import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setUser as sentrySetUser } from '@sentry/react';

import { AppConfigDaoService } from 'features/app-config/services';
import { handleError } from 'features/notification/store';
import { setLanguages } from 'features/queue-settings/store';
import { PageRoute } from 'features/router/routes';
import type {
  UpdateUserLanguagesPayload,
  User,
  UserState,
} from 'features/user/interfaces';
import { UserDaoService } from 'features/user/services';

export const initialUserState: UserState = {
  user: {
    id: 0,
    userName: '',
    firstName: '',
    lastName: '',
    genderCode: 0,
    agentNumber: '',
    isActive: false,
    callCenterCode: 'SLC',
    languages: [],
    languagesPriority: [],
    email: '',
  },
  isAtHome: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.user.email = action.payload;
    },
    setIsUserAtHome: (state, action: PayloadAction<boolean>) => {
      state.isAtHome = action.payload;
    },
  },
});

export const { setUser, setUserEmail, setIsUserAtHome } = userSlice.actions;

export const userReducer = userSlice.reducer;

export const fetchCurrentUser = createAsyncThunk(
  'user/fetchCurrentUser',
  async (payload, { dispatch }) => {
    try {
      const user = await UserDaoService.getCurrentUser();

      sentrySetUser({
        id: user.id,
        username: user.userName,
      });
      dispatch(setUser(user));
      dispatch(setLanguages(user.languagesPriority));
    } catch (error: any) {
      dispatch(
        handleError({
          error,
          disableUserNotification: true,
          methodName: 'fetchCurrentUser',
        })
      );
      if (error?.response?.status === 403) {
        window.location.href = PageRoute.ACCESS_DENIED;
      }
    }
  }
);

export const updateUserLanguages = createAsyncThunk(
  'user/updateLanguages',
  async ({ languages, userId }: UpdateUserLanguagesPayload) => {
    const languageCodes = languages
      .filter((item) => item.isSelected)
      .map((item) => item.languageCode);

    return UserDaoService.updateUserLanguages({
      userId,
      languageCodes,
    });
  }
);

export const checkIsUserAtHome = createAsyncThunk(
  'user/isUserAtHome',
  async (callCenter: string, { dispatch }) => {
    const { isCallCenterAtHome } =
      await AppConfigDaoService.isCallCenterAtHome(callCenter);
    dispatch(setIsUserAtHome(isCallCenterAtHome));
  }
);
