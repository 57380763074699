export enum RelayCallState {
  // No state assigned.
  Invalid,
  // Call is pushed to agent.
  AgntPush,
  // Call automatically rejected due to timeout or other automated process.
  AutoRjct,
  // Call has been completed.
  Complete,
  // Call has accumulated interpretation time and one or no parties are connected
  // to the call
  InPrgWMi,
  // Call is waiting to be pushed to an interpreter.
  WaitPush,
  // Call is in progress, but interpreting has not begun.
  InProg,
  // Call interpreting in progress.
  Intrpret,
  // Call manually rejected by agent.
  ManRejct,
  // Call was returned to the Call queue.
  RetrnToQ,
  // Initial state for a call generated by the client.
  Spawning,
  // State indicating that a reconnect occurred on the call
  Reconnct,
  // State indicating that a call was rejected because the agent was already in a call
  InClRjct,
}
