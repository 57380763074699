import { useState, useEffect } from 'react';

import {
  BlueParagraph,
  CallerStatusContainer,
  GreenParagraph,
  RedParagraph,
} from './styles';

import { useAppSelector } from 'features/app/hooks';
import {
  selectCallerSessionId,
  selectCallerSessionStatus,
} from 'features/caller-session/store';
import { isDevOrQaEnvironment } from 'common/utils';
import { sipCallIdSelector } from 'features/call/call-base/store/selectors';

const MENU_KEY = '*614';

export const HiddenDebugMenu = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [typedKeys, setTypedKeys] = useState('');
  const callerSessionStatus = useAppSelector(selectCallerSessionStatus);
  const callerSessionId = useAppSelector(selectCallerSessionId);
  const sipCallId = useAppSelector(sipCallIdSelector);
  const isDevOrQa = isDevOrQaEnvironment;

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      setTypedKeys((prev) => (prev + event.key).slice(-MENU_KEY.length));
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  useEffect(() => {
    if (typedKeys === MENU_KEY) {
      setIsVisible((prev) => !prev);
      setTypedKeys('');
    }
  }, [typedKeys]);

  if (!isDevOrQa || !isVisible) {
    return null;
  }

  return (
    <CallerStatusContainer>
      <RedParagraph>Session Status: {callerSessionStatus}</RedParagraph>
      <BlueParagraph>Session ID: {callerSessionId}</BlueParagraph>
      <GreenParagraph>SIP Call ID: {sipCallId}</GreenParagraph>
    </CallerStatusContainer>
  );
};
