import type { Theme } from '@mui/material';

import type { ThemeComponents } from 'features/theme/types';

export default function IconButton(theme: Theme): Partial<ThemeComponents> {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: theme.unstable_sx({
          '&.Mui-disabled': {
            'svg path': {
              stroke: theme.palette.grey[300],
            },
            span: {
              color: theme.palette.grey[300],
            },
          },
        }),
      },
    },
  };
}
