import merge from 'lodash.merge';

import type { EnvironmentVariables } from 'features/environment/constants';
import { env } from 'features/environment/constants';
import type { EnvironmentVariablesConfig } from 'features/environment/interfaces';

export class EnvironmentService {
  public static envConfig: EnvironmentVariables = {
    ...env,
  };

  public static setConfig(config: EnvironmentVariablesConfig) {
    merge(this.envConfig, config);

    if (env.isDevelopmentBuild) {
      merge(this.envConfig, env);
    }
  }
}

export const envConfig = EnvironmentService.envConfig;
