import { useFlags } from 'launchdarkly-react-client-sdk';
import type { FC, PropsWithChildren } from 'react';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { AuthProvider, TokenProvider } from 'features/auth/components';
import { envConfig } from 'features/environment/services';
import { LoggerService } from 'features/logger/services';
import { SelectMode } from 'features/multi-mode/components';
import { Mode } from 'features/multi-mode/enums';
import { ModeStorageService } from 'features/multi-mode/services';
import { modeSelector, switchModeTo } from 'features/multi-mode/store';
import { PageRoute } from 'features/router/routes';

export const MultiMode: FC<PropsWithChildren> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const mode = useAppSelector(modeSelector);
  const { vrsShift: vrsMode, vriShift: vriMode } = useFlags();

  useEffect(() => {
    if (vrsMode === false && vriMode === false) {
      LoggerService.error('Not selected Shift');
      navigate(PageRoute.SOMETHING_WENT_WRONG);
    } else if (vriMode && !vrsMode) {
      dispatch(switchModeTo(Mode.VRI));
    } else if (vrsMode && !vriMode) {
      dispatch(switchModeTo(Mode.VRS));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vriMode, vrsMode, mode]);

  useEffect(() => {
    const newMode = envConfig.defaultMode || ModeStorageService.getMode();

    if (newMode) {
      dispatch(switchModeTo(newMode));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!mode ? (
        <SelectMode />
      ) : (
        <AuthProvider mode={mode}>
          <TokenProvider>
            <Outlet />
          </TokenProvider>
        </AuthProvider>
      )}
    </>
  );
};
