import type { HubConnection } from '@microsoft/signalr';
import { Subject } from 'rxjs';

/** NOTE: There are two RnsEventBus classes in Mercury.
 * Be careful which you use.
 * This is a problem and we should fix it someday.
 */
export class RnsEventBus {
  static readonly $connected = new Subject<HubConnection>();
  static readonly $reconnecting = new Subject<Error | undefined>();
  static readonly $reconnected = new Subject<string | undefined>();
  static readonly $callDisconnected: Subject<string | undefined> =
    new Subject();
  static readonly $close = new Subject<Error | undefined>();
}
