import type { Theme } from '@mui/material';
import { alpha } from '@mui/material';

import type { ThemeComponents } from 'features/theme/types';

export default function Button(theme: Theme): Partial<ThemeComponents> {
  return {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: theme.unstable_sx({
          textTransform: 'none',
          borderRadius: 4,
          '& .MuiButton-startIcon, & .MuiButton-endIcon': {
            '& > *:first-of-type': {
              fontSize: 'inherit',
            },
          },
          '&:focus': {
            outline: 4,
            outlineStyle: 'solid',
            outlineColor: alpha(theme.palette.common.white, 0.3),
          },
          '&:disabled': {
            color: theme.palette.grey[300],
            backgroundColor: alpha(theme.palette.grey[600], 0.4),
          },
        }),
        sizeLarge: theme.unstable_sx({
          py: 6,
          px: 8,
          typography: 'buttonLarge',
        }),
        sizeMedium: theme.unstable_sx({
          py: 7,
          px: 8,
          typography: 'buttonMedium',
        }),
        sizeSmall: theme.unstable_sx({
          py: 5.5,
          px: 8,
          typography: 'buttonSmall',
        }),
        containedPrimary: theme.unstable_sx({
          color: theme.palette.grey[900],
          backgroundColor: theme.palette.primary[500],
          '&:hover': {
            backgroundColor: theme.palette.primary[100],
          },
        }),
        containedSecondary: theme.unstable_sx({
          color: theme.palette.common.white,
          backgroundColor: theme.palette.grey[600],
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        }),
        containedError: theme.unstable_sx({
          color: theme.palette.common.white,
          backgroundColor: theme.palette.error[600],
          '&:hover': {
            backgroundColor: theme.palette.error.main,
          },
        }),
        outlinedSecondary: theme.unstable_sx({
          color: theme.palette.grey[300],
          borderColor: theme.palette.grey[600],
          background: 'none',
          '&:hover': {
            borderColor: theme.palette.grey[600],
            background: 'none',
          },
        }),
      },
    },
  };
}
