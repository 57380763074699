import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';
import { isDeafToHearingSelector } from 'features/call/call-base/store/selectors';
import { isDeafConnectedSelector } from 'features/call/call-deaf/store';
import type { CustomGreetingState } from 'features/custom-greeting/interfaces';

export const initialCustomGreetingSliceState: CustomGreetingState = {
  text: '',
};

const customGreetingSlice = createSlice({
  name: 'custom-greeting',
  initialState: initialCustomGreetingSliceState,
  reducers: {
    setCustomGreeting: (state, action: PayloadAction<string>) => {
      state.text = action.payload;
    },
    resetCustomGreeting: () => initialCustomGreetingSliceState,
  },
});

export const { setCustomGreeting, resetCustomGreeting } =
  customGreetingSlice.actions;

export const customGreetingSelector = ({ customGreeting }: RootState) =>
  customGreeting.text;

export const isCustomGreetingPresentSelector = createSelector(
  isDeafToHearingSelector,
  customGreetingSelector,
  isDeafConnectedSelector,
  (isDeafToHearing, customGreeting, isDeafConnected) =>
    !isDeafToHearing && customGreeting && !isDeafConnected
);

export const customGreetingReducer = customGreetingSlice.reducer;
