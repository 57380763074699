import type { RootState } from 'features/app/store/store';
import { Mode } from 'features/multi-mode/enums';

export const modeSelector = ({ multiMode }: RootState) => multiMode.mode;

export const isVrsModeSelector = ({ multiMode }: RootState) =>
  multiMode.mode === Mode.VRS;

export const isVriModeSelector = ({ multiMode }: RootState) =>
  multiMode.mode === Mode.VRI;
