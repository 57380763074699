import type { Theme } from '@mui/material';

import type { ThemeComponents } from 'features/theme/types';

export default function OutlinedInput(theme: Theme): Partial<ThemeComponents> {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: `1px solid ${theme.palette.grey[300]}`,
          borderRadius: '8px',
          backgroundColor: 'transparent',
        },
        input: {
          height: '28px',
          padding: theme.spacing(4, 6),
          ...theme.typography.body2,
        },
      },
    },
  };
}
