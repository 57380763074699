import InterBlack from 'assets/fonts/Inter/Inter-Black.ttf';
import InterBold from 'assets/fonts/Inter/Inter-Bold.ttf';
import InterExtraBold from 'assets/fonts/Inter/Inter-ExtraBold.ttf';
import InterExtraLight from 'assets/fonts/Inter/Inter-ExtraLight.ttf';
import InterLight from 'assets/fonts/Inter/Inter-Light.ttf';
import InterMedium from 'assets/fonts/Inter/Inter-Medium.ttf';
import InterRegular from 'assets/fonts/Inter/Inter-Regular.ttf';
import InterSemiBold from 'assets/fonts/Inter/Inter-SemiBold.ttf';
import InterThin from 'assets/fonts/Inter/Inter-Thin.ttf';
import StabilGroteskBold from 'assets/fonts/StabilGrotesk/StabilGrotesk-Bold.otf';
import StabilGroteskBoldItalic from 'assets/fonts/StabilGrotesk/StabilGrotesk-BoldItalic.otf';
import StabilGroteskMedium from 'assets/fonts/StabilGrotesk/StabilGrotesk-Medium.otf';

export default `
  @font-face {
    font-family: StabilGrotesk;
    font-weight: 500;
    src: url(${StabilGroteskMedium}) format('opentype');
    font-display: block;
  }

  @font-face {
    font-family: StabilGrotesk;
    font-weight: 600;
    src: url(${StabilGroteskBold}) format('opentype');
    font-display: block;
  }

  @font-face {
    font-family: StabilGrotesk;
    font-weight: 600;
    font-style: italic;
    src: url(${StabilGroteskBoldItalic}) format('opentype');
    font-display: block;
  }

  @font-face {
    font-family: Inter;
    font-weight: 900;
    src: url(${InterBlack}) format('truetype');
    font-display: block;
  }

  @font-face {
    font-family: Inter;
    font-weight: 700;
    src: url(${InterBold}) format('truetype');
    font-display: block;
  }

  @font-face {
    font-family: Inter;
    font-weight: 800;
    src: url(${InterExtraBold}) format('truetype');
    font-display: block;
  }

  @font-face {
    font-family: Inter;
    font-weight: 200;
    src: url(${InterExtraLight}) format('truetype');
    font-display: block;
  }

  @font-face {
    font-family: Inter;
    font-weight: 300;
    src: url(${InterLight}) format('truetype');
    font-display: block;
  }

  @font-face {
    font-family: Inter;
    font-weight: 500;
    src: url(${InterMedium}) format('truetype');
    font-display: block;
  }

  @font-face {
    font-family: Inter;
    font-weight: 400;
    src: url(${InterRegular}) format('truetype');
    font-display: block;
  }

  @font-face {
    font-family: Inter;
    font-weight: 600;
    src: url(${InterSemiBold}) format('truetype');
    font-display: block;
  }

  @font-face {
    font-family: Inter;
    font-weight: 100;
    src: url(${InterThin}) format('truetype');
    font-display: block;
  }
`;
