import {
  addPlusToPhoneNumber,
  parsePhoneNumber,
  parsePhoneNumberIncomingCall,
} from 'features/call/call-base/helpers';
import type { Call } from 'features/call/call-base/interfaces';
import { translateHearingName } from 'features/call/call-hearing/helpers';
import type { HearingParticipant } from 'features/call/call-hearing/interfaces';
import {
  HearingParticipantPhoneNumberType,
  HearingParticipantType,
} from 'features/call/call-hearing/interfaces';

export const adaptIncomingPrimaryHearing = (call: Call): HearingParticipant => {
  return {
    id: '',
    name: translateHearingName(call.HearingNameString),
    phoneNumber:
      parsePhoneNumberIncomingCall(call.HearingConnectionString) ??
      HearingParticipantPhoneNumberType.ANONYMOUS,
    phoneExtension: call.HearingPhoneExtension,
    location: call.HearingConnectionLocation,
    status: 'disconnected',
    type: HearingParticipantType.PRIMARY,
    isLoading: false,
  };
};

export const adaptIncomingVco2Line = (
  carryOverPhoneNumber: string
): HearingParticipant => {
  return {
    id: '',
    name: '',
    location: '',
    phoneExtension: '',
    phoneNumber: addPlusToPhoneNumber(parsePhoneNumber(carryOverPhoneNumber)),
    status: 'disconnected',
    type: HearingParticipantType.VCO2LINE,
    isLoading: false,
  };
};
