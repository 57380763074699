import { createSelector } from '@reduxjs/toolkit';

import { isPrimaryHearingConnectingSelector } from 'features/call/call-hearing/store';
import { VoiceSessionStatus } from 'features/voice-session/enums';
import { voiceSessionStatusSelector } from 'features/voice-session/store';

export const isHearingToDeafCallerLoadingSelector = createSelector(
  voiceSessionStatusSelector,
  isPrimaryHearingConnectingSelector,
  (voiceSessionStatus, isPrimaryHearingConnecting) =>
    voiceSessionStatus === VoiceSessionStatus.CONNECTING ||
    isPrimaryHearingConnecting
);
