import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAudioReady: false,
  isVoiceAudioReady: false,
  isMicrophoneMuted: false,
};

/** Manages all audio for calls. (Except app sounds like ringtones.) */
const audioSlice = createSlice({
  name: 'audio',
  initialState,
  reducers: {
    // https://redux.js.org/style-guide/#model-actions-as-events-not-setters
    audioReady: (state) => {
      state.isAudioReady = true;
    },
    audioEnd: () => {
      return initialState;
    },

    hearingAudioReady: (state) => {
      state.isVoiceAudioReady = true;
    },
    hearingAudioEnd: (state) => {
      state.isVoiceAudioReady = false;
    },

    muteMicrophone: (state) => {
      state.isMicrophoneMuted = true;
    },
    unmuteMicrophone: (state) => {
      state.isMicrophoneMuted = false;
    },
  },
});

export const {
  audioReady,
  audioEnd,
  hearingAudioReady,
  hearingAudioEnd,
  muteMicrophone,
  unmuteMicrophone,
} = audioSlice.actions;

export const audioReducer = audioSlice.reducer;
