import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';
import { VoiceSessionStatus } from 'features/voice-session/enums';
import type { VoiceSessionState } from 'features/voice-session/interfaces';

export const initialVoiceSessionState: VoiceSessionState = {
  sessionId: '',
  status: VoiceSessionStatus.DISCONNECTED,
  isLoading: false,
};

export const voiceSessionSlice = createSlice({
  name: 'voiceSession',
  initialState: initialVoiceSessionState,
  reducers: {
    setVoiceSessionId: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload;
    },
    setVoiceSession: (state, action: PayloadAction<VoiceSessionState>) => {
      return action.payload;
    },
    setVoiceSessionStatus: (
      state,
      action: PayloadAction<VoiceSessionStatus>
    ) => {
      state.status = action.payload;
    },
    setVoiceSessionLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    resetVoiceSession: () => initialVoiceSessionState,
  },
});

export const {
  setVoiceSession,
  setVoiceSessionId,
  setVoiceSessionStatus,
  setVoiceSessionLoading,
  resetVoiceSession,
} = voiceSessionSlice.actions;

export const voiceSessionReducer = voiceSessionSlice.reducer;

export const voiceSessionIdSelector = ({ voiceSession }: RootState) =>
  voiceSession.sessionId;

export const voiceSessionStatusSelector = ({ voiceSession }: RootState) =>
  voiceSession.status;

export const isVoiceSessionLoadingSelector = ({ voiceSession }: RootState) =>
  voiceSession.isLoading;

export const isVoiceSessionActiveSelector = ({ voiceSession }: RootState) =>
  voiceSession.status === VoiceSessionStatus.ACTIVE;
