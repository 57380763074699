import { createContext } from 'react';

import { VoiceMeetingService } from 'features/voice-meeting/services';

export const VoiceMeetingContext = createContext<{
  service: VoiceMeetingService;
  audio: HTMLAudioElement | null;
}>({
  service: VoiceMeetingService.getInstance(),
  audio: null,
});
