import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type {
  MachinePreferencesPayload,
  MachinePreferencesState,
} from 'features/machine-preferences/interfaces';

export const initialMachinePreferencesState: MachinePreferencesState = {};

const machinePreferencesSlice = createSlice({
  name: 'machine-preferences',
  initialState: initialMachinePreferencesState,
  reducers: {
    setMachinePreferences: (
      state,
      action: PayloadAction<MachinePreferencesPayload>
    ) => {
      state.machineId = action.payload.machineId;
      state.stationNumber = action.payload.stationNumber;
      state.callCenterCode = action.payload.callCenterCode;
    },
  },
});

export const { setMachinePreferences } = machinePreferencesSlice.actions;

export const machinePreferencesReducer = machinePreferencesSlice.reducer;
