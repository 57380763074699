import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';

import { useVpnDetector } from 'features/vpn-detector/hooks';
import { VpnStatus } from 'features/vpn-detector/store';
import theme from 'features/theme/theme';

export const VpnWarning: FC = () => {
  const vpnDetectorState = useVpnDetector();
  const { t } = useTranslation('translation', {
    keyPrefix: 'vpnDetector',
  });

  return (
    <div>
      {vpnDetectorState.vpnStatus === VpnStatus.Detected && (
        <div className='vpn-warning'>
          <Typography
            variant='textLargeMedium'
            color={theme.palette.common.white}
            mb={4}
          >
            {t('vpnDetectedWarning')}
          </Typography>
        </div>
      )}
    </div>
  );
};
