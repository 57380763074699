import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';
import { VrsConferenceCallDetailsStatus } from 'features/call/vrs-conference-call/enums';
import type {
  VrsConferenceCallDetailsParticipant,
  VrsConferenceCallState,
} from 'features/call/vrs-conference-call/interfaces';

export const initialParticipant = {
  status: VrsConferenceCallDetailsStatus.PARTICIPANT_DISCONNECTED,
};

export const initialVrsConferenceCallSliceState: VrsConferenceCallState = {
  passcode: '',
  callDetails: {
    deaf: initialParticipant,
    hearing: initialParticipant,
  },
};

const vrsConferenceCallSlice = createSlice({
  name: 'vrs-conference-call',
  initialState: initialVrsConferenceCallSliceState,
  reducers: {
    setVrsConferenceCallPasscode: (state, action: PayloadAction<string>) => {
      state.passcode = action.payload.trim();
    },
    setVrsConferenceDeafParticipant: (
      state,
      action: PayloadAction<VrsConferenceCallDetailsParticipant>
    ) => {
      state.callDetails.deaf = action.payload;
    },
    setVrsConferenceHearingParticipant: (
      state,
      action: PayloadAction<VrsConferenceCallDetailsParticipant>
    ) => {
      state.callDetails.hearing = action.payload;
    },
    setVrsConferenceDeafParticipantStatus: (
      state,
      action: PayloadAction<VrsConferenceCallDetailsStatus>
    ) => {
      state.callDetails.deaf.status = action.payload;
    },
    setVrsConferenceHearingParticipantStatus: (
      state,
      action: PayloadAction<VrsConferenceCallDetailsStatus>
    ) => {
      state.callDetails.hearing.status = action.payload;
    },
    resetVrsConferenceCall: () => initialVrsConferenceCallSliceState,
  },
});

export const {
  setVrsConferenceCallPasscode,
  setVrsConferenceDeafParticipantStatus,
  setVrsConferenceHearingParticipantStatus,
  resetVrsConferenceCall,
} = vrsConferenceCallSlice.actions;

export const vrsConferenceCallPasscodeSelector = ({
  vrsConferenceCall,
}: RootState) => vrsConferenceCall.passcode;

export const vrsConferenceCallReducer = vrsConferenceCallSlice.reducer;
