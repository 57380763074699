import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';
import { normalizePhoneNumber } from 'features/call/call-base/helpers';
import { HearingParticipantType } from 'features/call/call-hearing/interfaces';

export const primaryHearingSelector = ({ callHearing }: RootState) =>
  callHearing.hearings[0];

export const getHearingById = ({ callHearing }: RootState, id: string) =>
  callHearing.hearings.find((hearing) => hearing.id === id);

export const getNotDisconnectedHearingByPhoneNumberSelector = (
  { callHearing }: RootState,
  phoneNumber: string
) => {
  if (phoneNumber === 'anonymous') {
    return callHearing.hearings[0];
  }
  return callHearing.hearings.find((hearing) => {
    const p1 = normalizePhoneNumber(hearing.phoneNumber);
    const p2 = normalizePhoneNumber(phoneNumber);
    return p1 === p2 && hearing.status !== 'disconnected';
  });
};
export const isPrimaryHearingConnectedSelector = createSelector(
  primaryHearingSelector,
  (hearing) => hearing.status === 'connected'
);

export const isPrimaryHearingConnectingSelector = createSelector(
  primaryHearingSelector,
  (hearing) => hearing.status === 'connecting'
);

export const primaryHearingStatusSelector = createSelector(
  primaryHearingSelector,
  (hearing) => hearing.status
);

export const isPrimaryHearingDisconnectedSelector = createSelector(
  primaryHearingSelector,
  (hearing) => hearing.status === 'disconnected'
);

export const selectIsLoadingPrimaryHearing = createSelector(
  primaryHearingSelector,
  (hearing) => hearing.isLoading
);

export const primaryHearingPhoneNumberSelector = createSelector(
  primaryHearingSelector,
  (hearing) => hearing.phoneNumber
);

export const vco2LineSelector = ({ callHearing }: RootState) =>
  callHearing.hearings[1];

export const selectIsLoadingVco2LineHearing = createSelector(
  vco2LineSelector,
  (vco2Line) => vco2Line.isLoading
);

export const vco2LineIdSelector = createSelector(
  vco2LineSelector,
  (vco2Line) => vco2Line.id
);

export const twoLineVcoPhoneNumberSelector = createSelector(
  vco2LineSelector,
  (vco2Line) => vco2Line.phoneNumber
);

export const vco2LineStatusSelector = createSelector(
  vco2LineSelector,
  (vco2Line) => vco2Line.status
);

export const isTwoLineVcoDisconnectedSelector = createSelector(
  vco2LineSelector,
  (vco2Line) => vco2Line.status === 'disconnected'
);

export const isTwoLineVcoConnectedSelector = createSelector(
  vco2LineSelector,
  (vco2Line) => vco2Line.status === 'connected'
);

export const hearingsSelector = ({ callHearing }: RootState) =>
  callHearing.hearings;

export const lastConnectHearingSelector = ({ callHearing }: RootState) =>
  callHearing.hearings[callHearing.lastConnectHearing];

export const isAllHearingsDisconnectedSelector = ({ callHearing }: RootState) =>
  callHearing.hearings.every((hearing) => hearing.status === 'disconnected');

export const isAnyHearingConnectedSelector = ({ callHearing }: RootState) =>
  callHearing.hearings.some((hearing) => hearing.status === 'connected');

export const selectIsAnyHearingConnectedOrConnecting = createSelector(
  hearingsSelector,
  (hearings) =>
    hearings.some(
      (hearing) =>
        hearing.status === 'connected' || hearing.status === 'connecting'
    )
);

export const callErrorSelector = ({ callHearing }: RootState) =>
  callHearing.callError;

export const hearingWSConnectionSelector = ({ callHearing }: RootState) =>
  callHearing.isWSConnectionEstablished;

export const isWavelloCallActiveSelector = ({ callHearing }: RootState) =>
  callHearing.hearings[0].isWavelloCallActive;

export const selectIsAnyHearingConnecting = createSelector(
  hearingsSelector,
  (hearings) => hearings.some((hearing) => hearing.status === 'connecting')
);

export const selectIsAnyHearingPendingDisconnect = createSelector(
  hearingsSelector,
  (hearings) => hearings.some((hearing) => hearing.pendingDisconnect)
);

export const selectPrimaryAndSecondaryConnectedHearings = ({
  callHearing,
}: RootState) => {
  return callHearing.hearings.filter(
    (hearing) =>
      hearing.status === 'connected' &&
      [
        HearingParticipantType.PRIMARY,
        HearingParticipantType.SECONDARY,
      ].includes(hearing.type)
  );
};
