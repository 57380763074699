import { useEffect } from 'react';

import {
  VpnStatus,
  checkVpnStatus,
  vpnDetectorSelector,
} from 'features/vpn-detector/store';
import { useAppDispatch, useAppSelector } from 'features/app/hooks';

export const useVpnDetector = () => {
  const dispatch = useAppDispatch();
  const vpnDetectorState = useAppSelector(vpnDetectorSelector);

  useEffect(() => {
    if (vpnDetectorState.vpnStatus === VpnStatus.Unknown) {
      dispatch(checkVpnStatus());
    }
  }, [dispatch, vpnDetectorState]);

  return vpnDetectorState;
};
