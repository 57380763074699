import { httpClient } from 'common/services';
import type {
  DeafUserInformationRequest,
  DeafUserInformationResponse,
  SignmailUploadUrlReadyRequest,
  SignmailUploadUrlResponse,
} from 'features/signmail/interfaces';

async function getDeafUserInformation({
  calleeNumber,
  callerNumber,
  blockCallerId,
}: DeafUserInformationRequest) {
  const route = `/signmail/resolve/${callerNumber}/${calleeNumber}/${blockCallerId}`;
  const response = await httpClient.get<DeafUserInformationResponse>(route);
  return response.data;
}

async function getUploadUrl(callId: number) {
  const route = `/signmail/upload-url/${callId}`;
  const response = await httpClient.get<SignmailUploadUrlResponse>(route);
  return response.data;
}

async function uploadFile(url: string, file: File): Promise<number> {
  const formData = new FormData();
  formData.append('file', file);
  const { data } = await httpClient.uploadFile(url, formData);
  return data;
}

async function uploadUrlReady(
  callId: number,
  data: SignmailUploadUrlReadyRequest
) {
  const route = `/signmail/upload-url/${callId}/ready`;
  const response = await httpClient.post<string>(route, data);
  return response.data;
}

export const SignMailDaoService = {
  getDeafUserInformation,
  getUploadUrl,
  uploadFile,
  uploadUrlReady,
};
