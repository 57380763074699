import type { ThemeComponents } from 'features/theme/types';

export default function SvgIcon(): Partial<ThemeComponents> {
  return {
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          fontSize: '20px',
        },
        fontSizeLarge: {
          fontSize: '32px',
        },
      },
      variants: [
        {
          props: { fontSize: 'x-small' },
          style: {
            fontSize: '16px',
          },
        },
        {
          props: { fontSize: 'dense' },
          style: {
            fontSize: '22px',
          },
        },
        {
          props: { fontSize: 'x-large' },
          style: {
            fontSize: '40px',
          },
        },
      ],
    },
  };
}
