import type { Session } from 'features/session/interfaces';

export class SessionService {
  private static instance: SessionService;
  private session: Session | null = null;

  private constructor() {}

  public static getInstance(): SessionService {
    if (!SessionService.instance) {
      SessionService.instance = new SessionService();
    }

    return SessionService.instance;
  }

  public setSession(session: Session) {
    this.session = session;
  }

  public getSession(): Session | null {
    return this.session;
  }

  public resetSession() {
    this.session = null;
  }
}
