import { httpClient } from 'common/services';

async function getTotalInterpretTime(callId: number) {
  const route = `/call/${callId}/total-interpret-time`;
  const response = await httpClient.get<number>(route);
  return response.data;
}

export const CallTimerDaoService = {
  getTotalInterpretTime,
};
