import arrow from '@mui/icons-material/KeyboardArrowDown';
import type { SelectProps as MuiSelectProps } from '@mui/material';
import { MenuItem, Select as MuiSelect } from '@mui/material';
import type { FC } from 'react';

import type { CustomSelectItem } from './interfaces';

import { MarkIcon } from 'assets/svg';
import styled, { styledProps } from 'features/theme/styled';
import theme from 'features/theme/theme';

interface SelectProps extends Omit<MuiSelectProps, 'value'> {
  options?: CustomSelectItem[];
  dropdownWidth?: number;
  value?: unknown;
}

const OptionTextMultiline = styledProps('span')<{ $customColor?: string }>`
  ${({ $customColor }) =>
    $customColor &&
    `
  color: ${$customColor};
  `}
  white-space: initial;
`;

const MarkIconSelected = styled(MarkIcon)`
  min-width: 16px;
  margin-left: 10px;
`;

const CustomIconStyled = styled('span')`
  display: flex;
  margin-right: ${theme.spacing(4)};
`;

const OptionStyled = styled('div')`
  display: flex;
  align-items: center;
`;

export const Select: FC<SelectProps> = ({
  options,
  value,
  placeholder,
  dropdownWidth,
  ...args
}) => {
  return (
    <MuiSelect
      {...args}
      value={value ?? ''}
      fullWidth
      IconComponent={arrow}
      MenuProps={{
        MenuListProps: {
          sx: () => ({
            width: dropdownWidth,
          }),
        },
        classes: { paper: 'select' },
      }}
      // Placeholder + Without Translation: https://github.com/mui/material-ui/issues/11069#issuecomment-1001502219
      displayEmpty={!!placeholder}
      renderValue={!value ? () => placeholder : undefined}
    >
      {options?.map((option) => (
        <MenuItem key={option.value} value={option.value} title={option.text}>
          <OptionStyled>
            {option.customIcon && (
              <CustomIconStyled>{option.customIcon}</CustomIconStyled>
            )}
            <OptionTextMultiline $customColor={option.customColor}>
              {option.text}
            </OptionTextMultiline>
          </OptionStyled>
          {option.value === value && <MarkIconSelected />}
        </MenuItem>
      ))}
    </MuiSelect>
  );
};
