import { SoundName } from 'features/audio-player/enums';
import { Sound } from 'features/audio-player/services';

export class AudioPlayer {
  private static activeAudio: Sound;
  private static _ringVolume = 1;
  public static set ringVolume(volume: number) {
    AudioPlayer._ringVolume = volume / 100;
  }

  static async play(name: SoundName) {
    AudioPlayer.activeAudio = Sound.create(name);

    const ringSounds = [
      SoundName.RINGING,
      SoundName.HEARING_RINGING,
      SoundName.EMERGENCY_RINGING,
      SoundName.HEARING_TO_DEAF_RINGING,
      SoundName.DEAF_TO_HEARING_RINGING,
    ];

    const isRingSound = ringSounds.includes(name);

    if (isRingSound) {
      AudioPlayer.activeAudio.volume = this._ringVolume;
      await AudioPlayer.activeAudio.play();
    } else {
      await AudioPlayer.activeAudio.play();
    }
  }

  static playSample(name: SoundName, duration?: number) {
    AudioPlayer.activeAudio = Sound.create(name);
    AudioPlayer.activeAudio.volume = this._ringVolume;
    AudioPlayer.activeAudio.playSample(duration);
  }

  static stop() {
    AudioPlayer.activeAudio.stop();
  }
}
