import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';
import type { InitialReportingState } from 'features/reporting/interfaces';

export const initialReportingState: InitialReportingState = {
  deafConnectRecorded: false,
  hearingConnectRecorded: false,
  twoLineVCORecorded: false,
  signMailConnectRecorded: false,
};

const reportingSlice = createSlice({
  initialState: initialReportingState,
  name: 'reporting',
  reducers: {
    recordDeafConnect: (state) => {
      state.deafConnectRecorded = true;
    },
    recordHearingConnect: (state) => {
      state.hearingConnectRecorded = true;
    },
    recordTwoLineVCOConnect: (state) => {
      state.twoLineVCORecorded = true;
    },
    recordSignMailConnect: (state) => {
      state.signMailConnectRecorded = true;
    },
    resetSignMailConnectRecord: (state) => {
      state.signMailConnectRecorded = false;
    },
    resetDeafConnectRecord: (state) => {
      state.deafConnectRecorded = false;
    },
    resetHearingConnectRecord: (state) => {
      state.hearingConnectRecorded = false;
    },
    resetTwoLineVCORecord: (state) => {
      state.twoLineVCORecorded = false;
    },
    resetReportingState: () => initialReportingState,
  },
});

export const {
  recordDeafConnect,
  recordHearingConnect,
  resetReportingState,
  resetDeafConnectRecord,
  resetHearingConnectRecord,
  resetTwoLineVCORecord,
  recordTwoLineVCOConnect,
  resetSignMailConnectRecord,
  recordSignMailConnect,
} = reportingSlice.actions;

export const deafConnectRecordedSelector = ({ reporting }: RootState) =>
  reporting.deafConnectRecorded;

export const hearingConnectRecordedSelector = ({ reporting }: RootState) =>
  reporting.hearingConnectRecorded;

export const twoLineVCORecordedSelector = ({ reporting }: RootState) =>
  reporting.twoLineVCORecorded;

export const reportingReducer = reportingSlice.reducer;

export const isSignMailConnectRecordedSelector = ({ reporting }: RootState) =>
  reporting.signMailConnectRecorded;
