import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { ButtonProps } from 'features/ui/elements';
import { Button } from 'features/ui/elements';

export const CallDetailsButtonAddHearing: FC<
  Pick<ButtonProps, 'onClick' | 'loading'>
> = ({ loading, onClick }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'addHearing' });

  return (
    <Button
      fullWidth
      size='small'
      color='secondary'
      loading={loading}
      onClick={onClick}
    >
      {t('openAddHearingBtn')}
    </Button>
  );
};
