import type { Theme } from '@mui/material';
import { alpha } from '@mui/material';

import type { ThemeComponents } from 'features/theme/types';

export default function Input(theme: Theme): Partial<ThemeComponents> {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: theme.unstable_sx({
          fieldset: {
            display: 'none',
          },
          borderRadius: 0,
          backgroundColor: alpha(theme.palette.common.white, 0.25),
          color: theme.palette.common.white,
          p: 0,
          '&.Mui-focused, &:hover': {
            backgroundColor: 'initial',
          },
        }),
      },
    },
    MuiInput: {
      styleOverrides: {
        root: theme.unstable_sx({
          typography: 'inputText',
          border: 1,
          borderColor: theme.palette.grey[400],
          borderRadius: 1,
          backgroundColor: theme.palette.grey[100],

          '&:hover': {
            borderColor: theme.palette.grey[500],
          },
          '&.Mui-error': {
            borderColor: theme.palette.error.main,
          },
          '&.Mui-focused': {
            borderColor: theme.palette.primary[500],
          },
          '&.Mui-disabled': {
            borderColor: theme.palette.grey[300],
            backgroundColor: theme.palette.grey[200],
            '.MuiInputAdornment-root': {
              color: theme.palette.grey[400],
            },
          },
        }),
        input: theme.unstable_sx({
          px: 4,
          py: 3,
          color: theme.palette.grey[600],
          borderRadius: 1,
        }),
        multiline: theme.unstable_sx({
          borderRadius: 4,
          borderColor: theme.palette.grey[300],
          backgroundColor: 'transparent',
          '&:hover': {
            borderColor: theme.palette.grey[300],
          },
          '&.Mui-focused': {
            borderColor: theme.palette.grey[300],
          },
          '&.Mui-disabled': {
            borderColor: alpha(theme.palette.grey[300], 0.4),
            backgroundColor: 'transparent',
          },
          '&.Mui-error': {
            borderColor: theme.palette.error.contrastText,
            '+ .MuiFormHelperText-root': {
              color: theme.palette.error.contrastText,
            },
          },
        }),
        inputMultiline: theme.unstable_sx({
          ...theme.typography.subtitle1,
          color: theme.palette.common.white,
          p: theme.spacing(5, 7),
          '&.Mui-disabled': {
            color: theme.palette.common.white,
          },
        }),
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: theme.unstable_sx({
          color: theme.palette.grey[500],
        }),
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: theme.unstable_sx({
          typography: 'textMediumRegular',
          borderRadius: 4,
          backgroundColor: alpha(theme.palette.common.white, 0.25),
          '&.Mui-focused, &:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
          },
          '& .MuiSelect-select': {
            whiteSpace: 'initial',
            py: 5,
            // To remove Mark Icon in Select placeholder
            '& svg': {
              display: 'none',
            },
          },
        }),
      },
    },
  };
}
