import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CallInPhone } from 'assets/svg';
import { useAppSelector } from 'features/app/hooks';
import { lastConnectHearingSelector } from 'features/call/call-hearing/store';
import { maskPhoneNumber } from 'features/phone-number/helpers';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';
import { Button } from 'features/ui/elements';

interface AddHearingConnectingProps {
  onCancel: (id: string, phoneNumber: string) => void;
}

export const AddHearingConnecting = ({
  onCancel,
}: AddHearingConnectingProps) => {
  const { t: ct } = useTranslation();
  const { t } = useTranslation('translation', {
    keyPrefix: 'addCallee',
  });
  const lastConnectHearing = useAppSelector(lastConnectHearingSelector);

  return (
    <div>
      <Typography variant='label' color={theme.palette.grey[300]}>
        {t('connecting')}
      </Typography>
      <ParticipantWrapper>
        <CallInPhone height={20} width={20} />
        <Typography variant='body1' color={theme.palette.grey[50]}>
          {lastConnectHearing?.phoneNumber &&
            maskPhoneNumber(lastConnectHearing.phoneNumber)}
        </Typography>
      </ParticipantWrapper>
      <Button
        data-testid='add-hearing-cancel-button'
        fullWidth
        size='small'
        loading={!lastConnectHearing?.id}
        onClick={() =>
          onCancel(lastConnectHearing?.id, lastConnectHearing?.phoneNumber)
        }
      >
        {ct('cancel')}
      </Button>
    </div>
  );
};

const ParticipantWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(8)};
  margin-top: ${theme.spacing(4)};
  margin-bottom: ${theme.spacing(4)};
  align-items: center;
`;
