import type { Maybe } from 'common/types';
import type { EnvironmentVariablesConfig } from 'features/environment/interfaces';
import type { Mode } from 'features/multi-mode/enums';

export interface EnvironmentVariables extends EnvironmentVariablesConfig {
  isDevelopmentBuild: boolean;
  isProductionBuild: boolean;
  isTestMode: boolean;
  publicUrl: string;
  apiUrl: string;
  isStorybookPresents: boolean;
  emergency: {
    fakePSAP: string;
    fakeECRC: string;
  };
  defaultMode?: Mode;
}

export const env: EnvironmentVariables = {
  isDevelopmentBuild: import.meta.env.MODE === 'development',
  isProductionBuild: import.meta.env.MODE === 'production',
  isTestMode: import.meta.env.MODE === 'test',
  publicUrl: import.meta.env.VITE_APP_PUBLIC_URL,
  apiUrl: import.meta.env.VITE_APP_API_URL,
  environment: import.meta.env.VITE_APP_PRODUCTION_ENVIRONMENT,
  isStorybookPresents: !!import.meta.env.STORYBOOK,
  emergency: {
    fakePSAP: import.meta.env.VITE_APP_FAKE_PSAP_NUMBER,
    fakeECRC: import.meta.env.VITE_APP_FAKE_ECRC_NUMBER,
  },
  defaultMode: import.meta.env.VITE_APP_DEFAULT_MODE as Maybe<Mode>,
  awsRegion: import.meta.env.VITE_APP_AWS_REGION,
  launchDarklyClientSideId: import.meta.env
    .VITE_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  sendbirdApplicationId: import.meta.env.VITE_APP_SENDBIRD_APPLICATION_ID,
  sip: {
    domain: import.meta.env.VITE_APP_SIP_DOMAIN,
    iceServerCredential: import.meta.env.VITE_APP_SIP_ICE_SERVER_CREDENTIAL,
    iceServerUsername: import.meta.env.VITE_APP_SIP_ICE_SERVER_USERNAME,
    turnServer: import.meta.env.VITE_APP_SIP_TURN_SERVER,
    turnServerAlt: import.meta.env.VITE_APP_SIP_TURN_SERVER_ALT,
    stunServer: import.meta.env.VITE_APP_SIP_STUN_SERVER,
    stunServerAlt: import.meta.env.VITE_APP_SIP_STUN_SERVER_ALT,
  },
  zoom: {
    sdkKey: import.meta.env.VITE_APP_ZOOM_SDK_KEY,
  },
  vri: {
    sipCustomerEndpoint: import.meta.env.VITE_APP_VRI_SIP_REMOTE_DOMAIN,
    cognito: {
      userPoolId: import.meta.env.VITE_APP_VRI_COGNITO_USER_POOL_ID,
      clientId: import.meta.env.VITE_APP_VRI_COGNITO_CLIENT_ID,
    },
  },
  vrs: {
    sipCustomerEndpoint: import.meta.env.VITE_APP_VRS_SIP_REMOTE_DOMAIN,
    cognito: {
      userPoolId: import.meta.env.VITE_APP_VRS_COGNITO_USER_POOL_ID,
      clientId: import.meta.env.VITE_APP_VRS_COGNITO_CLIENT_ID,
    },
  },
};
