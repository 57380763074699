import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import type { ErrorBaseProps } from 'features/error/components';
import { ErrorBase } from 'features/error/components';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';
import { Button } from 'features/ui/elements';

export const ErrorWithBackButton: FC<ErrorBaseProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ErrorBase {...props}>
      <BackButton
        title={t('goBack')}
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(-1)}
      >
        {t('goBack')}
      </BackButton>
    </ErrorBase>
  );
};

const BackButton = styled(Button)`
  margin-top: ${theme.spacing(20)};
`;
