import { httpClient } from 'common/services';
import type { Call } from 'features/call/call-base/interfaces';
import type {
  CompleteTeamingRequest,
  RequestAdhocTeaming,
  RequestTeamingRequest,
  TeamingForceAcceptRequest,
} from 'features/teaming/teaming-base/interfaces';
import type { User } from 'features/user/interfaces';

async function requestTeaming({
  callId,
  options,
  voiceSessionId,
}: RequestTeamingRequest) {
  const route = `/call/${callId}/teaming`;
  const response = await httpClient.post<string>(route, {
    voiceSessionId,
    ...options,
  });
  return response.data;
}

async function requestAdhocTeaming({ callId, options }: RequestAdhocTeaming) {
  const route = `/call/${callId}/adhoc-teaming`;
  const response = await httpClient.post<User>(route, options);
  return response.data;
}

async function beginTeaming(callId: number) {
  const route = `/call/${callId}/teaming/begin`;
  const response = await httpClient.put<Call>(route);
  return response.data;
}

async function cancelTeaming(callId: number) {
  const route = `/call/${callId}/teaming/cancel`;
  const response = await httpClient.put<Call>(route);
  return response.data;
}

async function completeTeaming({
  callId,
  userId,
  keepAlive,
  suppressTeamEndNotify = false,
}: CompleteTeamingRequest) {
  const route = `/call/${callId}/teaming/complete`;
  const response = await httpClient.put<Call>(
    route,
    {
      suppressTeamEndNotify,
      userId,
    },
    { keepAlive }
  );
  return response.data;
}

async function forceAcceptTeaming({
  callId,
  userId,
  reason,
  roomUri,
}: TeamingForceAcceptRequest) {
  const route = `/call/${callId}/teaming/force-accept`;
  const response = await httpClient.put<Call>(route, {
    userId,
    reason,
    roomUri,
  });
  return response.data;
}

export const TeamingDaoService = {
  requestTeaming,
  beginTeaming,
  cancelTeaming,
  completeTeaming,
  requestAdhocTeaming,
  forceAcceptTeaming,
};
