import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { VpnDetectorService } from 'features/vpn-detector/services';
import type { RootState } from 'features/app/store/store';

export enum VpnStatus {
  Unknown,
  Detected,
  NotDetected,
}

export interface VpnDetectorState {
  vpnStatus: VpnStatus;
  bypassVpnRestrictions: boolean;
}

export const initialVpnDetectorState: VpnDetectorState = {
  vpnStatus: VpnStatus.Unknown,
  bypassVpnRestrictions: false,
};

export const vpnDetectorSlice = createSlice({
  name: 'vpn-detector',
  initialState: initialVpnDetectorState,
  reducers: {
    setIsVpnDetected: (state, action: PayloadAction<VpnStatus>) => {
      state.vpnStatus = action.payload;
    },
    setBypassVpnRestrictions: (state, action: PayloadAction<boolean>) => {
      state.bypassVpnRestrictions = action.payload;
    },
  },
});

export const { setIsVpnDetected, setBypassVpnRestrictions } =
  vpnDetectorSlice.actions;

export const vpnDetectorReducer = vpnDetectorSlice.reducer;

export const vpnDetectorSelector = ({ vpnDetector }: RootState) => vpnDetector;

export const isVpnDetectedSelector = ({ vpnDetector }: RootState) =>
  vpnDetector.vpnStatus === VpnStatus.Detected;

export const bypassVpnRestrictionsSelector = ({ vpnDetector }: RootState) =>
  vpnDetector.bypassVpnRestrictions;

export const checkVpnStatus = createAsyncThunk(
  'vpn-detector/checkVpnStatus',
  async (_, { dispatch }) => {
    const isVpn = await VpnDetectorService.checkVpn();
    dispatch(
      setIsVpnDetected(isVpn ? VpnStatus.Detected : VpnStatus.NotDetected)
    );
  }
);
