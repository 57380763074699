import { TerminationType } from 'features/call/call-base/enums';

interface terminationDeciderProps {
  interpretTime: number;
  wasCallPlaced: boolean;
  terminationType: TerminationType;
}

export const terminationDeciderVrs = ({
  interpretTime,
  wasCallPlaced,
  terminationType,
}: terminationDeciderProps) => {
  const originalTermReason = terminationType;

  if (originalTermReason === TerminationType.NonBill) {
    return originalTermReason;
  }

  const thereWasInterpretingTime = interpretTime > 0;
  if (thereWasInterpretingTime) {
    return originalTermReason === TerminationType.SignMail
      ? TerminationType.SignMail
      : TerminationType.Normal;
  }

  if (wasCallPlaced) {
    return originalTermReason === TerminationType.Busy
      ? TerminationType.Busy
      : TerminationType.NoAnswer;
  }

  return TerminationType.ClNtPlcd;
};
