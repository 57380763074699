import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';

// type for state
interface StatsState {
  remoteVideoIp: string;
  remoteIpCheckComplete: boolean;
}

export const initialStatsState: StatsState = {
  remoteVideoIp: '',
  remoteIpCheckComplete: false,
};

export const statsSlice = createSlice({
  name: 'stats',
  initialState: initialStatsState,
  reducers: {
    setRemoteVideoIp: (state, action) => {
      state.remoteVideoIp = action.payload;
    },
    resetRemoteVideoIp(state) {
      state.remoteVideoIp = '';
      state.remoteIpCheckComplete = false;
    },
    setRemoteIpCheckComplete(state) {
      state.remoteIpCheckComplete = true;
    },
  },
});

export const {
  setRemoteVideoIp,
  resetRemoteVideoIp,
  setRemoteIpCheckComplete,
} = statsSlice.actions;

export const statsReducer = statsSlice.reducer;

export const remoteVideoIpSelector = ({ stats }: RootState) =>
  stats.remoteVideoIp;

export const remoteIpCheckCompleteSelector = ({ stats }: RootState) =>
  stats.remoteIpCheckComplete;
