import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';
import type {
  DialedNumbersHistoryState,
  DialedNumbersList,
} from 'features/dialed-numbers-history/interfaces';

export const initialDialedNumbersHistoryState: DialedNumbersHistoryState = {
  dialedNumbersList: [],
};

const dialedNumbersHistorySlice = createSlice({
  name: 'dialed-numbers-history',
  initialState: initialDialedNumbersHistoryState,
  reducers: {
    setDialedNumberHistory: (
      state,
      action: PayloadAction<DialedNumbersList>
    ) => {
      state.dialedNumbersList = [...new Set(action.payload)];
    },
    resetDialedNumbersHistory: () => initialDialedNumbersHistoryState,
  },
});

export const { setDialedNumberHistory, resetDialedNumbersHistory } =
  dialedNumbersHistorySlice.actions;

export const dialedNumbersListSelector = ({
  dialedNumbersHistory,
}: RootState) => dialedNumbersHistory.dialedNumbersList;

export const dialedNumbersHistoryReducer = dialedNumbersHistorySlice.reducer;
