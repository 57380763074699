import { Typography } from '@mui/material';
import type { FC, FunctionComponent, PropsWithChildren, SVGProps } from 'react';
import { useMemo } from 'react';

import styled, { styledProps } from 'features/theme/styled';
import theme from 'features/theme/theme';

export interface BadgeProps extends PropsWithChildren {
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  severity: 'error' | 'warning' | 'info' | 'success' | 'subtle' | 'darkError';
}

interface BadgeColorGroup {
  backgroundColor: string;
  foregroundColor: string;
}

const Container = styledProps('span')<{ $backgroundColor: string }>`
  display: inline-flex;
  align-items: center;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: 16px;
  padding: ${theme.spacing(1, 4, 1, 3)};
`;

// TODO: replace hardcoded colors when Doug update Design System
export const Badge: FC<BadgeProps> = (props) => {
  const colorGroup: BadgeColorGroup = useMemo(
    () => getColorGroupBySeverity(props.severity),
    [props.severity]
  );

  const Icon = useMemo(() => {
    if (!props.icon) {
      return null;
    }

    return styled(props.icon)`
      width: 12px;
      height: 12px;
      & path {
        stroke: ${colorGroup.foregroundColor};
      }
    `;
  }, [props.icon, colorGroup]);

  return (
    <Container $backgroundColor={colorGroup.backgroundColor}>
      {Icon && <Icon />}
      <Typography variant='caption' color={colorGroup.foregroundColor} ml={2}>
        {props.children}
      </Typography>
    </Container>
  );
};

function getColorGroupBySeverity(
  severity: BadgeProps['severity']
): BadgeColorGroup {
  if (severity === 'error') {
    return {
      backgroundColor: '#FAEDED',
      foregroundColor: '#8E0E09',
    };
  }

  if (severity === 'warning') {
    return {
      backgroundColor: '#FFD99C',
      foregroundColor: '#A68759',
    };
  }

  if (severity === 'info') {
    return {
      backgroundColor: '#D4F7F7',
      foregroundColor: '#3B8787',
    };
  }

  if (severity === 'subtle') {
    return {
      backgroundColor: '#2b363e',
      foregroundColor: '#b9bdbe',
    };
  }

  if (severity === 'darkError') {
    return {
      backgroundColor: '#621818',
      foregroundColor: '#cbb3b2',
    };
  }

  return {
    backgroundColor: '#A8F79C',
    foregroundColor: '#26871A',
  };
}
