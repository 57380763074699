export enum UserStatus {
  /** User is currently in a delay after a call. */
  CDelay,

  /** User is currently in a delay after an abandoned call. */
  ABDelay,

  /** User is idle. */
  Idle,

  /** This user is in a call but both parties are not connected. */
  InPrgres,

  /** This user is in a call with both parties connected. */
  Intrptng,

  /** User is not logged in. */
  LoggedOut,

  /** User is currently in delay after login */
  LDelay,

  /** Not accepting calls at this time. */
  NoCalls,

  /** User has been pushed a call but has not yet responded. */
  Pushed,

  /** User is teaming. */
  Teaming,
}
