import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Hotkey } from 'config/enums';
import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { CallDetailsStatus } from 'features/call/call-details/enums';
import { setCallDetailsStatus } from 'features/call/call-details/store';
import { handleError } from 'features/notification/store';
import { useSpawnNewCall } from 'features/spawn-call/hooks';
import { Button } from 'features/ui/elements';
import { shouldCallControlsBeVisibleSelector } from 'features/spawn-call/store';

export const CallDetailsButtonNewCall: FC = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('translation', { keyPrefix: 'newCall' });
  const { spawnNewCall } = useSpawnNewCall();
  const isSpawnCallButtonVisible = useAppSelector(
    shouldCallControlsBeVisibleSelector
  );
  const handleNewCall = async () => {
    setLoading(true);
    try {
      await spawnNewCall();
      dispatch(setCallDetailsStatus(CallDetailsStatus.NEW_CALL));
    } catch (error) {
      dispatch(
        handleError({
          message: t('requestErrorMessage'),
          methodName: 'handleNewCall',
          error,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  if (!isSpawnCallButtonVisible) {
    return;
  }

  return (
    <Button
      fullWidth
      size='small'
      color='secondary'
      data-testid='spawn-call-button'
      loading={loading}
      onClick={handleNewCall}
      hotkey={Hotkey.NewCall}
    >
      {t('openNewCallBtn')}
    </Button>
  );
};
