import { createTheme } from '@mui/material/styles';
import type { Shadows } from '@mui/material/styles/shadows';

import ComponentsOverrides from './overrides';
import Typography from './typography';

const theme = createTheme({
  breakpoints: {
    values: {
      lg: 1366,
      xl: 1440,
    },
  },
  palette: {
    primary: {
      100: '#C9F2ED',
      200: '#82DED9',
      500: '#3DCDC2',
      600: '#33B0A6',
      900: '#145752',
    },
    grey: {
      50: '#E3E3E5',
      100: '#C7C9CC',
      300: '#ADB0B0',
      400: '#919496',
      500: '#757A7D',
      600: '#595E61',
      700: '#404547',
      800: '#24292E',
      900: '#080F12',
    },
    error: {
      main: '#A8110B',
      contrastText: '#DE8782',
      light: '#FF5247',
    },
    warning: {
      //TODO update main color when the designer provides correct one
      main: '#ED6C02',
      100: '#FFFA8F',
    },
    deepChampagne: {
      500: '#FFCF87',
    },
    tangerine: {
      600: '#C2543A',
      700: '#994F3D',
    },
    emergency: {
      main: '#5D0A06',
      border: '#8E0E09',
      text: '#D5160E',
    },
    wavello: {
      active: '#6DDDFF',
      inActive: '#757A7D',
    },
  },
  shadows: [
    'none',
    '0px 0px 8px rgba(0, 0, 0, 0.08)',
    '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px -2px rgba(0, 0, 0, 0.16)',
    '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px -4px rgba(0, 0, 0, 0.16)',
    '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 12px 24px -6px rgba(0, 0, 0, 0.16)',
    '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
    ...Array(19).fill('none'),
  ] as Shadows,
  spacing: 2,
  shape: {
    borderRadius: 2,
  },
  typography: Typography,
});
theme.components = ComponentsOverrides(theme);

export default theme;
