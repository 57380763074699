import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';
import { CallType } from 'features/call/call-base/enums';
import { isPrimaryHearingConnectingSelector } from 'features/call/call-hearing/store';
import { ColdHandoffStatus } from 'features/cold-handoff/enums';

export const coldHandoffStatusSelector = ({ coldHandoff }: RootState) =>
  coldHandoff.status;

export const coldHandoffRequestingUserNameSelector = ({
  coldHandoff,
}: RootState) => coldHandoff.requestingUser.name;

export const coldHandoffAcceptingUserUriSelector = ({
  coldHandoff,
}: RootState) => coldHandoff.acceptingUser.uri;

export const isColdHandoffDisabledSelector = (state: RootState) =>
  createSelector(
    isPrimaryHearingConnectingSelector,
    isColdHandoffInProgressSelector,
    (isHearingConnecting, isColdHandoffInProgress) =>
      isHearingConnecting ||
      isColdHandoffInProgress ||
      // Temporary solution for blocking Cold Handoff in Conference feature
      // https://sorenson.atlassian.net/browse/TER-2357
      state.call.callType === CallType.CONFERENCE_CALL
  )(state);

export const isColdHandoffInProgressSelector = ({ coldHandoff }: RootState) =>
  coldHandoff.status !== ColdHandoffStatus.NONE;
