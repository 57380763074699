import { httpClient } from 'common/services';
import type { EnvironmentVariablesConfig } from 'features/environment/interfaces';

async function getEnvironmentConfig() {
  const route = '/client-config';
  const response = await httpClient.get<EnvironmentVariablesConfig>(route);
  return response.data;
}

export const EnvironmentDaoService = {
  getEnvironmentConfig,
};
