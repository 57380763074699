import type { PreloadedState } from '@reduxjs/toolkit';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createReduxEnhancer } from '@sentry/react';
import _ from 'lodash';

import { callerSessionReducer } from 'features/caller-session/store';
import { appConfigReducer } from 'features/app-config/store';
import { appLoadingReducer } from 'features/app-loading/store';
import appReducer from 'features/app/store/app/appSlice';
import { audioReducer, callReducer } from 'features/call/call-base/store';
import { conferenceReducer } from 'features/call/call-conference/store';
import { callDeafReducer } from 'features/call/call-deaf/store';
import { callDetailsReducer } from 'features/call/call-details/store';
import { callHearingReducer } from 'features/call/call-hearing/store';
import { callTimerReducer } from 'features/call/call-timer/store';
import { vrsCallCommentsReducer } from 'features/call/vrs-call-comments/store';
import { vrsCallReducer } from 'features/call/vrs-call/store';
import { vrsConferenceCallReducer } from 'features/call/vrs-conference-call/store';
import { coldHandoffReducer } from 'features/cold-handoff/store';
import { customGreetingReducer } from 'features/custom-greeting/store';
import { deafPhoneNumberHiddenReducer } from 'features/deaf-phone-number-hidden/store';
import { dialPadReducer } from 'features/dial-pad/store';
import { dialedNumbersHistoryReducer } from 'features/dialed-numbers-history/store';
import { emergencyReducer } from 'features/emergency/store';
import { env } from 'features/environment/constants';
import { localVideoReducer } from 'features/local-video/store';
import { machinePreferencesReducer } from 'features/machine-preferences/store';
import { mediaDevicesReducer } from 'features/media-devices/store';
import monitorReducer from 'features/monitoring/store/monitoringSlice';
import { multiModeReducer } from 'features/multi-mode/store';
import { notificationReducer } from 'features/notification/store';
import { queueSettingsReducer } from 'features/queue-settings/store';
import { reportingReducer } from 'features/reporting/store';
import { sessionReducer } from 'features/session/store';
import { signMailReducer } from 'features/signmail/store';
import { spawnCallReducer } from 'features/spawn-call/store';
import { statsReducer } from 'features/stats/store';
import { teamingReducer } from 'features/teaming/teaming-base/store';
import { userReducer } from 'features/user/store';
import { vcoReducer } from 'features/vco/store';
import { videoPrivacyReducer } from 'features/video-privacy/store';
import { voiceSessionReducer } from 'features/voice-session/store';
import { vpnDetectorReducer } from 'features/vpn-detector/store';
import { vriChatReducer } from 'features/vri-chat/store';
import { vrsChatReducer } from 'features/vrs-chat/store';

const rootReducer = combineReducers({
  app: appReducer,
  appConfig: appConfigReducer,
  session: sessionReducer,
  user: userReducer,
  queueSettings: queueSettingsReducer,
  call: callReducer,
  callerSession: callerSessionReducer,
  audio: audioReducer,
  spawnCall: spawnCallReducer,
  voiceSession: voiceSessionReducer,
  callHearing: callHearingReducer,
  callDeaf: callDeafReducer,
  notification: notificationReducer,
  mediaDevices: mediaDevicesReducer,
  teaming: teamingReducer,
  dialPad: dialPadReducer,
  dialedNumbersHistory: dialedNumbersHistoryReducer,
  vco: vcoReducer,
  localVideo: localVideoReducer,
  conference: conferenceReducer,
  callDetails: callDetailsReducer,
  vrsCall: vrsCallReducer,
  videoPrivacy: videoPrivacyReducer,
  vrsCallComments: vrsCallCommentsReducer,
  emergency: emergencyReducer,
  machinePreferences: machinePreferencesReducer,
  coldHandoff: coldHandoffReducer,
  customGreeting: customGreetingReducer,
  vrsConferenceCall: vrsConferenceCallReducer,
  vrsChat: vrsChatReducer,
  vriChat: vriChatReducer,
  multiMode: multiModeReducer,
  signMail: signMailReducer,
  monitor: monitorReducer,
  deafPhoneNumberHidden: deafPhoneNumberHiddenReducer,
  appLoading: appLoadingReducer,
  stats: statsReducer,
  callTimer: callTimerReducer,
  reporting: reportingReducer,
  vpnDetector: vpnDetectorReducer,
});

const sentryReduxEnhancer = createReduxEnhancer({});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    preloadedState,
    reducer: rootReducer,
    enhancers: [sentryReduxEnhancer],
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
      }),
    devTools: env.isDevelopmentBuild && {
      maxAge: 200,
    },
  });
};

/**
 * Guarantees that only one Redux store will be set up,
 * and the same store will always be returned.
 *
 * (The only exception is during local development,
 * since hot module replacement will cause this to be redefined
 * each time the dev server performs HMR.)
 */
export const setupStoreOnce = _.once(setupStore);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
