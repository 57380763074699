import type { ThemeComponents } from 'features/theme/types';

export default function TextField(): Partial<ThemeComponents> {
  return {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        fullWidth: true,
        InputLabelProps: {
          shrink: true,
          focused: false,
          disableAnimation: true,
          size: 'small',
        },
        InputProps: {
          disableUnderline: true,
        },
      },
    },
  };
}
