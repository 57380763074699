import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';
import { DialPadVariant } from 'features/dial-pad/enums';
import type { DialPadState } from 'features/dial-pad/interfaces';

export const initialDialPadState: DialPadState = {
  isDialPadOpened: false,
  dialPadVariant: DialPadVariant.PHONE_DEALER,
};

const dialPadSlice = createSlice({
  name: 'dial-pad',
  initialState: initialDialPadState,
  reducers: {
    toggleDialPad: (state) => {
      state.isDialPadOpened = !state.isDialPadOpened;
    },
    setDialPadVariant: (state, action: PayloadAction<DialPadVariant>) => {
      state.dialPadVariant = action.payload;
    },
    resetDialPad: () => initialDialPadState,
  },
});

export const { toggleDialPad, resetDialPad, setDialPadVariant } =
  dialPadSlice.actions;

export const isDialPadOpenedSelector = ({ dialPad }: RootState) =>
  dialPad.isDialPadOpened;

export const dialPadVariantSelector = ({ dialPad }: RootState) =>
  dialPad.dialPadVariant;

export const dialPadReducer = dialPadSlice.reducer;
