export enum ConnectionChangeType {
  UNKNOWN, // The connection change type is not known
  HEARING_CONNECT = 'HearingConnect', // After VI click take call on H2D call, system requests transfer of hearing leg, once hearing connected
  DEAF_CONNECT = 'DeafConnect', // After VI click take call on D2H call, system requests transfers of deaf leg, once deaf connected
  AGENT_CONNECT_DEAF = 'AgentConnectDeaf', // VI click callee connect on H2D call, once deaf connected
  AGENT_CONNECT_HEARING = 'AgentConnectHearing', // VI click callee connect D2H call, once hearing connected
  HEARING_DISCONNECT = 'HearingDisconnect', // Hearing user press disconnect
  DEAF_DISCONNECT = 'DeafDisconnect', // Deaf user press disconnect
  AGENT_DISCONNECT_HEARING = 'AgentDisconnectHearing', // VI click disconnect Hearing
  AGENT_DISCONNECT_DEAF = 'AgentDisconnectDeaf', // VI click disconnect Deaf
  AGENT_HOLD = 'AgentHold', // The call has been placed on hold in the application
  AGENT_UNHOLD = 'AgentUnhold', // The call has been placed taken off hold in the application
  AGENT_HANDOFF = 'AgentHandoff', // VI has handed off the call
  AGENT_CONNECT_HEARING_TO_DEAF_MESSAGE = 'AgentConnectHearingToDeafMessage', // The agent has begun recording a hearing to deaf message
  AGENT_DISCONNECT_HEARING_TO_DEAF_MESSAGE = 'AgentDisconnectHearingToDeafMessage', // The agent has stopped recording a hearing to deaf message
  RETURN_TO_QUEUE_DISCONNECT = 'ReturnToQueueDisconnect', // VI returns the call to queue
  CONNECT_VCO = 'ConnectVco', // The system automatically established an audio connection to the deaf party
  DISCONNECT_VCO = 'DisconnectVco', // The system ended the audio connection to the deaf party
  AGENT_CONNECT_VCO = 'AgentConnectVco', // VI established an audio connection to the deaf party
  AGENT_DISCONNECT_VCO = 'AgentDisconnectVco', // VI disconnected audio connection to the deaf party or muted the deaf party
  DEAF_CONNECT_HEARING_VIDEO = 'DeafConnectHearingVideo', // Wavello feature, record this when system connects to the hearing video feature (Wavello) and deaf person initiated hearing video connection
  HEARING_CONNECT_HEARING_VIDEO = 'HearingConnectHearingVideo', // Wavello feature, record this when system connects to the hearing video feature (Wavello) and hearing person initiated hearing video connection
  DEAF_DISCONNECT_HEARING_VIDEO = 'DeafDisconnectHearingVideo', // Wavello feature, record this when system disconnects from the hearing video feature (Wavello) and deaf person initiated hearing video disconnect
  HEARING_DISCONNECT_HEARING_VIDEO = 'HearingDisconnectHearingVideo', // Wavello feature, record this when system disconnects from the hearing video feature (Wavello) and hearing person initiated hearing video disconnect
  EXTERNAL_CONFERENCE_CONNECT = 'ExternalConferenceConnect', // VI initiate conference, connected to external conference app
  EXTERNAL_CONFERENCE_DISCONNECT = 'ExternalConferenceDisconnect', // Disconnected from external conference app
}
