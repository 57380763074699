import { useTranslation } from 'react-i18next';

import { isAcceptingUserSelector } from 'features/teaming/teaming-base/store/';
import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { CallNotificationType } from 'features/call/call-events/enums';
import { handleError } from 'features/notification/store';
import { sendRnsNotification } from 'features/rns/store';
import type {
  VoiceSessionCreatingCompletedRnsEventData,
  VoiceSessionCreatingFailedRnsEventData,
  VoiceSessionCreatingStartedRnsEventData,
} from 'features/voice-session/interfaces';

export const useVoiceSessionNotifications = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('translation', {
    keyPrefix: 'voiceSession',
  });

  const isAcceptingUser = useAppSelector(isAcceptingUserSelector);

  const sendVoiceSessionCreatingStartedNotification = async ({
    hearingPhoneNumber,
  }: VoiceSessionCreatingStartedRnsEventData) => {
    if (isAcceptingUser) {
      return;
    }
    try {
      await dispatch(
        sendRnsNotification({
          eventName: CallNotificationType.VOICE_SESSION_CREATING_STARTED,
          data: {
            hearingPhoneNumber,
          },
        })
      ).unwrap();
    } catch (error) {
      dispatch(
        handleError({
          message: t('rnsErrorMessages.sendVoiceSessionCreatingStarted'),
          methodName: 'sendVoiceSessionCreatingStartedNotification',
          error,
        })
      );
    }
  };

  const sendVoiceSessionCreatingFailedNotification = async ({
    hearingPhoneNumber,
  }: VoiceSessionCreatingFailedRnsEventData) => {
    try {
      if (isAcceptingUser) {
        return;
      }
      await dispatch(
        sendRnsNotification({
          eventName: CallNotificationType.VOICE_SESSION_CREATING_FAILED,
          data: {
            hearingPhoneNumber: hearingPhoneNumber,
          },
        })
      ).unwrap();
    } catch (error) {
      handleError({
        message: t('rnsErrorMessages.sendVoiceSessionCreatingFailed'),
        methodName: 'sendVoiceSessionCreatingFailedNotification',
        error,
      });
    }
  };

  const sendVoiceSessionCreatingCompletedNotification = async ({
    voiceSessionId,
  }: VoiceSessionCreatingCompletedRnsEventData) => {
    if (isAcceptingUser) {
      return;
    }
    try {
      await dispatch(
        sendRnsNotification({
          eventName: CallNotificationType.VOICE_SESSION_CREATING_COMPLETED,
          data: {
            voiceSessionId,
          },
        })
      ).unwrap();
    } catch (error) {
      handleError({
        message: t('rnsErrorMessages.sendVoiceSessionCreatingCompleted'),
        methodName: 'sendVoiceSessionCreatingCompletedNotification',
        error,
      });
    }
  };

  return {
    sendVoiceSessionCreatingStartedNotification,
    sendVoiceSessionCreatingFailedNotification,
    sendVoiceSessionCreatingCompletedNotification,
  };
};
