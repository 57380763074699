import { createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';
import { handleError } from 'features/notification/store';
import { VriChatParticipant } from 'features/vri-chat/enums';
import {
  calculateChatTurns,
  setVriChatAnalytics,
} from 'features/vri-chat/helpers';
import {
  setVriChatLastMessageFrom,
  setVriChatTurns,
  vriChatLastMessageFromSelector,
  vriChatReasonSelector,
  vriChatTurnsSelector,
} from 'features/vri-chat/store/';

export const updateOutgoingTurn = createAsyncThunk(
  'updateOutgoingTurn',
  async (payload, { dispatch, getState }) => {
    const state = getState() as RootState;
    const vriChatLastMessageFrom = vriChatLastMessageFromSelector(state);

    if (vriChatLastMessageFrom === VriChatParticipant.USER) {
      return;
    }

    await dispatch(setVriChatTurn(VriChatParticipant.USER));
  }
);

export const updateIncomingTurn = createAsyncThunk(
  'updateIncomingTurn',
  async (payload, { dispatch, getState }) => {
    const state = getState() as RootState;
    const vriChatLastMessageFrom = vriChatLastMessageFromSelector(state);

    if (vriChatLastMessageFrom === VriChatParticipant.DEAF) {
      return;
    }

    await dispatch(setVriChatTurn(VriChatParticipant.DEAF));
  }
);

export const setVriChatTurn = createAsyncThunk(
  'setVriChatTurn',
  async (payload: VriChatParticipant, { dispatch, getState }) => {
    const state = getState() as RootState;
    const chatTurns = vriChatTurnsSelector(state);
    const updatedChatTurns = calculateChatTurns(chatTurns);

    dispatch(setVriChatLastMessageFrom(payload));
    dispatch(setVriChatTurns(updatedChatTurns));
    dispatch(sendVriChatAnalytics());
  }
);

export const sendVriChatAnalytics = createAsyncThunk(
  'sendVriChatAnalytics',
  async (payload, { dispatch, getState }) => {
    const state = getState() as RootState;
    const chatTurns = vriChatTurnsSelector(state);
    const chatReason = vriChatReasonSelector(state);

    try {
      dispatch(setVriChatAnalytics('setVriChatTurn', chatTurns, chatReason));
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'sendVriChatAnalytics',
        })
      );
    }
  }
);
