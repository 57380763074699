import type { RootState } from 'features/app/store/store';

export const userIdSelector = ({ user }: RootState) => user.user.id;

export const agentNumberSelector = ({ user }: RootState) =>
  user.user.agentNumber;

export const userSelector = ({ user }: RootState) => user.user;

export const userNameSelector = ({ user }: RootState) => user.user.userName;

export const isUserAtHomeSelector = ({ user }: RootState) => user.isAtHome;

export const callCenterCodeSelector = ({ user }: RootState) =>
  user.user.callCenterCode;
