import type { FC, MutableRefObject, PropsWithChildren } from 'react';

import styled from 'features/theme/styled';

interface ScrollBoxProps extends PropsWithChildren {
  anchorRef?: MutableRefObject<HTMLDivElement | null>;
  className?: string;
}

export const ScrollBox: FC<ScrollBoxProps> = ({
  children,
  className,
  anchorRef,
}) => {
  return (
    <Container className={className}>
      {children}
      <Anchor ref={anchorRef} />
    </Container>
  );
};

const Container = styled('div')`
  overflow: auto;
  visibility: hidden;
  & * {
    overflow-anchor: none;
  }
  &:hover {
    visibility: visible;
  }
`;

const Anchor = styled('div')`
  overflow-anchor: auto;
  height: 1px;
`;
