import type { FC } from 'react';
import { useEffect } from 'react';

import { useMediaDevices } from 'features/media-devices/hooks';

export const DeviceChangeHandler: FC = () => {
  const { getDevices } = useMediaDevices();

  useEffect(() => {
    navigator.mediaDevices.addEventListener('devicechange', getDevices);
    return () =>
      navigator.mediaDevices.removeEventListener('devicechange', getDevices);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};
