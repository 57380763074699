import type { Mode } from 'features/multi-mode/enums';

const modeLocalStorageKey = 'Mode';

export class ModeStorageService {
  static getMode() {
    return localStorage.getItem(modeLocalStorageKey) as Mode | null;
  }

  static setMode(mode: Mode) {
    localStorage.setItem(modeLocalStorageKey, mode);
  }

  static clear() {
    localStorage.removeItem(modeLocalStorageKey);
  }
}
