import type { ConferenceState } from './conferenceSlice';
import { initialConferenceState } from './conferenceSlice';

import { ConferenceType } from 'features/call/call-conference/enums';

export const conferenceZoomJoinedMock: ConferenceState = {
  ...initialConferenceState,
  type: ConferenceType.ZOOM,
  url: 'https://us02web.zoom.us/j/85359894149?pwd=MkVCNlBMUmV5Z3E5emRRdnczV0JuUT09',
  isJoinedToZoom: true,
};

export const conferenceZoomNotJoinedMock: ConferenceState = {
  ...initialConferenceState,
  type: ConferenceType.ZOOM,
  url: 'https://us02web.zoom.us/j/85359894149?pwd=MkVCNlBMUmV5Z3E5emRRdnczV0JuUT09',
  isJoinedToZoom: false,
};

export const conferenceTeamsNotJoinedMock: ConferenceState = {
  ...initialConferenceState,
  type: ConferenceType.TEAMS,
  url: 'https://teams.live.com/meet/9445997164193',
  isJoinedToZoom: false,
};

export const conferenceFaceTimeMock: ConferenceState = {
  ...initialConferenceState,
  type: ConferenceType.FACE_TIME,
};
