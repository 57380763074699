import type { AudioVideoEventAttributes, EventName } from 'amazon-chime-sdk-js';
import { Subject } from 'rxjs';

import type { HearingPhoneWithDisconnectReason } from 'features/call/call-hearing/interfaces';

export interface AttendeeIdPresence {
  attendeeId: string;
  present: boolean;
  externalUserId?: string;
}

export class VoiceMeetingEventBus {
  static hearingCallDeclined$ = new Subject<HearingPhoneWithDisconnectReason>();

  static finishVoiceSession$ = new Subject<void>();

  static fatalError$ = new Subject<Error>();
  static attendeeIdPresence$ = new Subject<AttendeeIdPresence>();
  static meetingError$ = new Subject<{
    name: EventName;
    attributes: AudioVideoEventAttributes;
  }>();
}
