import type { Theme } from '@mui/material';

import Alert from './Alert';
import Avatar from './Avatar';
import Button from './Button';
import Checkbox from './Checkbox';
import CircularProgress from './CircularProgress';
import CssBaseline from './CssBaseline';
import Dialog from './Dialog';
import FormControlLabel from './FormControlLabel';
import FormHelperText from './FormHelperText';
import IconButton from './IconButton';
import Input from './Input';
import InputLabel from './InputLabel';
import LinearProgress from './LinearProgress';
import Link from './Link';
import Menu from './Menu';
import OutlinedInput from './OutlinedInput';
import Paper from './Paper';
import Popover from './Popover';
import Radio from './Radio';
import Select from './Select';
import SvgIcon from './SvgIcon';
import Switch from './Switch';
import TextField from './TextField';
import Typography from './Typography';

import type { ThemeComponents } from 'features/theme/types';

export default function ComponentsOverrides(theme: Theme): ThemeComponents {
  return {
    ...CssBaseline(theme),
    ...Alert(theme),
    ...Dialog(theme),
    ...Paper(theme),
    ...Select(theme),
    ...Link(theme),
    ...Avatar(theme),
    ...Button(theme),
    ...LinearProgress(theme),
    ...Menu(theme),
    ...SvgIcon(),
    ...Typography(theme),
    ...TextField(),
    ...InputLabel(theme),
    ...FormHelperText(theme),
    ...Input(theme),
    ...CircularProgress(theme),
    ...Switch(theme),
    ...Checkbox(theme),
    ...FormControlLabel(theme),
    ...Radio(theme),
    ...Popover(),
    ...IconButton(theme),
    ...OutlinedInput(theme),
  };
}
