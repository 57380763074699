import { voiceMeetingSessionIdMock } from 'features/voice-meeting/mocks';
import { VoiceSessionStatus } from 'features/voice-session/enums';
import type { VoiceSessionState } from 'features/voice-session/interfaces';

export const voiceSessionConnectedMock: VoiceSessionState = {
  status: VoiceSessionStatus.ACTIVE,
  sessionId: voiceMeetingSessionIdMock,
  isLoading: false,
};

export const voiceSessionDisconnectedMock: VoiceSessionState = {
  status: VoiceSessionStatus.DISCONNECTED,
  sessionId: voiceMeetingSessionIdMock,
  isLoading: false,
};
