export enum TeamingState {
  None,
  Accept,
  AdhocAcc,
  AdhocReq,
  AutoRjct,
  Cancel,
  CancelS,
  Complete,
  Push,
  ManRejct,
  Request,
  Spawn,
  Switch,
  Teaming,
  InClRjct,
  RemoteRq,
  RemoteIc,
}
