import JsSIP from 'jssip';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';

import { useAppSelector } from 'features/app/hooks';
import { mediaDevicesSelector } from 'features/media-devices/store';
import { useMediaStream } from 'features/media-streams/hooks';
import styled from 'features/theme/styled';

export const SelfView: FC = () => {
  const video = useRef<HTMLVideoElement>(null);
  const [selfViewStream, setSelfViewStream] = useState<MediaStream | null>(
    null
  );
  const { camera } = useAppSelector(mediaDevicesSelector);
  const { getVideoStream } = useMediaStream();

  const saveSelfViewStream = (stream: MediaStream) => {
    setSelfViewStream(stream);
    if (video.current) {
      video.current.srcObject = stream;
    }
  };

  const setMediaStream = async () => {
    saveSelfViewStream(await getVideoStream());
  };

  useEffect(() => {
    setMediaStream();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camera]);

  useEffect(() => {
    return () => {
      if (selfViewStream) {
        JsSIP.Utils.closeMediaStream(selfViewStream);
      }
    };
  }, [selfViewStream]);

  return <StyledVideo ref={video} autoPlay playsInline />;
};

const StyledVideo = styled('video')`
  width: 100%;
  height: 100%;
  transform: rotateY(180deg);
  pointer-events: none;
`;
