import type { ButtonProps as MuiButtonProps } from '@mui/material';
import { Button as MuiButton, CircularProgress } from '@mui/material';
import type { FC } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import type { Hotkey } from 'config/enums';
import { textEllipsis } from 'features/theme/commonCss';
import styled from 'features/theme/styled';

export interface ButtonProps extends MuiButtonProps {
  loading?: boolean;
  hotkey?: Hotkey;
  onClick?: () => void;
}

const onClickDefaultValue = () => null;

export const Button: FC<ButtonProps> = ({
  loading,
  disabled,
  children,
  size,
  hotkey = '',
  onClick = onClickDefaultValue,
  ...args
}) => {
  const renderButtonContent = () => {
    return <TruncatedText>{children}</TruncatedText>;
  };

  const circularProgressSize = size === 'large' ? 31 : 22;

  useHotkeys(hotkey, onClick, {
    enabled: Boolean(hotkey) && !disabled && !loading,
  });

  return (
    <MuiButton
      disabled={disabled || loading}
      size={size}
      onClick={onClick}
      {...args}
    >
      {loading ? (
        <CircularProgress
          data-testid='loading-circular'
          size={circularProgressSize}
          thickness={4}
          color='inherit'
          variant='indeterminate'
        />
      ) : (
        renderButtonContent()
      )}
    </MuiButton>
  );
};

const TruncatedText = styled('span')`
  ${textEllipsis}
`;
