import { ConnectionChangeType } from 'features/call/call-base/enums';
import type { DisconnectReason } from 'features/voice-session/interfaces';

const getConnectionChangeTypeForHearingByDisconnectReason = (
  disconnectReason?: DisconnectReason
) => {
  if (disconnectReason === 'disconnected by agent') {
    return ConnectionChangeType.AGENT_DISCONNECT_HEARING;
  }

  return ConnectionChangeType.HEARING_DISCONNECT;
};

const getConnectionChangeTypeForVco2LineByDisconnectReason = (
  disconnectReason?: DisconnectReason
) => {
  if (disconnectReason === 'disconnected by agent') {
    return ConnectionChangeType.AGENT_DISCONNECT_VCO;
  }

  return ConnectionChangeType.DISCONNECT_VCO;
};

export const getConnectionChangeTypeByDisconnectReason = (
  isVco: boolean,
  disconnectReason?: DisconnectReason
) => {
  if (isVco) {
    return getConnectionChangeTypeForVco2LineByDisconnectReason(
      disconnectReason
    );
  }
  return getConnectionChangeTypeForHearingByDisconnectReason(disconnectReason);
};
