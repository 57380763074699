import { normalize, schema } from 'normalizr';

import type { ChatMessage } from 'features/vri-chat/interfaces';

export const normalizeChatMessages = (messages: ChatMessage[]) => {
  const messageSchema = new schema.Entity<ChatMessage>(
    'messages',
    {},
    {
      idAttribute: 'requestId',
    }
  );
  const normalizedMessages = normalize(messages, [messageSchema]);

  return normalizedMessages.entities.messages ?? {};
};
