import { t } from 'i18next';

import { PostMessage } from 'common/enums';
import { env } from 'features/environment/constants';

export class PushNotificationService {
  private static instance: PushNotificationService;
  private readonly NOTIFICATION_OPTIONS = {
    body: t('pushNotifications.body'),
    requireInteraction: true,
    silent: false,
    badge: `${env.publicUrl}/favicon.ico`,
    icon: `${env.publicUrl}/logo_200x200.jpg`,
  };

  public static getInstance(): PushNotificationService {
    if (!PushNotificationService.instance) {
      PushNotificationService.instance = new PushNotificationService();
    }

    return PushNotificationService.instance;
  }

  createCallNotification() {
    if (!document.hasFocus()) {
      this.createCrossBrowserNotification();
    }
  }

  getNotificationPermission() {
    if (Notification.permission === 'default') {
      document.addEventListener('click', this.askPermission);
    }
    return Notification.permission !== 'denied';
  }

  private createCrossBrowserNotification() {
    if (
      navigator.userAgent.match(/chrome|chromium|crios|opr\/|edg/i) &&
      navigator.serviceWorker.controller
    ) {
      this.createSWNotification();
    } else {
      this.createBasicNotification();
    }
  }

  private async askPermission() {
    const res = await Notification.requestPermission();
    if (res === 'granted') {
      document.removeEventListener('click', this.askPermission);
    }
  }

  private createBasicNotification() {
    const notification = new Notification(
      t('pushNotifications.title'),
      this.NOTIFICATION_OPTIONS
    );
    notification.addEventListener('click', () => {
      window.focus();
      notification.close();
    });
  }

  private createSWNotification() {
    navigator.serviceWorker.controller?.postMessage({
      type: PostMessage.TAKE_CALL_NOTIFICATION,
      title: t('pushNotifications.title'),
      options: {
        ...this.NOTIFICATION_OPTIONS,
        actions: [
          {
            action: 'takecall',
            title: t('pushNotifications.actionTitle'),
          },
        ],
      },
    });
  }
}
