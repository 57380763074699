import type { FC, RefObject } from 'react';
import React from 'react';
import { typography } from '@mui/system';

import styled from 'features/theme/styled';
import theme from 'features/theme/theme';

export interface PhoneNumberInputProps {
  refInput: RefObject<HTMLInputElement>;
  title: string;
  className?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

// TODO: create UI component for this input when Doug provide access to designs
const StyledInput = styled('input')`
  ${typography(theme.typography.body1)};
  max-width: 100%;
  background-color: ${theme.palette.common.white};
  border-radius: ${theme.spacing(4)};
  border: none;
  outline: none;
`;

export const PhoneNumberInput: FC<PhoneNumberInputProps> = ({
  title,
  refInput,
  className,
  onKeyDown,
}) => {
  return (
    <StyledInput
      className={className}
      title={title}
      ref={refInput}
      onKeyDown={onKeyDown}
      autoFocus
      autoComplete='chrome-off'
    />
  );
};
