import type { Theme } from '@mui/material';

import type { ThemeComponents } from 'features/theme/types';

export default function FormHelperText(theme: Theme): Partial<ThemeComponents> {
  return {
    MuiFormHelperText: {
      styleOverrides: {
        root: theme.unstable_sx({
          mt: 3,
          color: theme.palette.grey[100],

          '&.Mui-error': {
            color: theme.palette.error.main,
          },
          '&.Mui-disabled': {
            color: theme.palette.grey[400],
          },
        }),
      },
    },
  };
}
