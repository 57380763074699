import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { SessionState } from 'features/session/interfaces';

export const initialSessionState: SessionState = {
  id: 0,
  rnsConnectionId: '',
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState: initialSessionState,
  reducers: {
    setRnsConnectionId: (state, action: PayloadAction<string>) => {
      state.rnsConnectionId = action.payload;
    },
    setSessionId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    },
    resetSessionId: (state) => {
      state.id = initialSessionState.id;
    },
  },
});

export const { setRnsConnectionId, setSessionId, resetSessionId } =
  sessionSlice.actions;

export const sessionReducer = sessionSlice.reducer;
