import { sendAnalyticsInfo } from 'features/analytics/helpers';
import type { Mode } from 'features/multi-mode/enums';

export const switchModeAnalytics = (methodName: string, mode: Mode) => {
  return sendAnalyticsInfo({
    Method: methodName,
    Message: 'Switch mode',
    Mode: mode,
  });
};
