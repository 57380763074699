import {
  captureException as sentryCaptureException,
  captureMessage as sentryCaptureMessage,
  setTag,
} from '@sentry/react';

type LoggingArguments = {
  message?: string;
  data?: Record<string, any>;
  context: string;
};

export class LoggerService {
  static error(...data: any[]) {
    console.error(...data);
    const [
      exception,
      callId,
      email,
      sipCallId,
      rnsConnectionId,
      sessionId,
      voiceSessionId,
    ] = data;

    callId && setTag('callId', callId);
    email && setTag('email', email);
    sipCallId && setTag('sipCallId', sipCallId);
    rnsConnectionId && setTag('rnsConnectionId', rnsConnectionId);
    sessionId && setTag('sessionId', sessionId);
    voiceSessionId && setTag('voiceSessionId', voiceSessionId);

    if (typeof exception === 'string') {
      sentryCaptureMessage(exception, 'error');
      return;
    }
    sentryCaptureException(exception);
  }

  static info(args: LoggingArguments) {
    console.info(...LoggerService.buildLoggingArguments('INFO', args));
  }

  private static buildLoggingArguments(
    level: string,
    { message, data, context }: LoggingArguments
  ) {
    return [
      LoggerService.buildLoggedMessage(context, level, message),
      data,
    ].filter(Boolean);
  }

  private static buildLoggedMessage(
    context: string,
    level: string,
    message?: string
  ) {
    return `[${level}] ${context}${message ? ' - ' + message : ''}`;
  }
}
