import { sendAnalyticsError } from 'features/analytics/helpers';

interface notificationErrorAnalyticsType {
  methodName: string;
  error: string;
  state?: any;
  message?: string;
}

export const notificationErrorAnalytics = ({
  methodName,
  error,
  state,
  message,
}: notificationErrorAnalyticsType) => {
  return sendAnalyticsError({
    Method: methodName,
    Message: message,
    Error: error,
    state: state,
  });
};
