export enum AppSupportedLanguage {
  EN = 'en',
  JA = 'ja',
  EN_AU = 'en-AU',
  DE = 'de',
  ES = 'es',
  IT = 'it',
  KO = 'ko',
  FR = 'fr',
  EN_GB = 'en-GB',
}
