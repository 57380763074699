import type { Theme } from '@mui/material';

import type { ThemeComponents } from 'features/theme/types';

export default function Avatar(theme: Theme): Partial<ThemeComponents> {
  return {
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
        colorDefault: theme.unstable_sx({
          typography: 'avatarLetterMedium',
          backgroundColor: theme.palette.primary[500],
          color: theme.palette.grey[100],
        }),
      },
    },
  };
}
