/* eslint-disable import/no-internal-modules */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { t } from 'i18next';

import { showNotification } from './notificationSlice';

import type { RootState } from 'features/app/store/store';
import {
  callIdSelector,
  sipCallIdSelector,
} from 'features/call/call-base/store/selectors';
import { LoggerService } from 'features/logger/services';
import { notificationErrorAnalytics } from 'features/notification/helpers';
import type { HandleErrorPayload } from 'features/notification/interfaces';
import {
  rnsConnectionIdSelector,
  sessionIdSelector,
} from 'features/session/store/selectors';
import { userSelector } from 'features/user/store';
import { voiceSessionIdSelector } from 'features/voice-session/store';

/**
 * Gathers info for context about the error,
 * shows it to the user (optional),
 * and sends it to console, Sentry, and DataSet.
 */
export const handleError = createAsyncThunk(
  'app/handleError',
  async (
    {
      error,
      title,
      state,
      message,
      methodName,
      disableUserNotification = false,
    }: HandleErrorPayload,
    { dispatch, getState }
  ) => {
    const store = getState() as RootState;
    const callId = callIdSelector(store);
    const { email } = userSelector(store);
    const sipCallId = sipCallIdSelector(store);
    const rnsConnectionId = rnsConnectionIdSelector(store);
    const sessionId = sessionIdSelector(store);
    const voiceSessionId = voiceSessionIdSelector(store);

    LoggerService.error(
      error,
      callId,
      email,
      sipCallId,
      rnsConnectionId,
      sessionId,
      voiceSessionId
    );

    if (!disableUserNotification) {
      dispatch(
        showNotification({
          severity: 'error',
          title: title ?? t('somethingWentWrong'),
          message,
        })
      );
    }

    dispatch(
      notificationErrorAnalytics({
        methodName: methodName ?? 'handleError',
        error: error.message,
        state: state ?? {},
        message: message ?? '',
      })
    );
  }
);
