import type { Theme } from '@mui/material';

import type { ThemeComponents } from 'features/theme/types';

export default function Select(theme: Theme): Partial<ThemeComponents> {
  return {
    MuiSelect: {
      defaultProps: {
        variant: 'filled',
        disableUnderline: true,
      },
      styleOverrides: {
        select: theme.unstable_sx({
          pl: 7,
        }),
        icon: theme.unstable_sx({ color: theme.palette.common.white }),
      },
    },
  };
}
