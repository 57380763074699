import { useTranslation } from 'react-i18next';
import { typography } from '@mui/system';

import { CallDetailsButtonConnect } from 'features/call/call-details/components';
import { PhoneNumberInput } from 'features/phone-number/components';
import { useValidatePhoneNumberInput } from 'features/phone-number/hooks';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';
import { Label } from 'features/ui/elements';

const PhoneNumberInputStyle = styled(PhoneNumberInput)`
  margin-top: ${theme.spacing(3)};
  margin-bottom: ${theme.spacing(3)};
  padding: ${theme.spacing(4, 6)};
`;

const LabelStyle = styled(Label)`
  display: inline-block;
`;

const CallDetailsButtonConnectStyle = styled(CallDetailsButtonConnect)`
  margin-top: ${theme.spacing(6)};
`;

export const NewCallee = ({
  onClick,
  error,
  isLoading,
}: {
  onClick: (phoneNumber: string) => void;
  error?: string;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'addCallee',
  });
  const { ref, isPhoneNumberValid, unmaskedValue } =
    useValidatePhoneNumberInput();

  const textDecider = () => {
    if (error) {
      return <CallError>{error}</CallError>;
    } else {
      return <LabelStyle text={t('description')} />;
    }
  };

  return (
    <>
      <Label text={t('label')} />
      <PhoneNumberInputStyle title='phoneNumber' refInput={ref} />
      {textDecider()}
      <CallDetailsButtonConnectStyle
        data-testid='call-details-connect-button'
        onClick={() => onClick(unmaskedValue)}
        disabled={!isPhoneNumberValid}
        loading={isLoading}
      />
    </>
  );
};

const CallError = styled('p')`
  color: ${theme.palette.error.light};
  font-weight: 400;
  ${typography(theme.typography.label)};
`;
