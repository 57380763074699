import type { Theme } from '@mui/material';

import type { ThemeComponents } from 'features/theme/types';

export default function FormControlLabel(
  theme: Theme
): Partial<ThemeComponents> {
  return {
    MuiFormControlLabel: {
      defaultProps: {
        componentsProps: {
          typography: {
            variant: 'label',
            color: theme.palette.common.white,
          },
        },
      },
      styleOverrides: {
        root: theme.unstable_sx({
          m: 0,
        }),
        label: theme.unstable_sx({
          '&.Mui-disabled': {
            color: theme.palette.grey[300],
          },
        }),
        labelPlacementStart: theme.unstable_sx({
          mr: 0,
        }),
        labelPlacementTop: theme.unstable_sx({
          mb: 0,
        }),
        labelPlacementBottom: theme.unstable_sx({
          mt: 0,
        }),
      },
    },
  };
}
