import type { VideoMessageData } from 'features/signmail/interfaces';

export const getGreetingVideoUrl = (videoMessageData: VideoMessageData) => {
  if (!videoMessageData.GreetingURL) {
    return '';
  }

  const url = new URL(videoMessageData.GreetingURL);
  const params = new URLSearchParams(url.search);
  params.append('downloadSpeed', videoMessageData.DownloadSpeed);
  url.search = params.toString();

  return url.toString();
};
