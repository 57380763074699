import { SoundName } from 'features/audio-player/enums';
import type { ISound } from 'features/audio-player/services';

export const soundOptionsMap: Record<SoundName, SoundOptions> = {
  [SoundName.RING_BACK]: {},
  [SoundName.RINGING]: { loop: true },
  [SoundName.ACCEPTED]: {},
  [SoundName.REJECTED]: { volume: 0.5 },
  [SoundName.HEARING_RINGING]: { loop: true },
  [SoundName.EMERGENCY_RINGING]: { loop: true, loopInterval: 5000 },
  [SoundName.HEARING_TO_DEAF_RINGING]: { loop: false },
  [SoundName.DEAF_TO_HEARING_RINGING]: { loop: false },
};

type SoundOptions = Omit<ISound, 'name'>;
