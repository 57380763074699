import { AppConfigService } from 'features/app-config/services';
import { fetchAppConfig } from 'features/app-config/store';
import { useAppDispatch } from 'features/app/hooks';
import { useEmergencyFlag } from 'features/emergency/hooks';
import { setEmergencyPhoneNumbers } from 'features/emergency/store';
import { handleError } from 'features/notification/store';

export const useFetchAppConfig = () => {
  const dispatch = useAppDispatch();
  const { isEmergencyEnabled } = useEmergencyFlag();

  const fetchConfig = async () => {
    try {
      const config = await dispatch(fetchAppConfig()).unwrap();
      if (isEmergencyEnabled) {
        dispatch(setEmergencyPhoneNumbers(config));
      }
      AppConfigService.setAppConfig(config);
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'fetchConfig',
        })
      );
    }
  };

  return { fetchConfig };
};
