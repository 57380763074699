import type { Theme } from '@mui/material';

import FontStyles from 'features/theme/fontStyles';

export default function CssBaseline(theme: Theme) {
  return {
    MuiCssBaseline: {
      styleOverrides: `
        ${FontStyles}

        html, body, #root {
          height: 100%;
          min-width: 100%;
        }

        h1, h2, h3, h4, h5, h6 {
          margin: 0;
        }

        a {
          text-decoration: none;
        }

        &::-webkit-scrollbar {
          width: 6px;
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 6px;
          background-color: ${theme.palette.grey[600]};
        }
      `,
    },
  };
}
