import { useFlags } from 'launchdarkly-react-client-sdk';

import { env } from 'features/environment/constants';

export const useEmergencyFlag = () => {
  const { vrsEmergency } = useFlags();

  const isEmergencyEnabled = vrsEmergency && !env.isDevelopmentBuild;

  return { isEmergencyEnabled };
};
