import packageJson from '../../../../package.json';

import { EndpointProductService } from 'common/services/EndpointProductService';
import { endpointName } from 'features/sip/constants';

export class ProductService {
  static get productName() {
    if (
      EndpointProductService.isSignTimeWeb ||
      EndpointProductService.isSignTimeIos
    ) {
      return endpointName;
    }

    return packageJson.name;
  }

  static get version() {
    return packageJson.version;
  }
}
