import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { AppConfigState } from 'features/app-config/interfaces';
import { AppConfigDaoService } from 'features/app-config/services';

export const initialAppConfigSlice: AppConfigState = {
  isAppConfigLoadingSuccess: false,
};

export const appConfigSlice = createSlice({
  name: 'app-config',
  initialState: initialAppConfigSlice,
  reducers: {
    finishAppConfigLoading: (state) => {
      state.isAppConfigLoadingSuccess = true;
    },
  },
});

const { finishAppConfigLoading } = appConfigSlice.actions;

export const appConfigReducer = appConfigSlice.reducer;

export const fetchAppConfig = createAsyncThunk(
  'app-config/fetchAppConfig',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const config = await AppConfigDaoService.getAppConfig();
      dispatch(finishAppConfigLoading());
      return config;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
