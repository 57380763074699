import type { FC } from 'react';
import { typography } from '@mui/system';

import styled from 'features/theme/styled';
import theme from 'features/theme/theme';

export interface LabelProps {
  text: string;
}

const StyledLabel = styled('div')`
  color: ${theme.palette.grey[100]};
  display: inline-block;
  ${typography(theme.typography.label)};
`;

export const Label: FC<LabelProps> = ({ text }) => {
  return <StyledLabel>{text}</StyledLabel>;
};
