import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ConferenceType } from 'features/call/call-conference/enums';
import { ConferenceWindowService } from 'features/call/call-conference/services';
import { leaveRemoteTeaming } from 'features/teaming/teaming-base/store';

export interface ConferenceState {
  type: ConferenceType;
  url: string;
  isJoinedToZoom: boolean;
}

export const initialConferenceState: ConferenceState = {
  type: ConferenceType.NONE,
  url: '',
  isJoinedToZoom: false,
};

export const conferenceSlice = createSlice({
  name: 'conference',
  initialState: initialConferenceState,
  reducers: {
    setConferenceType: (state, action: PayloadAction<ConferenceType>) => {
      state.type = action.payload;
    },
    setConferenceUrl: (state, action: PayloadAction<string>) => {
      state.url = action.payload;
    },
    setJoinedToZoom: (state) => {
      state.isJoinedToZoom = true;
    },
    resetConference: () => initialConferenceState,
  },
});

export const {
  setConferenceType,
  setConferenceUrl,
  setJoinedToZoom,
  resetConference,
} = conferenceSlice.actions;

export const leaveConferenceTeaming = createAsyncThunk(
  'conference-teaming/leave',
  async (payload, { dispatch }) => {
    await dispatch(leaveRemoteTeaming());
    ConferenceWindowService.close();
  }
);

export const conferenceReducer = conferenceSlice.reducer;
