import type { Breadcrumb } from '@sentry/types';

export const formatJsSipBreadcrumb = (breadcrumb: Breadcrumb): Breadcrumb => {
  if (breadcrumb.message) {
    breadcrumb.message = breadcrumb.message
      .replace(/%c/g, '')
      .replace(/\+\d+ms/g, '')
      .replace(/color: #[a-z0-9]{3,6}/gi, '')
      .replace(/color: inherit/g, '');
  }

  return breadcrumb;
};
