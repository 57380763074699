import { Typography } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'features/app/hooks';
import { useCallHearingParticipantHandlers } from 'features/call/call-accepted/hooks';
import { useHearings } from 'features/call/call-hearing/hooks';
import { ConnectedPhoneNumbers } from 'features/connected-phone-numbers/components';
import { isAcceptingUserSelector } from 'features/teaming/teaming-base/store';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';

export const AlsoConnectedTo: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'alsoConnectedTo',
  });
  const { activeSecondaryHearings, showAlsoConnectedTo } = useHearings();
  const { handleHearingDisconnect } = useCallHearingParticipantHandlers();
  const isAcceptingUser = useAppSelector(isAcceptingUserSelector);

  if (!showAlsoConnectedTo) {
    return null;
  }

  return (
    <Container>
      <Head>
        <Typography
          variant='textLargeMedium'
          color={theme.palette.common.white}
        >
          {t('title')}
        </Typography>
      </Head>
      <ConnectedPhoneNumbers
        list={activeSecondaryHearings}
        onHangUp={handleHearingDisconnect}
        canHangUp={!isAcceptingUser}
      />
    </Container>
  );
};

const Container = styled('div')`
  max-width: 242px;
  width: 100%;
  border-radius: ${theme.spacing(4)};
  padding: ${theme.spacing(8)};
  margin-top: ${theme.spacing(8)};
  background-color: ${theme.palette.grey[800]};
`;

const Head = styled('div')`
  color: ${theme.palette.common.white};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${theme.spacing(4)};
`;
