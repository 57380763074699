import { Typography } from '@mui/material';
import type { FC } from 'react';

import packageJson from '../../../../../package.json';

import theme from 'features/theme/theme';

export const AppVersion: FC = () => {
  const appVersion = packageJson.version;

  return (
    <>
      {appVersion && (
        <Typography
          variant='body1'
          color={theme.palette.common.white}
          p={theme.spacing(7, 0, 0, 2)}
        >
          v.{appVersion}
        </Typography>
      )}
    </>
  );
};
