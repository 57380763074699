import type { ThemeOptions } from '@mui/material';

const Typography: ThemeOptions['typography'] = {
  fontFamily: 'Inter, sans-serif',
  fontSize: 14,
  htmlFontSize: 14,
  h1: {
    fontSize: 36,
    fontFamily: 'StabilGrotesk',
    lineHeight: '47px',
    fontWeight: 500,
    letterSpacing: '0.1px',
  },
  h2: {
    fontSize: 24,
    fontFamily: 'StabilGrotesk',
    lineHeight: '31px',
    fontWeight: 600,
  },
  h3: {
    fontSize: 18,
    fontFamily: 'StabilGrotesk',
    lineHeight: '23px',
    fontWeight: 600,
  },
  body1: {
    fontSize: 18,
    lineHeight: '28px',
    fontWeight: 400,
  },
  body2: {
    fontSize: 16,
    lineHeight: '19px',
    fontWeight: 400,
  },
  buttonLarge: {
    fontSize: 24,
    fontFamily: 'StabilGrotesk',
    lineHeight: '31px',
    fontWeight: 600,
  },
  buttonMedium: {
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: 500,
  },
  buttonSmall: {
    fontSize: 18,
    lineHeight: '22px',
    fontWeight: 500,
  },
  caption: {
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 500,
  },
  textMediumRegular: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 400,
  },
  // TODO: will change when designer provide typography labels for dial pad
  dialPadInput: {
    fontSize: 28,
    lineHeight: '32px',
    fontWeight: 400,
  },
  dialPadButtonValue: {
    fontSize: 32,
    fontFamily: 'StabilGrotesk',
    lineHeight: '32px',
    fontWeight: 500,
  },
  // TODO: Change this after the designer will unify all labels in UI
  label: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
  },
  link1: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '19px',
  },
  link2: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '17px',
  },

  // Old typography
  h4: {
    fontSize: 32,
    lineHeight: '48px',
    fontWeight: 400,
    letterSpacing: 0.25,
  },
  h5: {
    fontSize: 24,
    lineHeight: '36px',
    fontWeight: 400,
    letterSpacing: 0,
  },
  h6: {
    fontSize: 20,
    lineHeight: '28px',
    fontWeight: 500,
    letterSpacing: 0.15,
  },
  subtitle1: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    letterSpacing: 0.15,
  },
  subtitle2: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
    letterSpacing: 0.1,
  },
  overline: {
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 400,
    letterSpacing: 1,
  },
  avatarLetterLarge: {
    fontSize: 20,
    lineHeight: '28px',
    fontWeight: 400,
    letterSpacing: 0.14,
  },
  avatarLetterMedium: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 400,
    letterSpacing: 0.14,
  },
  avatarLetterSmall: {
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 400,
    letterSpacing: 0.14,
  },
  inputLabel: {
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 400,
    letterSpacing: 0.15,
  },
  helperText: {
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 400,
    letterSpacing: 0.4,
  },
  inputText: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 400,
    letterSpacing: 0.15,
  },
  tooltip: {
    fontSize: 10,
    lineHeight: '16px',
    fontWeight: 500,
    letterSpacing: 0,
  },
  textLargeMedium: {
    fontSize: 18,
    lineHeight: '28px',
    fontWeight: 500,
  },
};

export default Typography;
