import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import {
  addPlusToPhoneNumber,
  parsePhoneNumber,
  parsePhoneNumberWithRemovePlus,
} from 'features/call/call-base/helpers';
import { hearingAudioReady } from 'features/call/call-base/store';
import { callIdSelector } from 'features/call/call-base/store/selectors';
import { deafPhoneNumberByModeSelector } from 'features/call/call-deaf/store';
import { useHearings } from 'features/call/call-hearing/hooks';
import { HearingParticipantType } from 'features/call/call-hearing/interfaces';
import {
  addHearing,
  callErrorSelector,
  clearCallError,
  hearingsSelector,
  primaryHearingPhoneNumberSelector,
  setCallError,
  setCallHearingId,
  setHearingStatusWithId,
  setPrimaryHearingDirection,
  setPrimaryHearingPhoneNumber,
} from 'features/call/call-hearing/store';
import { ParticipantsStatusEventBus } from 'features/call/call-status/services';
import { VrsCallType } from 'features/call/vrs-call/enums';
import { vrsCallTypeSelector } from 'features/call/vrs-call/store/vrsCallSelectors';
import { isVrsModeSelector } from 'features/multi-mode/store';
import { IsN11Number } from 'features/n11/helpers';
import { ConvertN11ToNumber } from 'features/n11/services';
import { handleError } from 'features/notification/store';
import { isTeamingInProgressSelector } from 'features/teaming/teaming-base/store';
import { agentNumberSelector, userNameSelector } from 'features/user/store';
import {
  useVoiceMeetingSessionConnect,
  useVoiceMeetingSetupListeners,
} from 'features/voice-meeting/hooks';
import type { VoiceSessionHearingParticipant } from 'features/voice-meeting/interfaces';
import { VoiceMeetingService } from 'features/voice-meeting/services';
import { VoiceSessionStatus } from 'features/voice-session/enums';
import { useVoiceSessionNotifications } from 'features/voice-session/hooks';
import {
  resetVoiceSession,
  setVoiceSession,
  setVoiceSessionLoading,
  setVoiceSessionStatus,
} from 'features/voice-session/store';

export const useVoiceMeetingSessionInit = () => {
  const dispatch = useAppDispatch();
  const primaryHearingPhoneNumber = useAppSelector(
    primaryHearingPhoneNumberSelector
  );
  const deafPhoneNumberByMode = useAppSelector(deafPhoneNumberByModeSelector);
  const agentNumber = useAppSelector(agentNumberSelector);
  const agentUserName = useAppSelector(userNameSelector);
  const callId = useAppSelector(callIdSelector);
  const isTeaming = useAppSelector(isTeamingInProgressSelector);
  const hearingsInState = useAppSelector(hearingsSelector);
  const vrsCallType = useAppSelector(vrsCallTypeSelector);

  const { startNewSession, connectUserToExistingSession } =
    useVoiceMeetingSessionConnect();
  const { setupListeners } = useVoiceMeetingSetupListeners();
  useHearings();
  const isVrs = useAppSelector(isVrsModeSelector);
  const { t } = useTranslation('translation', {
    keyPrefix: 'addCallee',
  });
  const { t: ct } = useTranslation('translation', {
    keyPrefix: 'callErrors',
  });
  const callError = useAppSelector(callErrorSelector);
  const {
    sendVoiceSessionCreatingStartedNotification,
    sendVoiceSessionCreatingFailedNotification,
  } = useVoiceSessionNotifications();

  const addHearingParticipantsToStore = (
    hearings: VoiceSessionHearingParticipant[]
  ) => {
    // if its call type emergency callback then the first hearing is the primary hearing
    if (vrsCallType === VrsCallType.EMERGENCY_CALLBACK) {
      const primaryHearing = hearings[0];
      const isConnected = primaryHearing.state === 'connected';
      const parsedNumber = parsePhoneNumberWithRemovePlus(
        primaryHearing.phoneNumber
      );
      dispatch(setPrimaryHearingPhoneNumber(parsedNumber));
      dispatch(setPrimaryHearingDirection(primaryHearing.direction));
      dispatch(setCallHearingId(primaryHearing));
      setHearingStatusWithId({
        id: primaryHearing.id,
        status: isConnected ? 'connected' : 'disconnected',
        phoneNumber: parsePhoneNumberWithRemovePlus(parsedNumber),
      });
      hearings.shift();
    }
    for (const hearing of hearings) {
      const existingHearing = hearingsInState.find(
        (h) =>
          parsePhoneNumberWithRemovePlus(h.phoneNumber) ===
          parsePhoneNumberWithRemovePlus(hearing.phoneNumber)
      );

      if (existingHearing) {
        const { id, type, phoneNumber } = existingHearing;
        const isConnected = hearing.state === 'connected';

        if (type === HearingParticipantType.PRIMARY) {
          dispatch(setPrimaryHearingDirection(hearing.direction));
          dispatch(setCallHearingId({ id, phoneNumber }));
        } else if (
          type === HearingParticipantType.VCO2LINE ||
          type === HearingParticipantType.SECONDARY
        ) {
          setHearingStatusWithId({
            id,
            status: isConnected ? 'connected' : 'disconnected',
            phoneNumber,
          });
        }
      } else {
        dispatch(
          addHearing({
            id: hearing.id,
            phoneNumber: hearing.phoneNumber,
            name: hearing.phoneNumber,
            location: '',
            phoneExtension: '',
            status: hearing.state as 'connected' | 'disconnected',
            type: HearingParticipantType.SECONDARY,
            isLoading: false,
          })
        );
      }
    }
  };

  const replaceN11Number = async (
    phoneNumber: string,
    isSecondary: boolean = false
  ) => {
    try {
      const data = await ConvertN11ToNumber(phoneNumber, callId);
      let replacedNumber = data.PhoneNumber;
      if (
        data.PhoneNumber === '' ||
        data.PhoneNumber === 0 ||
        data.N11Status === 1
      ) {
        dispatch(setCallError(t('addCallError')));
        return phoneNumber;
      }
      if (!isSecondary) {
        dispatch(setPrimaryHearingPhoneNumber(replacedNumber));
      } else {
        dispatch(
          addHearing({
            id: '',
            phoneNumber: addPlusToPhoneNumber(parsePhoneNumber(replacedNumber)),
            name: phoneNumber,
            location: '',
            phoneExtension: '',
            status: 'disconnected',
            type: HearingParticipantType.SECONDARY,
            isLoading: false,
          })
        );
      }
      return data.PhoneNumber;
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'replaceN11Number',
        })
      );
    }
  };

  const clearCallErrorIfExists = () => {
    if (callError) {
      dispatch(clearCallError());
    }
  };

  const createNewVoiceSession = async (phoneNumber: string) => {
    let hearingPhoneNumber = phoneNumber;
    try {
      dispatch(setVoiceSessionLoading(true));
      if (isVrs && IsN11Number(phoneNumber)) {
        hearingPhoneNumber = await replaceN11Number(phoneNumber);
        if (phoneNumber === hearingPhoneNumber) {
          return;
        }
      }
      clearCallErrorIfExists();
      dispatch(setVoiceSessionStatus(VoiceSessionStatus.CONNECTING));
      ParticipantsStatusEventBus.hearing.$connecting.next(hearingPhoneNumber);

      await sendVoiceSessionCreatingStartedNotification({
        hearingPhoneNumber,
      });

      await startNewSession({
        agentUserName,
        callId,
        deafPhoneNumber: deafPhoneNumberByMode,
        hearingPhoneNumber: addPlusToPhoneNumber(
          parsePhoneNumber(hearingPhoneNumber)
        ),
      });
    } catch (error: any) {
      dispatch(resetVoiceSession());
      ParticipantsStatusEventBus.hearing.$disconnected.next({
        phoneNumber: hearingPhoneNumber,
      });
      await sendVoiceSessionCreatingFailedNotification({
        hearingPhoneNumber,
      });
      if (error?.message?.includes('Invalid dialString')) {
        dispatch(
          handleError({
            error,
            methodName: 'createNewVoiceSession',
            title: ct('invalidDialString'),
          })
        );
      } else {
        dispatch(
          handleError({
            error,
            methodName: 'createNewVoiceSession',
          })
        );
      }
    }
  };

  const connectUserToVoiceSession = async (
    voiceSessionId: string,
    audioElement: HTMLAudioElement | null
  ) => {
    try {
      dispatch(setVoiceSessionLoading(true));
      dispatch(setVoiceSessionStatus(VoiceSessionStatus.CONNECTING));
      if (!isTeaming) {
        ParticipantsStatusEventBus.hearing.$connecting.next(
          primaryHearingPhoneNumber
        );
      }

      await connectUserToExistingSession(
        voiceSessionId,
        agentNumber,
        audioElement
      );

      const voiceMeetingServiceInstance = VoiceMeetingService.getInstance();

      dispatch(
        setVoiceSession({
          sessionId: voiceMeetingServiceInstance.getSessionId(),
          status: VoiceSessionStatus.ACTIVE,
          isLoading: false,
        })
      );

      addHearingParticipantsToStore(voiceMeetingServiceInstance.getHearings());

      await setupListeners();
      voiceMeetingServiceInstance.onAudioVideoDidStart(() => {
        dispatch(hearingAudioReady());
      });
    } catch (error) {
      dispatch(resetVoiceSession());
      ParticipantsStatusEventBus.hearing.$disconnected.next({
        phoneNumber: primaryHearingPhoneNumber,
      });
      dispatch(
        handleError({
          error,
          methodName: 'connectUserToVoiceSession',
        })
      );
    }
  };

  return {
    createNewVoiceSession,
    connectUserToVoiceSession,
    replaceN11Number,
    clearCallErrorIfExists,
  };
};
