import type { Theme } from '@mui/material';

import type { ThemeComponents } from 'features/theme/types';

export default function InputLabel(theme: Theme): Partial<ThemeComponents> {
  return {
    MuiInputLabel: {
      styleOverrides: {
        root: theme.unstable_sx({
          typography: 'label',
          color: theme.palette.grey[100],
          '&.Mui-error': {
            color: theme.palette.grey[500],
          },
          '&.Mui-disabled': {
            color: theme.palette.grey[400],
          },
        }),
      },
    },
  };
}
