import styled from '@emotion/styled';

export const CallerStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 16px;
  background-color: #b1b1b1;
  border-bottom: 8px solid #3dcdc2;
  height: 100px;
`;

export const RedParagraph = styled.p`
  color: crimson;
  font-size: 14px;
  font-weight: bold;
  padding: 0;
  margin: 0;
`;

export const BlueParagraph = styled.p`
  color: navy;
  font-size: 14px;
  font-weight: bold;
  padding: 0;
  margin: 0;
`;

export const GreenParagraph = styled.p`
  color: #01796f;
  font-size: 14px;
  font-weight: bold;
  padding: 0;
  margin: 0;
`;
