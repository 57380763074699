import type { FC, PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';

import { useAppDispatch } from 'features/app/hooks';
import type { EnvironmentVariablesConfig } from 'features/environment/interfaces';
import {
  EnvironmentDaoService,
  EnvironmentService,
} from 'features/environment/services';
import { handleError } from 'features/notification/store';
import { sipSettings } from 'features/sip/config';

export const EnvironmentConfig: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const [isConfigLoading, setIsConfigLoading] = useState(true);

  const updateSipConfig = (config: EnvironmentVariablesConfig) => {
    const sipConfig = sipSettings.get();
    const turnServer = config.sip.turnServer;
    const stunServer = config.sip.stunServer;
    const stunServerAlt = config.sip.stunServerAlt;
    const stunServers = [stunServer, stunServerAlt].filter((server) => server);

    const iceServers = [
      {
        urls: [turnServer],
        credential: config.sip.iceServerCredential,
        username: config.sip.iceServerUsername,
      },
      {
        urls: stunServers,
      },
    ].filter((server) => server.urls.length > 0);

    sipSettings.update({
      domain: config.sip.domain,
      socket: {
        ...sipConfig.socket,
        uri: config.sip.domain,
      },
      pcConfig: {
        ...sipConfig.pcConfig,
        iceServers: iceServers,
      },
    });
  };

  const setEnvironmentConfig = async () => {
    try {
      const environmentConfig =
        await EnvironmentDaoService.getEnvironmentConfig();

      if (environmentConfig) {
        EnvironmentService.setConfig(environmentConfig);
        updateSipConfig(environmentConfig);
      }
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'setEnvironmentConfig',
        })
      );
    } finally {
      setIsConfigLoading(false);
    }
  };

  useEffect(() => {
    setEnvironmentConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isConfigLoading) {
    return <></>;
  }

  return <>{children}</>;
};
