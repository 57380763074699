import type { Theme } from '@mui/material';

import type { ThemeComponents } from 'features/theme/types';

export default function LinearProgress(theme: Theme): Partial<ThemeComponents> {
  return {
    MuiLinearProgress: {
      styleOverrides: {
        indeterminate: theme.unstable_sx({
          backgroundColor: theme.palette.grey[600],
        }),
        bar: theme.unstable_sx({
          maxWidth: 76,
          backgroundColor: theme.palette.primary[500],
        }),
        bar1Indeterminate: {
          width: 'auto',
          // TODO: validate animation with Doug
          animation: '1.5s indeterminate1 0s linear forward',
          '@keyframes indeterminate1': {
            '0%': {
              left: '0%',
              right: '100%',
            },
            '100%': {
              left: '0%',
              right: '0%',
            },
          },
        },
        bar2Indeterminate: {
          display: 'none',
        },
      },
    },
  };
}
