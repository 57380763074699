import {
  addPlusToPhoneNumber,
  parsePhoneNumber,
} from 'features/call/call-base/helpers';
import type {
  SessionAgentParticipant,
  SessionHearingParticipant,
  SessionMonitoringParticipant,
} from 'features/voice-session/interfaces';

export const createAgentParticipant = (
  agentId: string
): Partial<SessionAgentParticipant> => ({
  type: 'agent',
  agentId,
});

export const createHearingParticipant = (
  hearingNumber: string,
  deafPhoneNumber: string
): Partial<SessionHearingParticipant> => ({
  type: 'hearing',
  direction: 'outbound',
  fromPhoneNumber: addPlusToPhoneNumber(parsePhoneNumber(deafPhoneNumber)),
  dialString: hearingNumber,
});

export const createMonitoringParticipant = (
  adminContact: string
): Partial<SessionMonitoringParticipant> => ({
  type: 'monitor',
  fromPhoneNumber: '+13854466691',
  dialString: addPlusToPhoneNumber(parsePhoneNumber(adminContact)),
});
