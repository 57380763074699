import type { HubConnection } from '@microsoft/signalr';
import {
  HttpTransportType,
  HubConnectionBuilder,
  HubConnectionState,
} from '@microsoft/signalr';

import { modeHeaderKey } from 'features/multi-mode/constants';
import type { Mode } from 'features/multi-mode/enums';
import type { ClientNotificationEvent } from 'features/rns/interfaces';
import { RnsEventBus } from 'features/rns/services';

interface RnsConnectionParams {
  host: string;
  token: string;
  mode: Mode;
}

interface ClientNotificationParams extends ClientNotificationEvent {
  receiverId: string;
}

export class RnsConnectionService {
  private static instance: RnsConnectionService;
  private readonly connection!: HubConnection;

  private constructor({ host, mode, token }: RnsConnectionParams) {
    this.connection = new HubConnectionBuilder()
      .withUrl(`${host}/?${modeHeaderKey}=${mode}`, {
        transport: HttpTransportType.WebSockets,
        headers: {
          Authorization: token,
          [modeHeaderKey]: mode,
        },
      })
      .withAutomaticReconnect()
      .build();
  }

  public static getInstance(
    params?: RnsConnectionParams
  ): RnsConnectionService | null {
    if (RnsConnectionService.instance) {
      return RnsConnectionService.instance;
    }

    if (!RnsConnectionService.instance && params) {
      RnsConnectionService.instance = new RnsConnectionService(params);
      return RnsConnectionService.instance;
    }

    console.warn('There is no RNS instance and parameters were not provided');
    return null;
  }

  public getConnection(): HubConnection {
    return this.connection;
  }

  public async start() {
    if (this.connection.state === HubConnectionState.Disconnected) {
      await this.connection.start();
      await this.connection.send('GroupJoin', 'AgentStations');

      RnsEventBus.$connected.next(this.connection);

      this.connection.onreconnecting((error) => {
        RnsEventBus.$reconnecting.next(error);
      });

      this.connection.onclose((error?: Error) => {
        RnsEventBus.$close.next(error);
      });

      this.connection.onreconnected((connectionId) => {
        RnsEventBus.$reconnected.next(connectionId);
      });
    }
  }

  public async sendClientNotification({
    eventName,
    receiverId,
    data,
  }: ClientNotificationParams) {
    await this.connection.send(
      'NotifyClient',
      receiverId,
      eventName,
      JSON.stringify(data ?? {})
    );
  }
}
