import { useEffect } from 'react';

import { sendAnalyticsInfo } from 'features/analytics/helpers';
import { useAppDispatch } from 'features/app/hooks';

export const useBackButtonListener = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // TODO: remove this once we do CallerSession and handle it then ARC-2112
    const handlePopState = async () => {
      await dispatch(
        sendAnalyticsInfo({
          Method: 'handleBackPress',
          Message: 'user pressed the back button and we are reloading the page',
        })
      );
      window.location.reload();
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [dispatch]);

  return null;
};
