import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';
import type { VriChatParticipant } from 'features/vri-chat/enums';
import { VriChatMessageStatus } from 'features/vri-chat/enums';
import { normalizeChatMessages } from 'features/vri-chat/helpers';
import type {
  ChatMessage,
  ChatMessageNormalized,
} from 'features/vri-chat/interfaces';

export interface VriChatState {
  isEnabled: boolean;
  isShowPopup: boolean;
  isLoading: boolean;
  lastMessageFrom?: VriChatParticipant;
  turns: number;
  messages: ChatMessageNormalized;
  reason: string;
}

export const initialVriChatState: VriChatState = {
  isEnabled: false,
  isShowPopup: false,
  isLoading: false,
  turns: 0,
  messages: {},
  reason: '',
};

const vriChatSlice = createSlice({
  name: 'vri-chat',
  initialState: initialVriChatState,
  reducers: {
    enableVriChat: (state) => {
      state.isEnabled = true;
    },
    toggleVriChatPopup: (state) => {
      state.isShowPopup = !state.isShowPopup;
    },
    setVriChatLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setVriChatMessages: (state, action: PayloadAction<ChatMessage[]>) => {
      state.messages = normalizeChatMessages(action.payload);
    },
    setVriChatLastMessageFrom: (
      state,
      action: PayloadAction<VriChatParticipant>
    ) => {
      state.lastMessageFrom = action.payload;
    },
    setVriChatTurns: (state, action: PayloadAction<number>) => {
      state.turns = action.payload;
    },
    appendVriChatMessages: (state, action: PayloadAction<ChatMessage[]>) => {
      Object.entries(normalizeChatMessages(action.payload)).forEach(
        ([key, value]) => {
          state.messages[key] = value;
        }
      );
    },
    updateVriChatMessage: (state, action: PayloadAction<ChatMessage>) => {
      state.messages[action.payload.requestId] = action.payload;
    },
    updateDeliveredMessagesAsRead: (state) => {
      Object.entries(state.messages).forEach(([key]) => {
        if (state.messages[key].status === VriChatMessageStatus.DELIVERED) {
          state.messages[key].status = VriChatMessageStatus.READ;
        }
      });
    },
    setVriChatReason: (state, action: PayloadAction<string>) => {
      state.reason = action.payload;
    },
    resetVriChat: () => initialVriChatState,
  },
});

export const {
  enableVriChat,
  toggleVriChatPopup,
  setVriChatMessages,
  setVriChatLoading,
  setVriChatTurns,
  setVriChatLastMessageFrom,
  appendVriChatMessages,
  updateDeliveredMessagesAsRead,
  updateVriChatMessage,
  resetVriChat,
  setVriChatReason,
} = vriChatSlice.actions;

export const vriChatReducer = vriChatSlice.reducer;

export const isVriChatEnabledSelector = ({ vriChat }: RootState) =>
  vriChat.isEnabled;

export const isVriChatShowPopupSelector = ({ vriChat }: RootState) =>
  vriChat.isShowPopup;

export const isVriChatLoadingSelector = ({ vriChat }: RootState) =>
  vriChat.isLoading;

export const vriChatLastMessageFromSelector = ({ vriChat }: RootState) =>
  vriChat.lastMessageFrom;

export const vriChatMessagesSelector = ({ vriChat }: RootState) =>
  vriChat.messages;

export const vriChatReasonSelector = ({ vriChat }: RootState) => vriChat.reason;

export const vriChatTurnsSelector = ({ vriChat }: RootState) => vriChat.turns;
