import { Typography } from '@mui/material';
import type { FC, PropsWithChildren, ReactNode } from 'react';

import type { CallDetailsParticipantMetadataProps } from 'features/call/call-details/components';
import { CallDetailsParticipantMetadata } from 'features/call/call-details/components';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';

export interface CallDetailsParticipantProps
  extends PropsWithChildren<CallDetailsParticipantMetadataProps> {
  title: string;
  icon: ReactNode;
  testId?: string;
}

const Container = styled('div')`
  display: flex;
  flex-direction: column;
`;

const ParticipantWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(8)};
  margin-bottom: ${theme.spacing(8)};
`;

export const CallDetailsParticipant: FC<CallDetailsParticipantProps> = ({
  name,
  phoneNumber,
  phoneExtension,
  location,
  title,
  children,
  icon,
  testId,
  isWavelloRegistered = false,
}) => {
  return (
    <Container data-testid={testId}>
      <Typography variant='label' color={theme.palette.grey[300]} mb={4}>
        {title}
      </Typography>
      <ParticipantWrapper>
        {icon}
        <CallDetailsParticipantMetadata
          name={name}
          location={location}
          phoneNumber={phoneNumber}
          phoneExtension={phoneExtension}
          isWavelloRegistered={isWavelloRegistered}
        />
      </ParticipantWrapper>
      {children}
    </Container>
  );
};
