import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';
import type {
  LanguagePriority,
  QueueSettingsState,
} from 'features/queue-settings/interfaces';

export const initialQueueSettingsState: QueueSettingsState = {
  languages: [],
};

export const queueSettingsSlice = createSlice({
  name: 'queue-settings',
  initialState: initialQueueSettingsState,
  reducers: {
    setLanguages: (state, action: PayloadAction<LanguagePriority[]>) => {
      state.languages = action.payload;
    },
  },
});

export const { setLanguages } = queueSettingsSlice.actions;

export const languagesSelector = ({ queueSettings }: RootState) =>
  queueSettings.languages;

export const isNoLanguageSelectedSelector = ({ queueSettings }: RootState) =>
  queueSettings.languages.every((language) => !language.isSelected);

export const queueSettingsReducer = queueSettingsSlice.reducer;
