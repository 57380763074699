// TODO: move to enums(files in scope of feature should be grouped by type) + this enum difficult to find due to file name
export enum PageRoute {
  MAIN = '/',
  VRI_CALL = '/vri/call',
  VRS_CALL = '/vrs/call',
  VRI_CONFERENCE = '/vri/conference',
  VRS_CONFERENCE = '/vrs/conference',
  ZOOM = '/zoom',
  SOMETHING_WENT_WRONG = '/something-went-wrong',
  ACCESS_DENIED = '/access-denied',
}
