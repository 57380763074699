import { Typography } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'features/app/hooks';
import {
  customGreetingSelector,
  isCustomGreetingPresentSelector,
} from 'features/custom-greeting/store';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';

export const CustomGreeting: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'customGreeting',
  });
  const customGreeting = useAppSelector(customGreetingSelector);
  const isCustomGreetingPresent = useAppSelector(
    isCustomGreetingPresentSelector
  );

  return (
    <>
      {isCustomGreetingPresent && (
        <CustomGreetingContainer>
          <Typography variant='subtitle2' color={theme.palette.grey[800]}>
            {t('title')}
          </Typography>
          <Typography
            variant='textMediumRegular'
            color={theme.palette.grey[900]}
          >
            {customGreeting}
          </Typography>
        </CustomGreetingContainer>
      )}
    </>
  );
};

const CustomGreetingContainer = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 340px;
  margin-bottom: ${theme.spacing(8)};
  padding: ${theme.spacing(10)};
  gap: ${theme.spacing(4)};
  background-color: ${theme.palette.primary[100]};
  border-radius: 8px;
`;
