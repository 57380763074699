export enum TerminationType {
  Unknown,
  Abandon,
  Busy,
  NoAnswer,
  Normal,
  Cancel,
  SignMail,
  Test,
  StvCancl,
  NonBill,
  RtqCancl,
  NotReg,
  ClNtPlcd,
  DlRelay,
  AnswerTO,
  CnctFail,
  CustCncl,
  NormVRI,
  NormVRS,
  NonSL,
  External,
}
