import type { Theme } from '@mui/material';

import type { ThemeComponents } from 'features/theme/types';

export default function CircularProgress(
  theme: Theme
): Partial<ThemeComponents> {
  return {
    MuiCircularProgress: {
      styleOverrides: {
        circle: theme.unstable_sx({
          color: theme.palette.grey[100],
        }),
      },
    },
  };
}
