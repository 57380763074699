import type { Theme } from '@mui/material';

import type { ThemeComponents } from 'features/theme/types';

export default function Menu(theme: Theme): Partial<ThemeComponents> {
  return {
    MuiMenu: {
      styleOverrides: {
        paper: theme.unstable_sx({
          borderRadius: 0,
        }),
        list: theme.unstable_sx({
          backgroundColor: theme.palette.grey[700],
          p: 0,
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: theme.unstable_sx({
          color: theme.palette.common.white,
          p: theme.spacing(6, 8),
          typography: 'link2',
        }),
      },
    },
  };
}
