import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';
import type { LocalVideoState } from 'features/local-video/interfaces';

export const initialLocalVideoState: LocalVideoState = {
  isLocalVideo: true,
};

const localVideoSlice = createSlice({
  name: 'local-video',
  initialState: initialLocalVideoState,
  reducers: {
    toggleLocalVideo: (state) => {
      state.isLocalVideo = !state.isLocalVideo;
    },
    showLocalVideo: (state) => {
      state.isLocalVideo = true;
    },
    hideLocalVideo: (state) => {
      state.isLocalVideo = false;
    },
    resetLocalVideo: () => initialLocalVideoState,
  },
});

export const {
  toggleLocalVideo,
  showLocalVideo,
  hideLocalVideo,
  resetLocalVideo,
} = localVideoSlice.actions;

export const isLocalVideoEnabledSelector = ({ localVideo }: RootState) =>
  localVideo.isLocalVideo;

export const localVideoReducer = localVideoSlice.reducer;
