import type { RootState } from 'features/app/store/store';

export const isPreviousCallIdExistSelector = ({ vrsCallComments }: RootState) =>
  Boolean(vrsCallComments.previousCallId);

export const previousCallIdSelector = ({ vrsCallComments }: RootState) =>
  vrsCallComments.previousCallId;

export const isReportNeededSelector = ({ vrsCallComments }: RootState) =>
  vrsCallComments.isReportNeeded;

export const reportTypeSelector = ({ vrsCallComments }: RootState) =>
  vrsCallComments.reportType;
