import type { FC, PropsWithChildren } from 'react';
import * as ReactOidcContext from 'react-oidc-context';

import { envConfig } from 'features/environment/services';
import { useMachinePreferences } from 'features/machine-preferences/hooks';
import type { Mode } from 'features/multi-mode/enums';

interface AuthProviderProps extends PropsWithChildren {
  mode: Mode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ mode, children }) => {
  useMachinePreferences();

  const modeLowerCase = mode.toLowerCase() as Lowercase<Mode>;
  const { userPoolId, clientId } = envConfig[modeLowerCase].cognito;
  const providerProps: ReactOidcContext.AuthProviderProps = {
    onSigninCallback: () => {
      window.history.replaceState({}, document.title, window.location.pathname);
    },
    response_type: 'code',
    redirect_uri: envConfig.publicUrl,
    scope: 'openid profile email',
    loadUserInfo: true,
    authority: `https://cognito-idp.${envConfig.awsRegion}.amazonaws.com/${userPoolId}`,
    client_id: clientId,
  };

  return (
    <ReactOidcContext.AuthProvider {...providerProps}>
      {children}
    </ReactOidcContext.AuthProvider>
  );
};
