import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';

interface AppLoadingState {
  isLoading: boolean;
}

export const initialState: AppLoadingState = {
  isLoading: true,
};

const appLoadingSlice = createSlice({
  name: 'app-loading',
  initialState: initialState,
  reducers: {
    finishAppLoading: (state) => {
      state.isLoading = false;
    },
  },
});

export const { finishAppLoading } = appLoadingSlice.actions;
export const appLoadingReducer = appLoadingSlice.reducer;

export const isAppLoadingSelector = ({ appLoading }: RootState) =>
  appLoading.isLoading;
