import { sendAnalyticsInfo } from 'features/analytics/helpers';
import { LeaveQueueLogMessages } from 'features/leave-queue/enums';

export const leaveQueueAnalytics = (methodName: string, logoutType: string) => {
  return sendAnalyticsInfo({
    Method: methodName,
    LogoutType: logoutType,
    Message: LeaveQueueLogMessages.FINISH_TAKING_CALLS_CLICK,
  });
};
