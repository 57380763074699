import { httpClient } from 'common/services';
import type { Call } from 'features/call/call-base/interfaces';
import type {
  CancelColdHandoffRequest,
  CompleteColdHandoffRequest,
  RequestColdHandoffRequest,
  RestoreColdHandoffRequest,
  TransferColdHandoffRequest,
} from 'features/cold-handoff/interfaces';

async function requestHandoff({ callId, ...rest }: RequestColdHandoffRequest) {
  const route = `/call/${callId}/handoff`;
  const response = await httpClient.post<Call>(route, rest);
  return response.data;
}

async function cancelHandoff({ callId }: CancelColdHandoffRequest) {
  const route = `/call/${callId}/handoff/cancel`;
  const response = await httpClient.patch<Call>(route);
  return response.data;
}

async function restoreHandoff({ callId }: RestoreColdHandoffRequest) {
  const route = `/call/${callId}/handoff/restore`;
  const response = await httpClient.patch<Call>(route);
  return response.data;
}

async function transferHandoff({
  callId,
  ...rest
}: TransferColdHandoffRequest) {
  const route = `/call/${callId}/handoff/transfer`;
  const response = await httpClient.put<Call>(route, rest);
  return response.data;
}

async function completeHandoff({ callId }: CompleteColdHandoffRequest) {
  const route = `/call/${callId}/handoff/complete`;
  const response = await httpClient.put<Call>(route);
  return response.data;
}

export const ColdHandoffDaoService = {
  requestHandoff,
  cancelHandoff,
  restoreHandoff,
  transferHandoff,
  completeHandoff,
};
