import {
  MachinePreferencesLocalStorageKey,
  MachinePreferencesUrlParam,
} from 'features/machine-preferences/enum';

export const getMachinePreferencesFromUrl = () => {
  const machineId = new URLSearchParams(window.location.search).get(
    MachinePreferencesUrlParam.MACHINE_ID
  );
  const callCenterCode = new URLSearchParams(window.location.search).get(
    MachinePreferencesUrlParam.CALL_CENTER_CODE
  );
  const stationNumber = new URLSearchParams(window.location.search).get(
    MachinePreferencesUrlParam.STATION_NUMBER
  );

  return { machineId, callCenterCode, stationNumber };
};

export const setMachinePreferencesInLocalStorage = ({
  machineId,
  callCenterCode,
  stationNumber,
}: ReturnType<typeof getMachinePreferencesFromUrl>) => {
  if (machineId) {
    localStorage.setItem(
      MachinePreferencesLocalStorageKey.MACHINE_ID,
      machineId
    );
  }

  if (callCenterCode) {
    localStorage.setItem(
      MachinePreferencesLocalStorageKey.CALL_CENTER_CODE,
      callCenterCode
    );
  }

  if (stationNumber) {
    localStorage.setItem(
      MachinePreferencesLocalStorageKey.STATION_NUMBER,
      stationNumber
    );
  }
};

export const getMachinePreferencesFromLocalStorage = () => {
  const machineId = localStorage.getItem(
    MachinePreferencesLocalStorageKey.MACHINE_ID
  );
  const callCenterCode = localStorage.getItem(
    MachinePreferencesLocalStorageKey.CALL_CENTER_CODE
  );
  const stationNumber = localStorage.getItem(
    MachinePreferencesLocalStorageKey.STATION_NUMBER
  );

  return {
    machineId,
    callCenterCode,
    stationNumber,
  };
};
