import type {
  CallDirection,
  DisconnectReason,
} from 'features/voice-session/interfaces';

export type HearingParticipantStatus =
  | 'connecting'
  | 'connected'
  | 'disconnected';

export enum HearingParticipantType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  VCO2LINE = 'VCO2LINE',
}

export enum HearingParticipantPhoneNumberType {
  ANONYMOUS = 'anonymous',
}

export interface HearingParticipant {
  id: string;
  name: string;
  phoneNumber: string;
  location: string;
  status: HearingParticipantStatus;
  type: HearingParticipantType;
  direction?: CallDirection;
  isWavelloRegistered?: boolean;
  isWavelloCallActive?: boolean;
  phoneExtension: string;
  isLoading: boolean;
  pendingDisconnect?: boolean;
}

export type HearingPhone = HearingParticipant['phoneNumber'];

export interface HearingPhoneWithId {
  id: string;
  phoneNumber: HearingPhone;
}

export interface HearingPhoneWithDisconnectReason {
  phoneNumber: string;
  disconnectReason?: DisconnectReason;
  id?: string;
}
