import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';
import type { VideoPrivacyState } from 'features/video-privacy/interfaces';

export const initialVideoPrivacyState: VideoPrivacyState = {
  local: {
    isEnabled: false,
    isLoading: false,
  },
  remote: {
    isEnabled: false,
  },
};

const videoPrivacySlice = createSlice({
  name: 'video-privacy',
  initialState: initialVideoPrivacyState,
  reducers: {
    enableLocalVideoPrivacy: (state) => {
      state.local.isEnabled = true;
    },
    disableLocalVideoPrivacy: (state) => {
      state.local.isEnabled = false;
    },
    startLocalVideoPrivacyLoading: (state) => {
      state.local.isLoading = true;
    },
    stopLocalVideoPrivacyLoading: (state) => {
      state.local.isLoading = false;
    },
    enableRemoteVideoPrivacy: (state) => {
      state.remote.isEnabled = true;
    },
    disableRemoteVideoPrivacy: (state) => {
      state.remote.isEnabled = false;
    },
    resetVideoPrivacy: () => initialVideoPrivacyState,
  },
});

export const {
  enableLocalVideoPrivacy,
  disableLocalVideoPrivacy,
  startLocalVideoPrivacyLoading,
  stopLocalVideoPrivacyLoading,
  enableRemoteVideoPrivacy,
  disableRemoteVideoPrivacy,
  resetVideoPrivacy,
} = videoPrivacySlice.actions;

export const videoPrivacyReducer = videoPrivacySlice.reducer;

export const isLocalVideoPrivacyEnabledSelector = ({
  videoPrivacy,
}: RootState) => videoPrivacy.local.isEnabled;

export const isLocalVideoPrivacyLoadingSelector = ({
  videoPrivacy,
}: RootState) => videoPrivacy.local.isLoading;

export const isRemoteVideoPrivacyEnabledSelector = ({
  videoPrivacy,
}: RootState) => videoPrivacy.remote.isEnabled;
