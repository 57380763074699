import { sendAnalyticsInfo } from 'features/analytics/helpers';
import { VriChatLogMessage } from 'features/vri-chat/enums';

export const setVriChatAnalytics = (
  methodName: string,
  chatTurns: number,
  chatReason: string
) => {
  return sendAnalyticsInfo({
    Method: methodName,
    ChatTurns: chatTurns,
    ChatReason: chatReason,
    Message: VriChatLogMessage.TURN_UPDATED,
  });
};
