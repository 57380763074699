import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { httpClient } from 'common/services';
import { modeHeaderKey } from 'features/multi-mode/constants';
import type { Mode } from 'features/multi-mode/enums';
import { switchModeAnalytics } from 'features/multi-mode/helpers';
import type { MultiModeState } from 'features/multi-mode/interfaces';
import { ModeStorageService } from 'features/multi-mode/services';

export const initialMultiModeState: MultiModeState = {};

export const multiModeSlice = createSlice({
  name: 'multi-mode',
  initialState: initialMultiModeState,
  reducers: {
    setMode: (state, action: PayloadAction<Mode>) => {
      state.mode = action.payload;
    },
    resetMultiMode: () => initialMultiModeState,
  },
});

export const { setMode, resetMultiMode } = multiModeSlice.actions;
export const multiModeReducer = multiModeSlice.reducer;

export const switchModeTo = createAsyncThunk(
  'multi-mode/switchModeTo',
  async (mode: Mode, { dispatch }) => {
    ModeStorageService.setMode(mode);
    httpClient.setHeader(modeHeaderKey, mode);
    switchModeAnalytics('switchModeTo', mode);
    dispatch(setMode(mode));
  }
);
