import type { Theme } from '@mui/material';

import type { ThemeComponents } from 'features/theme/types';

export default function Switch(theme: Theme): Partial<ThemeComponents> {
  return {
    MuiSwitch: {
      defaultProps: {
        disableRipple: true,
        color: 'primary',
        size: 'medium',
      },
      styleOverrides: {
        root: theme.unstable_sx({
          width: 44,
          height: 24,
          marginRight: theme.spacing(4),
          padding: 0,
          borderRadius: 12,
        }),
        thumb: {
          backgroundColor: theme.palette.common.white,
        },
        switchBase: {
          top: 2,
          left: 2,
          padding: 0,
          color: theme.palette.common.white,
          '&:hover + .MuiSwitch-track': {
            backgroundColor: theme.palette.grey[50],
          },
          '&.Mui-disabled': {
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.grey[100],
            },
            '& .MuiSwitch-thumb': {
              backgroundColor: theme.palette.grey[50],
            },
          },
          '&.Mui-checked:hover + .MuiSwitch-track': {
            // TODO: should be added to theme
            backgroundColor: '#29918A',
          },
        },
        track: {
          backgroundColor: theme.palette.grey[100],
          opacity: '1!important',
        },
      },
    },
  };
}
