import { httpClient } from 'common/services';
import type {
  CreateSessionRequest,
  ReconnectSessionRequest,
  RemoveSessionRequest,
  Session,
  UpdateSessionActivityRequest,
} from 'features/session/interfaces';

async function createSession({
  rnsConnectionId,
  clientVersion,
  machinePreferences,
}: CreateSessionRequest) {
  const route = `/sessions/${rnsConnectionId}`;
  const response = await httpClient.post<Session>(route, {
    clientVersion,
    machinePreferences,
  });
  return response.data;
}

async function removeSession({
  rnsConnectionId,
  logoutType,
  keepAlive = false,
}: RemoveSessionRequest) {
  const route = `/sessions/${rnsConnectionId}/${logoutType}`;
  const response = await httpClient.delete<void>(route, { keepAlive });
  return response.data;
}

async function updateSessionActivity({
  rnsConnectionId,
  teamingState,
}: UpdateSessionActivityRequest) {
  const route = `/sessions/${rnsConnectionId}/activity`;
  const response = await httpClient.patch<void>(route, { teamingState });
  return response.data;
}

async function reconnectSession({ ...body }: ReconnectSessionRequest) {
  const route = '/sessions/reconnect';
  const response = await httpClient.post<Session>(route, body);
  return response.data;
}

async function getSessionBySessionId(sessionId: number) {
  const route = `/sessions/${sessionId}`;
  const response = await httpClient.get<Session | null>(route);
  return response.data;
}

export const SessionDaoService = {
  createSession,
  removeSession,
  updateSessionActivity,
  reconnectSession,
  getSessionBySessionId,
};
