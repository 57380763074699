import Sounds from 'assets/sounds.json';
import { soundOptionsMap } from 'features/audio-player/constants';
import type { SoundName } from 'features/audio-player/enums';
import { LoggerService } from 'features/logger/services';

export interface ISound {
  name: SoundName;
  volume?: number;
  loop?: boolean;
  /** The interval between sounds in milliseconds */
  loopInterval?: number;
}

export class Sound {
  public readonly name: SoundName;
  private _audio: HTMLAudioElement;
  private _loopInterval: number;
  private timerId?: number;

  constructor(sound: ISound) {
    this.name = sound.name;
    this._audio = new Audio(Sounds[this.name]);
    this.volume = sound.volume ?? 1;
    this._audio.loop = sound.loop ?? false;
    this._loopInterval = sound.loopInterval ?? 0;
    this._audio.load();
  }

  static create(name: SoundName) {
    return new Sound({
      name,
      ...soundOptionsMap[name],
    });
  }

  // TODO: Refactor logic with loop
  async play() {
    try {
      await this._audio.play();

      if (this._audio.loop) {
        this._audio.onended = async () => {
          if (this._loopInterval) {
            this.pauseBetweenCycles();
          } else {
            await this.play();
          }
        };
      }
    } catch (error) {
      LoggerService.error(error);
    }
  }

  private pauseBetweenCycles() {
    setTimeout(() => {
      // If it's paused at the beginning, it was stopped; don't play again
      if (this._audio.paused && this._audio.currentTime === 0) {
        return;
      }

      this.play();
    }, this._loopInterval);
  }

  playSample(duration: number = 3000) {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.stop();
    }
    this._audio.play();

    this.timerId = window.setTimeout(() => {
      this.stop();
    }, duration);
  }

  stop() {
    this._audio.pause();
    this._audio.currentTime = 0;
  }

  set volume(volume: number) {
    this._audio.volume = volume;
  }
}
