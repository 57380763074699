import { createAsyncThunk } from '@reduxjs/toolkit';
import { t } from 'i18next';

import { setInnerLoading } from 'features/app/store/app/appSlice';
import { LogoutType } from 'features/call/call-base/enums';
import { leaveQueueAnalytics } from 'features/leave-queue/helpers';
import { handleError, showNotification } from 'features/notification/store';
import { finishSession } from 'features/session/store';
import { stoppedTakingCalls } from 'features/caller-session/store';

export const leaveQueue = createAsyncThunk(
  'leaveQueue/leaveQueue',
  async (payload, { dispatch }) => {
    dispatch(setInnerLoading(true));
    dispatch(stoppedTakingCalls());

    const logoutType = LogoutType.Normal;

    try {
      dispatch(leaveQueueAnalytics('leaveQueue', LogoutType[logoutType]));

      await dispatch(finishSession({ logoutType })).unwrap();
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'leaveQueue',
        })
      );
    } finally {
      dispatch(setInnerLoading(false));
      window.location.reload();
    }
  }
);

export const leaveQueueBecauseOfRegistrationFailed = createAsyncThunk(
  'leaveQueue/leaveQueueBecauseOfRegistrationFailed',
  async (_, { dispatch }) => {
    dispatch(setInnerLoading(true));

    const logoutType = LogoutType.ConnectionLoss;
    dispatch(stoppedTakingCalls());

    try {
      dispatch(
        leaveQueueAnalytics(
          'leaveQueueBecauseOfRegistrationFailed',
          LogoutType[logoutType]
        )
      );

      dispatch(
        showNotification({
          severity: 'error',
          title: t('somethingWentWrong'),
          message: t('registrationFailed'),
        })
      );

      await dispatch(finishSession({ logoutType })).unwrap();
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'leaveQueueBecauseOfRegistrationFailed',
        })
      );
    } finally {
      dispatch(setInnerLoading(false));
    }
  }
);
