import type { Theme } from '@mui/material';

import type { ThemeComponents } from 'features/theme/types';

export default function Dialog(theme: Theme): Partial<ThemeComponents> {
  return {
    MuiDialog: {
      styleOverrides: {
        paper: theme.unstable_sx({
          borderRadius: 4,
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: theme.unstable_sx({
          padding: theme.spacing(0, 12, 12),
        }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: theme.unstable_sx({
          padding: theme.spacing(12),
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: theme.unstable_sx({
          paddingBottom: theme.spacing(4),
        }),
      },
    },
  };
}
