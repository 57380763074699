import { useEffect, useState } from 'react';

import { useAppDispatch } from 'features/app/hooks';
import { useLocalStorage } from 'features/media-devices/hooks';
import type {
  MediaDevice,
  MediaDeviceGroup,
} from 'features/media-devices/interfaces';
import {
  setCamera,
  setMicrophone,
  setSpeaker,
} from 'features/media-devices/store';
import { handleError } from 'features/notification/store';
import type { SelectItem } from 'features/ui/elements';

interface MediaDeviceListGroup {
  microphoneList: SelectItem[];
  cameraList: SelectItem[];
  speakerList: SelectItem[];
}

export const useMediaDevices = () => {
  const dispatch = useAppDispatch();
  const [storageDevices, setStorageDevices] = useLocalStorage<MediaDeviceGroup>(
    'userDevices',
    {} as MediaDeviceGroup
  );
  const [cameraList, setCameraList] = useState<SelectItem[]>([]);
  const [microphoneList, setMicrophoneList] = useState<SelectItem[]>([]);
  const [speakerList, setSpeakerList] = useState<SelectItem[]>([]);

  const handleCameraChange = (camera: MediaDevice) => {
    dispatch(setCamera(camera));
    setStorageDevices((devices) => ({ ...devices, camera }));
  };
  const handleMicrophoneChange = (microphone: MediaDevice) => {
    dispatch(setMicrophone(microphone));
    setStorageDevices((devices) => ({ ...devices, microphone }));
  };
  const handleSpeakerChange = (speaker: MediaDevice) => {
    dispatch(setSpeaker(speaker));
    setStorageDevices((devices) => ({ ...devices, speaker }));
  };

  const getDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const lists = devices.reduce<MediaDeviceListGroup>(
        (acc, { kind, label, deviceId }) => {
          const item: SelectItem = {
            value: deviceId,
            text: label,
          };

          if (kind === 'audioinput') {
            acc.microphoneList.push(item);
          }
          if (kind === 'videoinput') {
            acc.cameraList.push(item);
          }
          if (kind === 'audiooutput') {
            acc.speakerList.push(item);
          }
          return acc;
        },
        {
          microphoneList: [],
          cameraList: [],
          speakerList: [],
        }
      );

      setMicrophoneList(lists.microphoneList);
      setCameraList(lists.cameraList);
      setSpeakerList(lists.speakerList);
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'getDevices',
        })
      );
    }
  };

  const isStoredDeviceExists = (
    storedDeviceId: string | null,
    list: SelectItem[]
  ) => {
    if (!storedDeviceId) {
      return false;
    }

    return list.some(({ value }) => value === storedDeviceId);
  };

  const getSelectedDevice = (
    storedDeviceId: string | null,
    list: SelectItem[]
  ) => {
    const [firstDevice] = list;

    return isStoredDeviceExists(storedDeviceId, list)
      ? storedDeviceId
      : firstDevice?.value;
  };

  useEffect(() => {
    const camera = getSelectedDevice(storageDevices.camera, cameraList);
    dispatch(setCamera(camera));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraList]);

  useEffect(() => {
    const microphone = getSelectedDevice(
      storageDevices.microphone,
      microphoneList
    );
    dispatch(setMicrophone(microphone));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [microphoneList]);

  useEffect(() => {
    const speaker = getSelectedDevice(storageDevices.speaker, speakerList);
    dispatch(setSpeaker(speaker));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [speakerList]);

  return {
    getDevices,
    cameraList,
    speakerList,
    microphoneList,
    handleCameraChange,
    handleSpeakerChange,
    handleMicrophoneChange,
  };
};
