import type { GroupChannel } from '@sendbird/chat/groupChannel';
import { Subject } from 'rxjs';

export class VriChatEventBus {
  static $createGroupChannel = new Subject<void>();
  static $sendMessage = new Subject<string>();
  static $startTypingMessage = new Subject<void>();
  static $stopTypingMessage = new Subject<void>();
  static $createMessageCollection = new Subject<GroupChannel>();
  static $updateMessagesAsRead = new Subject<void>();
  static $setTypingStatus = new Subject<boolean>();
  static $appendIncomingMessage = new Subject<GroupChannel>();
  static $leaveChat = new Subject<void>();
  static $getMessagesHistory = new Subject<void>();
}
