import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type {
  Notification,
  NotificationOptions,
} from 'features/notification/interfaces';

const initialState: Notification = {
  isOpen: false,
  severity: 'success',
  title: '',
  message: '',
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    showNotification: (
      state: Notification,
      action: PayloadAction<NotificationOptions>
    ) => ({
      ...action.payload,
      isOpen: true,
    }),
    closeNotification: (
      state: Notification,
      action: PayloadAction<NotificationOptions>
    ) => ({
      ...action.payload,
      isOpen: false,
    }),
  },
});

export const { showNotification, closeNotification } =
  notificationSlice.actions;

export const notificationReducer = notificationSlice.reducer;
