import type { AppConfig } from 'features/app-config/interfaces';
import type { EmergencyPhoneNumbers } from 'features/emergency/interfaces';

export const getEmergencyPhoneNumbers = (
  config: AppConfig
): EmergencyPhoneNumbers => {
  return {
    ECRC: config.Intrado911ConnectionString,
    PSAP: config.EmergencyExt,
  };
};
