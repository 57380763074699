import type { FC, PropsWithChildren } from 'react';

import { AppLogo } from 'features/app-logo/components';
import { AppVersion } from 'features/app-version/components';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';

export const BaseHeader: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Container>
      <Logo>
        <AppLogo />
        <AppVersion />
      </Logo>
      <Content>{children}</Content>
    </Container>
  );
};

const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing(0, 16)};
  background-color: ${theme.palette.grey[700]};
  height: 96px;
  border-bottom: 8px solid ${theme.palette.deepChampagne[500]};
`;

const Logo = styled('div')`
  display: flex;
  justify-items: center;
  width: auto;
  height: 44px;
`;

const Content = styled('div')`
  display: flex;
  align-items: center;
`;
