import { httpClient } from 'common/services';
import type {
  AppConfig,
  CallCenterAtHomeResponse,
} from 'features/app-config/interfaces';

async function getAppConfig() {
  const route = '/config';
  const response = await httpClient.get<AppConfig>(route);
  return response.data;
}

async function isCallCenterAtHome(callCenterCode: string) {
  const route = `/config/at-home/${callCenterCode}`;
  const response = await httpClient.get<CallCenterAtHomeResponse>(route);
  return response.data;
}
export const AppConfigDaoService = {
  getAppConfig,
  isCallCenterAtHome,
};
