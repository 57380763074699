import {
  parsePhoneNumber,
  parsePhoneNumberWithRemovePlus,
} from 'features/call/call-base/helpers';
import type {
  CallHearingState,
  HearingParticipant,
  HearingPhone,
} from 'features/call/call-hearing/interfaces';

export interface HearingSelector {
  phoneNumber: HearingPhone;
}

export const findHearingByPhoneNumber = (
  state: CallHearingState,
  selector: HearingSelector
): HearingParticipant => {
  const normalizedPhoneNumber = parsePhoneNumber(selector.phoneNumber);
  const phoneNumberWithoutPlus = parsePhoneNumberWithRemovePlus(
    selector.phoneNumber
  );

  const hearing = state.hearings.find(
    ({ phoneNumber }: HearingParticipant) =>
      phoneNumber === normalizedPhoneNumber ||
      parsePhoneNumberWithRemovePlus(phoneNumber) === phoneNumberWithoutPlus
  );

  if (!hearing) {
    throw new Error(`Hearing (${selector.phoneNumber}) not found`);
  }
  return hearing;
};

export const findPrimaryHearing = (
  state: CallHearingState
): HearingParticipant =>
  state.hearings.find(
    ({ type }: HearingParticipant) => type === 'PRIMARY'
  ) as HearingParticipant;

export const findVCO2Line = (state: CallHearingState): HearingParticipant =>
  state.hearings.find(
    ({ type }: HearingParticipant) => type === 'VCO2LINE'
  ) as HearingParticipant;

export const findHearingById = (
  state: CallHearingState,
  hearingId: string
): HearingParticipant => {
  const hearing = state.hearings.find(
    ({ id }: HearingParticipant) => id === hearingId
  );

  if (!hearing) {
    throw new Error(`Hearing (${hearingId}) not found`);
  }
  return hearing;
};
