import flow from 'lodash.flow';

import { LogLevel } from 'features/analytics/enums';
import type { SendAnalyticsRequest } from 'features/analytics/interfaces';
import { sendAnalytics } from 'features/analytics/store';

type SendAnalytics = Omit<SendAnalyticsRequest, 'Level'>;

export const sendAnalyticsInfo = (analytics: SendAnalytics) => {
  return sendAnalytics({
    ...analytics,
    Level: LogLevel.INFO,
  } as SendAnalyticsRequest);
};

export const sendAnalyticsError = (analytics: SendAnalytics) => {
  return sendAnalytics({
    ...analytics,
    Level: LogLevel.ERROR,
  } as SendAnalyticsRequest);
};

export const changeKeysOfObject = (obj: any) => {
  const newObj: { [key: string]: any } = {};
  for (const key in obj) {
    if (key === 'method' || key === 'methodName') {
      console.warn(
        'Keys `method` and `methodName` are deprecated, please use `Method` instead'
      );
      newObj['Method'] = obj[key];
    } else {
      newObj[key] = obj[key];
    }
  }
  return newObj;
};

export const capitalizeKeys = (obj: any) => {
  const newObj: { [key: string]: any } = {};
  for (const key in obj) {
    if (key[0] === key[0].toLowerCase()) {
      newObj[key[0].toUpperCase() + key.slice(1)] = obj[key];
    } else {
      newObj[key] = obj[key];
    }
  }
  return newObj;
};

export const normalizeAnalyticsKeys = flow(
  changeKeysOfObject,
  capitalizeKeys
) as (obj: any) => SendAnalyticsRequest;
