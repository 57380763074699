import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { MonitorState } from 'features/monitoring/interfaces/monitorState';

const initialState: MonitorState = {
  hostIpAddress: '',
  adminContact: '',
};

export const monitorSlice = createSlice({
  name: 'monitor',
  initialState,
  reducers: {
    setHostIpAddress: (state, action: PayloadAction<string>) => {
      state.hostIpAddress = action.payload;
    },
    setAdminContact: (state, action: PayloadAction<string>) => {
      state.adminContact = action.payload;
    },
    resetMonitor: () => initialState,
  },
});

export const { setHostIpAddress, setAdminContact, resetMonitor } =
  monitorSlice.actions;

export default monitorSlice.reducer;
