import type { Theme } from '@mui/material';
import { alpha } from '@mui/material';

import type { ThemeComponents } from 'features/theme/types';

export default function Paper(theme: Theme): Partial<ThemeComponents> {
  return {
    MuiPaper: {
      styleOverrides: {
        root: theme.unstable_sx({
          borderRadius: 0,
          '&.select': {
            boxShadow: 5,
            mt: 2,
            borderRadius: 4,
            '& .MuiMenu-list': {
              backgroundColor: theme.palette.common.white,
            },
            '& .MuiMenuItem-root': {
              typography: 'textMediumRegular',
              color: theme.palette.grey[900],
              py: 5,
              justifyContent: 'space-between',
              '&.Mui-focused, &:hover': {
                backgroundColor: alpha(theme.palette.grey[50], 0.5),
              },
              '&.Mui-selected': {
                backgroundColor: theme.palette.grey[50],
              },
            },
          },
        }),
      },
    },
  };
}
