import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';
import { isDeafToHearingSelector } from 'features/call/call-base/store/selectors';
import {
  isDeafConnectingSelector,
  isDeafUnavailableSelector,
} from 'features/call/call-deaf/store';
import { GreetingPreference } from 'features/signmail/enums';

export const selectSignMailStatus = ({ signMail }: RootState) =>
  signMail.currentStatus;

export const signMailVideoUrlSelector = ({ signMail }: RootState) =>
  signMail.greeting.videoUrl;

export const signMailGreetingPreferenceSelector = ({ signMail }: RootState) =>
  signMail.greeting.greetingPreference;

export const isTextOnlyGreetingSelector = createSelector(
  signMailGreetingPreferenceSelector,
  (greetingPreference) => greetingPreference === GreetingPreference.TEXT_ONLY
);

export const signMailGreetingTextSelector = ({ signMail }: RootState) =>
  signMail.greeting.text;

export const selectSignMailRecordingStatus = ({ signMail }: RootState) =>
  signMail.recording.status;

export const signMailRecordingUploadUrlSelector = ({ signMail }: RootState) =>
  signMail.recording.uploadUrl;

export const textStartSecondsSelector = ({ signMail }: RootState) =>
  signMail.recording.textStartSeconds;

export const isShowSignMailTextFormSelector = createSelector(
  isDeafToHearingSelector,
  isDeafConnectingSelector,
  isDeafUnavailableSelector,
  (isDeafToHearing, isDeafConnecting, isDeafUnavailable) => {
    return !isDeafToHearing && (isDeafConnecting || isDeafUnavailable);
  }
);
