import {
  createAction,
  createSlice,
  nanoid,
  type PayloadAction,
} from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';
import type { RegularCall, TeamingRequest } from 'features/rns/interfaces';

//
// Types and values
//

export const NoSessionId = null;

export const inputs = {
  analyticsFunction: (_: any) => {},
};

type Status =
  | 'NotTakingCalls'
  | 'TakingCalls'
  | 'ConsideringPushedCall'
  | 'InCallerSession';

interface CallPushPayload {
  caller: string;
  relayCallId: string;
  isDeafToHearing: boolean;
}

//
// Slice definition
//

const callerSessionSlice = createSlice({
  name: 'callerSession',

  initialState: {
    id: NoSessionId as string | null,
    status: 'NotTakingCalls' as Status,
    caller: '',
    relayCallId: '',
    webRtcConnections: [] as string[],
    switchboardConnections: [] as string[],

    errorMessages: [] as string[],
  },

  reducers: {
    startedTakingCalls: (state) => {
      state.status = 'TakingCalls';
    },

    stoppedTakingCalls: (state) => {
      // taking calls and considering can both stop taking calls potentially in case of an error.
      if (
        state.status === 'TakingCalls' ||
        state.status === 'ConsideringPushedCall'
      ) {
        state.status = 'NotTakingCalls';
      } else {
        state.errorMessages.push(
          'Stopped taking calls while in status ' + state.status
        );
      }
    },

    /** Declining a call also takes us out of the call queue. */
    declinedPushedCall: (state) => {
      if (state.status === 'ConsideringPushedCall') {
        state.status = 'NotTakingCalls';
        // TODO: Unset other state
      }
    },

    // Todo: dispatch this from 'incoming call not available' toast
    missedPushedCall: (state) => {
      if (state.status === 'ConsideringPushedCall') {
        state.status = 'TakingCalls';
        state.caller = '';
        state.relayCallId = '';
      }
    },

    // newConnection represents a person becoming connected to Mercury.
    newConnection: (state, action) => {
      // TODO: This will probably change;
      const person = action.payload as string;
      if (person?.includes('hearing')) {
        state.switchboardConnections.push(person);
      }
      if (person.includes('deaf')) {
        state.webRtcConnections.push(person);
      }
    },
    disconnection: (state, action: PayloadAction<string>) => {
      // TODO: Redo this reducer
      const person = action.payload;
      if (state.status === 'InCallerSession') {
        state.webRtcConnections = state.webRtcConnections.filter(
          (c) => c !== person
        );
        state.switchboardConnections = state.switchboardConnections.filter(
          (c) => c !== person
        );
        state.status = 'TakingCalls';
        state.id = NoSessionId;
        state.caller = '';
      }
    },

    handedOff: (state) => {
      if (state.status === 'InCallerSession') {
        state.status = 'TakingCalls';
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      incomingCallPushed,
      (state, action: PayloadAction<CallPushPayload>) => {
        if (state.status === 'TakingCalls') {
          state.status = 'ConsideringPushedCall';
          state.relayCallId = action.payload.relayCallId;
        } else {
          state.errorMessages.push(
            'Call pushed while in status ' + state.status
          );
        }
      }
    );

    // This is what will begin a caller session.
    builder.addCase(incomingCallAccepted, (state, action) => {
      if (state.status !== 'ConsideringPushedCall') {
        // TODO: Move side effects outside of reducers
        inputs.analyticsFunction(action);
        return;
      }

      state.status = 'InCallerSession';
      state.id = action.payload.newCallerSessionId;
      state.relayCallId = action.payload.relayCallId;
    });

    builder.addMatcher(
      () => true,
      () => {
        // TODO: This reducer will run after every action,
        // and will make sure that the caller session ends
        // when everyone becomes disconnected.
        // Edge case: Zoom/Teams calls might not appear to have anyone connected
        // but they do still represent a caller session.
      }
    );
  },
});

export const callerSessionReducer = callerSessionSlice.reducer;

//
// Actions
//

export const {
  startedTakingCalls,
  stoppedTakingCalls,
  declinedPushedCall,
  missedPushedCall,
  newConnection,
  disconnection,
  handedOff,
} = callerSessionSlice.actions;

export const incomingCallPushed = createAction(
  'callerSession/incomingCallPushed',
  (data: RegularCall) => {
    return {
      payload: {
        caller: data.Call.CallerConnectionString,
        relayCallId: data.Call.Id.toString(),
        isDeafToHearing: data.Call.IsDeafToHearing,
      } as CallPushPayload,
    };
  }
);

export const teamingCallPushed = createAction(
  'callerSession/incomingCallPushed',
  (data: TeamingRequest) => {
    return {
      payload: {
        caller: data.TeamingCall.CallerConnectionString,
        relayCallId: data.TeamingCall.Id.toString(),
        isDeafToHearing: data.TeamingCall.IsDeafToHearing,
      } as CallPushPayload,
    };
  }
);

// This means an incoming call has been accepted by the interpeter.
// This will begin a caller session.
export const incomingCallAccepted = createAction(
  'callerSession/incomingCallAccepted',
  (relayCallId: string) => {
    return {
      payload: {
        relayCallId,
        newCallerSessionId: nanoid(),
      },
    };
  }
);

export const newRtcConnection = createAction('callerSession/newRtcConnection');

//
// Selectors
//

export function selectWebRTCConnections(state: RootState) {
  const slice = state.callerSession;
  return slice.webRtcConnections.map((c) => `WebRTC to ${c}`);
}
export function selectSwitchboardConnections(state: RootState) {
  const slice = state.callerSession;
  return slice.switchboardConnections.map((c) => `Switchboard to ${c}`);
}

export function selectCallerSessionId(state: RootState) {
  const slice = state.callerSession;
  return slice.id;
}

/** True if Mercury should allow calls to be pushed from Relay. */
export function selectAllowPushingCalls(state: RootState) {
  const slice = state.callerSession;
  return slice.status === 'TakingCalls';
}

/** Returns D2H, H2D, or null if no caller. */
export function selectCallFlowType(state: RootState) {
  const slice = state.callerSession;
  if (!slice.caller) {
    return null;
  }

  return slice.caller.includes('deaf') ? 'D2H' : 'H2D';
}

export function selectCallerSessionStatus(state: RootState) {
  return state.callerSession.status;
}
