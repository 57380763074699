import { withSentryReactRouterV6Routing } from '@sentry/react';
import type { ReactElement } from 'react';
import { lazy, Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { LoadingPortal } from 'features/app-loading/components';
import { useAppSelector } from 'features/app/hooks';
import { MultiMode } from 'features/multi-mode/components';
import { PageRoute } from 'features/router/routes';
import { sessionIdSelector } from 'features/session/store';

const MainContainer = lazy(() => import('./MainContainer'));
const DashboardPage = lazy(() => import('pages/DashboardPage/DashboardPage'));
const VriCallPage = lazy(() => import('pages/VriCallPage/VriCallPage'));
const VrsCallPage = lazy(() => import('pages/VrsCallPage/VrsCallPage'));
const VriConferenceCallPage = lazy(
  () => import('pages/VriConferenceCallPage/VriConferenceCallPage')
);
const VrsConferenceCallPage = lazy(
  () => import('pages/VrsConferenceCallPage/VrsConferenceCallPage')
);
const ZoomPage = lazy(() => import('pages/ZoomPage/ZoomPage'));
const AccessDeniedPage = lazy(() => import('pages/error/AccessDeniedPage'));
const NotFoundPage = lazy(() => import('pages/error/NotFoundPage'));
const SomethingWentWrongPage = lazy(
  () => import('pages/error/SomethingWentWrongPage')
);
const SentryRoutes = withSentryReactRouterV6Routing(Routes);

export const Router = () => {
  const sessionId = useAppSelector(sessionIdSelector);

  const validateOnSessionId = (component: ReactElement) =>
    sessionId ? component : <Navigate replace to={PageRoute.MAIN} />;

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingPortal />}>
        <SentryRoutes>
          <Route path={PageRoute.MAIN} element={<MultiMode />}>
            <Route element={<MainContainer />}>
              <Route index element={<DashboardPage />} />
              <Route
                path={PageRoute.VRI_CALL}
                element={validateOnSessionId(<VriCallPage />)}
              />

              <Route
                path={PageRoute.VRS_CALL}
                element={validateOnSessionId(<VrsCallPage />)}
              />

              <Route
                path={PageRoute.VRI_CONFERENCE}
                element={<VriConferenceCallPage />}
              />

              <Route
                path={PageRoute.VRS_CONFERENCE}
                element={<VrsConferenceCallPage />}
              />
            </Route>

            <Route path={PageRoute.ZOOM} element={<ZoomPage />} />
          </Route>

          <Route
            path={PageRoute.SOMETHING_WENT_WRONG}
            element={<SomethingWentWrongPage />}
          />
          <Route
            path={PageRoute.ACCESS_DENIED}
            element={<AccessDeniedPage />}
          />
          <Route path='*' element={<NotFoundPage />} />
        </SentryRoutes>
      </Suspense>
    </BrowserRouter>
  );
};
