import type { Theme } from '@mui/material';

import type { ThemeComponents } from 'features/theme/types';

export default function Typography(theme: Theme): Partial<ThemeComponents> {
  return {
    MuiTypography: {
      styleOverrides: {
        root: {
          wordWrap: 'break-word',
        },
        paragraph: theme.unstable_sx({
          mb: 0,
        }),
      },
    },
  };
}
