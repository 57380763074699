import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { removePlusFromPhoneNumber } from 'features/call/call-base/helpers';
import { CallDaoService } from 'features/call/call-base/services';
import { callIdSelector } from 'features/call/call-base/store/selectors';
import { CallDetailsStatus } from 'features/call/call-details/enums';
import { setCallDetailsCalleeStatus } from 'features/call/call-details/store';
import {
  setHearingAsDisconnected,
  setHearingLocation,
  setPrimaryHearingPhoneNumber,
} from 'features/call/call-hearing/store';
import {
  dialedNumbersListSelector,
  setDialedNumberHistory,
} from 'features/dialed-numbers-history/store';
import { useVoiceMeetingParticipant } from 'features/voice-meeting/hooks';

export const useCallHearingParticipantHandlers = () => {
  const dispatch = useAppDispatch();

  const { connectHearing, disconnectHearing } = useVoiceMeetingParticipant();
  const callId = useAppSelector(callIdSelector);
  const dialedNumbersList = useAppSelector(dialedNumbersListSelector);

  const handleRegularHearingCall = useCallback(
    async (phoneNumber: string) => {
      dispatch(
        setDialedNumberHistory([
          removePlusFromPhoneNumber(phoneNumber),
          ...dialedNumbersList,
        ])
      );

      await connectHearing(phoneNumber);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [connectHearing]
  );

  const handleHearingSpawnNewCall = useCallback(
    async (phoneNumber: string) => {
      dispatch(setPrimaryHearingPhoneNumber(phoneNumber));

      const { CalleeConnectionLocation } =
        await CallDaoService.updateCalleePhoneNumber({
          callId,
          phoneNumber,
        });
      dispatch(
        setHearingLocation({
          location: CalleeConnectionLocation,
          phoneNumber,
        })
      );
      await handleRegularHearingCall(phoneNumber);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleRegularHearingCall]
  );

  const handleHearingCancel = async (id: string) => {
    await disconnectHearing(id);
    dispatch(setHearingAsDisconnected(id));
    dispatch(
      setCallDetailsCalleeStatus({
        isDeafParticipant: false,
        status: CallDetailsStatus.CALLEE_RECONNECT,
      })
    );
  };

  const handleHearingDisconnect = async (id: string) => {
    await disconnectHearing(id);
  };

  return {
    handleRegularHearingCall,
    handleHearingCancel,
    handleHearingDisconnect,
    handleHearingSpawnNewCall,
  };
};
