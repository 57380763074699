import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { VrsCallType } from 'features/call/vrs-call/enums';
import type { VrsCallState } from 'features/call/vrs-call/interfaces';

export const initialVrsCallState: VrsCallState = {
  callType: null,
  callWasPlaced: false,
  signMailGreetingReached: false,
  shouldCreateDataChannel: true,
  isAppUnloading: false,
};

const vrsCallSlice = createSlice({
  name: 'vrs-call',
  initialState: initialVrsCallState,
  reducers: {
    setVrsCallType: (state, action: PayloadAction<VrsCallType | null>) => {
      state.callType = action.payload;
    },
    resetVrsCall: (state) => {
      state.callType = null;
    },
    setCallWasPlaced: (state) => {
      state.callWasPlaced = true;
    },
    setShouldCreateDataChannel: (state, action: PayloadAction<boolean>) => {
      state.shouldCreateDataChannel = action.payload;
    },
    resetCallWasPlaced: (state) => {
      state.callWasPlaced = false;
    },
    appUnloading(state) {
      state.isAppUnloading = true;
    },
  },
});

export const {
  setVrsCallType,
  resetVrsCall,
  setCallWasPlaced,
  resetCallWasPlaced,
  setShouldCreateDataChannel,
  appUnloading,
} = vrsCallSlice.actions;

export const vrsCallReducer = vrsCallSlice.reducer;
