import type { Theme } from '@mui/material';

import type { ThemeComponents } from 'features/theme/types';

export default function Link(theme: Theme): Partial<ThemeComponents> {
  return {
    MuiLink: {
      styleOverrides: {
        root: theme.unstable_sx({
          cursor: 'pointer',
        }),
      },
    },
  };
}
