import type {
  ConnectingEvent,
  EndEvent,
  IceCandidateEvent,
  IncomingEvent,
  Info,
  OutgoingEvent,
  PeerConnectionEvent,
  ReInviteEvent,
  SDPEvent,
  TerminateOptions,
} from 'jssip/lib/RTCSession';
import type { IncomingRequest } from 'jssip/lib/SIPMessage';
import type { URI } from 'jssip/lib/URI';
import { Subject } from 'rxjs';

import type {
  SipInfoMessage,
  SipSessionAcceptedEvent,
} from 'features/sip/interfaces';

export class SipSessionEventBus {
  static accepted$ = new Subject<SipSessionAcceptedEvent>();
  static failed$ = new Subject<EndEvent>();
  static getUserMediaFailed$ = new Subject<void>();
  static ended$ = new Subject<EndEvent>();
  static reInvite$ = new Subject<ReInviteEvent>();
  static connecting$ = new Subject<ConnectingEvent>();
  static progress$ = new Subject<OutgoingEvent | IncomingEvent>();
  static sdp$ = new Subject<SDPEvent>();
  static icecandidate$ = new Subject<IceCandidateEvent>();
  static incomingDtmf$ = new Subject<IncomingRequest>();
  static registerOnSipServer$ = new Subject<void>();
  static registerSuccess$ = new Subject<void>();
  static registerFailed$ = new Subject<void>();
  static sendReinvite$ = new Subject<void>();

  // Actions
  static terminate$ = new Subject<TerminateOptions | void>();
  static refer$ = new Subject<string | URI>();
  static peerConnection$ = new Subject<PeerConnectionEvent>();
  static updateCall$ = new Subject<OutgoingEvent>();
  static endCall$ = new Subject<void>();

  // SIP INFO messages
  static incomingInfo$ = new Subject<Info>();
  static outgoingInfo$ = new Subject<SipInfoMessage>();
}
