import { httpClient } from 'common/services';
import type { N11NumberRequest } from 'features/n11/interfaces';

async function getN11Number({ phoneNumber, callId }: N11NumberRequest) {
  const route = `/call/${callId}/getN11Number`;
  const response = await httpClient.post(route, { phoneNumber });
  return response.data;
}

export const N11DaoService = {
  getN11Number,
};
