import { Typography } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'features/theme/styled';
import theme from 'features/theme/theme';
import { ArrowDownIcon, ArrowUpIcon, DialedHistoryIcon } from 'assets/svg';

interface DialedNumbersHistoryButtonProps {
  dialedNumbersList: string[];
  isOpenHistory: boolean;
  onOpenHistory: () => void;
}

const DialedNumbersHistoryStyleButton = styled('span')`
  cursor: pointer;
`;

const DialedNumbersHistoryStyleIcon = styled(DialedHistoryIcon)`
  vertical-align: middle;
`;

const DialedNumbersHistoryTitle = styled(Typography)`
  color: ${theme.palette.grey[300]};
  margin-left: ${theme.spacing(2.5)};
  margin-right: ${theme.spacing(6)};
`;

export const DialedNumbersHistoryButton: FC<
  DialedNumbersHistoryButtonProps
> = ({ dialedNumbersList, isOpenHistory, onOpenHistory }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'dialedNumbersHistory',
  });

  return (
    <>
      {dialedNumbersList.length !== 0 && (
        <DialedNumbersHistoryStyleButton onClick={onOpenHistory}>
          <DialedNumbersHistoryStyleIcon />
          <DialedNumbersHistoryTitle variant='label'>
            {t('btnText')}
          </DialedNumbersHistoryTitle>
          {isOpenHistory ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </DialedNumbersHistoryStyleButton>
      )}
    </>
  );
};
