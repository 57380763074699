import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';
import type { VrsChatState } from 'features/vrs-chat/interfaces';

export const initialVrsChatState: VrsChatState = {
  isShowPopup: false,
};

const vrsChatSlice = createSlice({
  initialState: initialVrsChatState,
  name: 'vrs-chat',
  reducers: {
    toggleVrsChatPopup: (state) => {
      state.isShowPopup = !state.isShowPopup;
    },
    openVrsChatPopup(state) {
      state.isShowPopup = true;
    },
    resetVrsChatState: () => initialVrsChatState,
  },
});

export const { toggleVrsChatPopup, resetVrsChatState, openVrsChatPopup } =
  vrsChatSlice.actions;

export const isShowPopupSelector = ({ vrsChat }: RootState) =>
  vrsChat.isShowPopup;

export const vrsChatReducer = vrsChatSlice.reducer;
