import { t } from 'i18next';

export const translateHearingName = (name: string): string => {
  const defaultHearingName = /Unknown Hearing Person/i;
  if (defaultHearingName.test(name)) {
    return t('unknownHearingName');
  }

  return name;
};
