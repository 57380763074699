import { EndpointProductName } from 'common/enums';

export class EndpointProductService {
  private static _productName: EndpointProductName | null;

  static get productName() {
    return EndpointProductService._productName;
  }

  static setName(name: string) {
    EndpointProductService._productName = name as EndpointProductName;
  }

  static clearName() {
    EndpointProductService._productName = null;
  }

  static get isSignTimeWeb() {
    return (
      EndpointProductService.productName === EndpointProductName.SIGN_TIME_WEB
    );
  }

  static get isSignTimeIos() {
    return (
      EndpointProductService.productName === EndpointProductName.SIGN_TIME_IOS
    );
  }
}
