import type { Call } from 'features/call/call-base/interfaces';
import type { CallState } from 'features/call/call-base/store';

export const adaptIncomingCall = (call: Call): Partial<CallState> => {
  return {
    id: call.Id,
    languageCode: call.LanguageCode,
    genderRequest: call.GenderRequest,
    isDeafToHearing: call.IsDeafToHearing,
    isCanBeTransferred: call.CanBeTransferred,
    callerConnectionType: call.CallerConnectionType,
    isHearingCallerIdBlocked: call.IsInboundHearingCallerIdBlocked,
    endpointMacAddress: call.EndpointMacAddress,
    calleeConnectionString: call.CalleeConnectionString,
  };
};
