import { forwardRef } from 'react';
import { AlertTitle, Typography } from '@mui/material';
import type { AlertProps } from '@mui/material/Alert';
import MuiAlert from '@mui/material/Alert';

export interface CustomAlertProps extends AlertProps {
  title?: string;
  message?: string;
}

export const Alert = forwardRef<HTMLDivElement, CustomAlertProps>(
  ({ title, message, ...args }, ref) => (
    <MuiAlert ref={ref} {...args}>
      {title && <AlertTitle>{title}</AlertTitle>}
      {message && (
        <Typography paragraph variant='helperText' m='0' mt={title ? 2.5 : 1}>
          {message}
        </Typography>
      )}
    </MuiAlert>
  )
);

Alert.displayName = 'Alert';
Alert.defaultProps = {
  title: '',
  message: '',
};
