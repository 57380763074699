import type { AudioVideoEventAttributes, EventName } from 'amazon-chime-sdk-js';

import { useAppDispatch } from 'features/app/hooks';
import { hearingAudioEnd } from 'features/call/call-base/store';
import { useVoiceMeetingContext } from 'features/voice-meeting/hooks';
import { VoiceMeetingEventBus } from 'features/voice-meeting/services';

export const useVoiceMeetingSetupListeners = () => {
  const { service } = useVoiceMeetingContext();
  const dispatch = useAppDispatch();

  const addEventObserver = () =>
    service.meetingSession!.eventController.addObserver({
      eventDidReceive: (
        name: EventName,
        attributes: AudioVideoEventAttributes
      ) => {
        const meetingEventErrors = [
          'meetingStartFailed',
          'meetingFailed',
          'signalingDropped',
        ];
        if (meetingEventErrors.includes(name)) {
          VoiceMeetingEventBus.meetingError$.next({
            name,
            attributes,
          });
        }
      },
    });

  const addAudioVideoObserver = () =>
    service.meetingSession?.audioVideo.addObserver({
      audioVideoDidStop: () => {
        dispatch(hearingAudioEnd());
      },
    });

  const subscribeToAttendeeIdPresence = () =>
    service.meetingSession?.audioVideo.realtimeSubscribeToAttendeeIdPresence(
      (attendeeId, present, externalUserId) => {
        VoiceMeetingEventBus.attendeeIdPresence$.next({
          attendeeId,
          present,
          externalUserId,
        });
      }
    );

  const realtimeSubscribeToFatalError = () =>
    service.meetingSession?.audioVideo.realtimeSubscribeToFatalError(
      (error: Error) => {
        VoiceMeetingEventBus.fatalError$.next(error);
      }
    );

  const setupListeners = async () => {
    if (!service.meetingSession) {
      throw Error('DEBUG: Meeting session not found');
    }

    addEventObserver();
    addAudioVideoObserver();
    subscribeToAttendeeIdPresence();
    realtimeSubscribeToFatalError();
  };

  return {
    setupListeners,
  };
};
