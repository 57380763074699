const sorensonVpnIpRanges = [
  // VPN IP ranges; currently, all Sorenson VPN connections seem to use this single IP address
  '209.169.244.29/32',
];

async function getPublicIpAddress(): Promise<string> {
  const response = await fetch('https://api.ipify.org?format=json');
  if (!response.ok) {
    throw new Error('Failed to retrieve public IP address');
  }
  const data = await response.json();
  return data.ip;
}

async function checkVpn(): Promise<boolean> {
  const isIpInRange = (ip: string, range: string) => {
    const [rangeIp, prefixLength] = range.split('/');
    const mask = -1 << (32 - +prefixLength);
    const rangeIpNum = ipToNumber(rangeIp) & mask;
    const ipNum = ipToNumber(ip) & mask;
    return rangeIpNum === ipNum;
  };

  const ipToNumber = (ip: string) => {
    return ip
      .split('.')
      .reduce((acc, octet) => (acc << 8) + parseInt(octet, 10), 0);
  };

  try {
    const publicIpAddress = await getPublicIpAddress();

    return sorensonVpnIpRanges.some((range) =>
      isIpInRange(publicIpAddress, range)
    );
  } catch {
    return false;
  }
}

export const VpnDetectorService = {
  checkVpn,
};
