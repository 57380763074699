import { useVoiceMeetingContext } from 'features/voice-meeting/hooks';

export const useVoiceMeetingAudio = () => {
  const { service } = useVoiceMeetingContext();

  const mute = () => {
    service.meetingSession?.audioVideo.realtimeMuteLocalAudio();
  };

  const unmute = () => {
    service.meetingSession?.audioVideo.realtimeUnmuteLocalAudio();
  };

  const stopAudioInput = () => {
    service.meetingSession?.audioVideo?.stopAudioInput();
  };

  const startAudioInput = (stream: MediaStream) => {
    service.meetingSession?.audioVideo?.startAudioInput(stream);
  };

  const mixIntoAudioInput = (stream: MediaStream) =>
    service.meetingSession?.audioVideo?.mixIntoAudioInput(stream);

  const getCurrentMeetingAudioStream = async () =>
    service.meetingSession?.audioVideo?.getCurrentMeetingAudioStream();

  return {
    stopAudioInput,
    mixIntoAudioInput,
    getCurrentMeetingAudioStream,
    startAudioInput,
    mute,
    unmute,
  };
};
