import { createAsyncThunk } from '@reduxjs/toolkit';
import { t } from 'i18next';

import {
  emergencyAddressInformationSelector,
  isEmergencyDialbackSelector,
} from './emergencySelectors';
import {
  markAddressVerificationNeeded,
  markAsEmergencyCall,
  setEmergencyAddressInformation,
  setEmergencyCallbackConnectionPoint,
  setEmergencyConnectionPoint,
  setEmergencyCoordinates,
  setIsEmergencyDeafReconnectionNeeded,
  setRegisteredAddress,
} from './emergencySlice';

import type { RootState } from 'features/app/store/store';
import { AudioPlayer } from 'features/audio-player/services';
import type { TerminationType } from 'features/call/call-base/enums';
import {
  setCallTakenTime,
  setRelayCallState,
  setUpdatedCall,
} from 'features/call/call-base/store';
import { callIdSelector } from 'features/call/call-base/store/selectors';
import { VrsCallType } from 'features/call/vrs-call/enums';
import { setVrsCallType } from 'features/call/vrs-call/store';
import { EmergencyConnectionPoint } from 'features/emergency/enums';
import {
  dialbackEmergencyCallAnalytics,
  regularEmergencyCallAnalytics,
  spawnEmergencyCallAnalytics,
} from 'features/emergency/helpers';
import type { EmergencyAddressAndProvisionStatus } from 'features/emergency/interfaces';
import { EmergencyDaoService } from 'features/emergency/services';
import { handleError } from 'features/notification/store';
import { rnsConnectionIdSelector } from 'features/session/store';
import { userIdSelector } from 'features/user/store';
import { toIsoWithTimezone } from 'features/utils/helpers';
import { incomingCallAccepted } from 'features/caller-session/store';
import { deafPhoneNumberSelector } from 'features/call/call-deaf/store';
import { callerSessionAnalyticsInfo } from 'features/caller-session/helpers';

export const takeEmergencyCall = createAsyncThunk(
  'emergency/takeCall',
  async (payload, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const callId = callIdSelector(state);
      const deafPhoneNumber = deafPhoneNumberSelector(state);
      dispatch(incomingCallAccepted(callId.toString(), deafPhoneNumber));
      dispatch(callerSessionAnalyticsInfo('Accepted emergency incoming call'));
      const isEmergencyDialback = isEmergencyDialbackSelector(state);
      const { isGeoCoordinateAvailable, verify911Address } =
        emergencyAddressInformationSelector(state);

      const { call, provision } = await EmergencyDaoService.takeEmergencyCall({
        callId,
        userId: userIdSelector(state),
        sessionId: rnsConnectionIdSelector(state),
      });
      dispatch(setEmergencyAddressInformation(call));
      dispatch(setParticipantAddress(provision));

      if (isEmergencyDialback) {
        dispatch(setEmergencyCallbackConnectionPoint(provision.isProvisioned));
        dispatch(
          dialbackEmergencyCallAnalytics({
            methodName: 'takeEmergencyCall',
            isProvisioned: provision.isProvisioned,
            isGeoCoordinateAvailable,
            verify911Address,
          })
        );
      } else {
        dispatch(setConnectionPoint(provision.isProvisioned));
        dispatch(
          regularEmergencyCallAnalytics({
            methodName: 'takeEmergencyCall',
            isProvisioned: provision.isProvisioned,
            isGeoCoordinateAvailable,
            verify911Address,
          })
        );
      }

      dispatch(setRelayCallState(call.CallState));
      dispatch(
        setCallTakenTime(toIsoWithTimezone(call.TakenOrAbandonDateTime))
      );
      return call;
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'takeEmergencyCall',
        })
      );
      return rejectWithValue(error);
    } finally {
      AudioPlayer.stop();
    }
  }
);

export const spawnEmergencyCall = createAsyncThunk(
  'emergency/spawnCall',
  async (
    terminationReason: TerminationType,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      const callId = callIdSelector(state);
      const sessionId = rnsConnectionIdSelector(state);

      const { call, provision } = await EmergencyDaoService.spawnEmergencyCall({
        callId,
        sessionId,
        terminationReason,
      });
      const { isGeoCoordinateAvailable, verify911Address } =
        emergencyAddressInformationSelector(state);

      dispatch(setParticipantAddress(provision));

      dispatch(setConnectionPoint(provision.isProvisioned));
      dispatch(
        spawnEmergencyCallAnalytics({
          methodName: 'spawnEmergencyCall',
          isProvisioned: provision.isProvisioned,
          isGeoCoordinateAvailable,
          verify911Address,
        })
      );
      dispatch(setUpdatedCall(call));
      dispatch(markAsEmergencyCall());
      dispatch(setVrsCallType(VrsCallType.EMERGENCY_CALL));

      return call.Id;
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'spawnEmergencyCall',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const setParticipantAddress = createAsyncThunk(
  'emergency/participantAddress',
  async (provision: EmergencyAddressAndProvisionStatus, { dispatch }) => {
    if (Object.keys(provision).length) {
      dispatch(
        setRegisteredAddress({
          name: `${prepareRegisteredAddress(provision)}${provision.zip}`,
          status: provision.isProvisioned ? 'approved' : 'pending',
        })
      );
    } else {
      dispatch(
        handleError({
          error: 'Error',
          title: t('notRegisteredAddress'),
          methodName: 'setParticipantAddress',
        })
      );
    }
  }
);

export const setConnectionPoint = createAsyncThunk(
  'emergency/connectionPoint',
  async (isProvisioned: boolean, { dispatch, getState }) => {
    const state = getState() as RootState;
    const callId = callIdSelector(state);
    const { isGeoCoordinateAvailable, verify911Address } =
      emergencyAddressInformationSelector(state);

    if (isProvisioned && !isGeoCoordinateAvailable && !verify911Address) {
      dispatch(setEmergencyConnectionPoint(EmergencyConnectionPoint.PSAP));
      return;
    }

    if (isProvisioned && !isGeoCoordinateAvailable && verify911Address) {
      dispatch(markAddressVerificationNeeded());
      dispatch(setIsEmergencyDeafReconnectionNeeded(true));
      return;
    }

    if (!isProvisioned && !isGeoCoordinateAvailable) {
      dispatch(setEmergencyConnectionPoint(EmergencyConnectionPoint.ECRC));
      return;
    }

    if (isGeoCoordinateAvailable) {
      const emergencyCoordinates =
        await EmergencyDaoService.getEmergencyCoordinates(callId);

      dispatch(setEmergencyConnectionPoint(EmergencyConnectionPoint.PSAP));
      dispatch(
        setEmergencyCoordinates({
          latitude: emergencyCoordinates.Latitude,
          altitude: emergencyCoordinates.Altitude,
          longitude: emergencyCoordinates.Longitude,
          uncertainty: emergencyCoordinates.Uncertainty,
        })
      );
    }
  }
);

export const setCallbackAddress = createAsyncThunk(
  'emergency/callbackAddress',
  async (endpointMacAddress: string, { dispatch }) => {
    try {
      const provision =
        await EmergencyDaoService.getProvision(endpointMacAddress);

      dispatch(setParticipantAddress(provision));
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'setCallbackAddress',
        })
      );
    }
  }
);

const prepareRegisteredAddress = (
  provision: EmergencyAddressAndProvisionStatus
) => {
  const startAddress =
    provision.apartment || provision.street || provision.city
      ? `${provision.apartment} ${provision.street}\n${provision.city}, `
      : '';
  const endAddress = provision.state ? `${provision.state}, ` : '';

  return `${startAddress}${endAddress}`;
};
