import type { FC, PropsWithChildren } from 'react';

import { LoadingPortal } from 'features/app-loading/components';
import { isAppLoadingSelector } from 'features/app-loading/store';
import { useAppSelector } from 'features/app/hooks';

export const AppLoading: FC<PropsWithChildren> = ({ children }) => {
  const isAppLoading = useAppSelector(isAppLoadingSelector);

  return (
    <>
      {isAppLoading && <LoadingPortal />}
      {children}
    </>
  );
};
