import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import type { FC } from 'react';

import theme from 'features/theme/theme';
import { AppLoading } from 'features/app-loading/components';
import App from 'features/app/App';
import { setupStoreOnce } from 'features/app/store/store';
import { EnvironmentConfig } from 'features/environment/components';
import { LaunchDarklyProvider } from 'features/feature-toggle/providers';
import { BrowserSupportingValidator } from 'features/supported-browsers/components';
import { InitializeFeatureFlags } from 'features/feature-toggle/components';

const RootComponent: FC = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline enableColorScheme />
    <BrowserSupportingValidator>
      <Provider store={setupStoreOnce()}>
        <AppLoading>
          <EnvironmentConfig>
            <LaunchDarklyProvider>
              <InitializeFeatureFlags />
              <App />
            </LaunchDarklyProvider>
          </EnvironmentConfig>
        </AppLoading>
      </Provider>
    </BrowserSupportingValidator>
  </ThemeProvider>
);

export default RootComponent;
