import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';

export const monitorHostIpAddressSelector = ({ monitor }: RootState) =>
  monitor.hostIpAddress;

export const adminContactSelector = ({ monitor }: RootState) =>
  monitor.adminContact;

export const isMonitorHostIpExistsSelector = createSelector(
  monitorHostIpAddressSelector,
  (monitorHostIpAddress) => Boolean(monitorHostIpAddress)
);
