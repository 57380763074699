import { createSlice } from '@reduxjs/toolkit';

export interface CallTimerState {
  isStarted: boolean;
}

export const initialCallTimerState: CallTimerState = {
  isStarted: false,
};

export const callTimerSlice = createSlice({
  name: 'call-timer',
  initialState: initialCallTimerState,
  reducers: {
    startCallTimerCount: (state) => {
      state.isStarted = true;
    },
    stopCallTimerCount: (state) => {
      state.isStarted = false;
    },
  },
});

export const { startCallTimerCount, stopCallTimerCount } =
  callTimerSlice.actions;

export const callTimerReducer = callTimerSlice.reducer;

export const isTimerStartedSelector = ({
  callTimer,
}: {
  callTimer: CallTimerState;
}) => callTimer.isStarted;
