import type { Breadcrumb } from '@sentry/types';

import {
  CHIME_LOGS_REGEXP,
  JS_SIP_LOGS_REGEXP,
} from 'features/sentry/constants';

export const isChimeBreadcrumb = (breadcrumb: Breadcrumb) => {
  return breadcrumb.message && CHIME_LOGS_REGEXP.test(breadcrumb.message);
};

export const isJsSipBreadcrumb = (breadcrumb: Breadcrumb) => {
  return breadcrumb.message && JS_SIP_LOGS_REGEXP.test(breadcrumb.message);
};
