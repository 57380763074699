import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { ButtonProps } from 'features/ui/elements';
import { Button } from 'features/ui/elements';

export const CallDetailsButtonCancel: FC<
  Pick<ButtonProps, 'onClick' | 'loading' | 'hotkey'>
> = ({ loading, onClick, hotkey }) => {
  const { t } = useTranslation();

  return (
    <Button
      fullWidth
      size='small'
      color='primary'
      loading={loading}
      onClick={onClick}
      hotkey={hotkey}
    >
      {t('cancel')}
    </Button>
  );
};
