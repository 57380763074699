export enum TeamingType {
  // In-person ad hoc teaming
  AdhocReq = 'AdhocReq',
  // In-person teaming
  Request = 'Request',
  // Remote Teaming
  RemoteRq = 'RemoteRq',
  // Remote in-center Teaming
  RemoteIc = 'RemoteIc',
}
