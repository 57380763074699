export enum SoundName {
  RING_BACK = 'ringback',
  RINGING = 'ringing',
  EMERGENCY_RINGING = 'emergencyRinging',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  HEARING_RINGING = 'hearingRinging',
  DEAF_TO_HEARING_RINGING = 'deafToHearingRinging',
  HEARING_TO_DEAF_RINGING = 'hearingToDeafRinging',
}
