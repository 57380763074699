import type { Call } from 'features/call/call-base/interfaces';
import type { CallDeafState } from 'features/call/call-deaf/interfaces';

export const adaptIncomingCallDeaf = (call: Call): Partial<CallDeafState> => {
  return {
    name: call.IsDeafToHearing ? call.CallerName : call.CalleeName,
    phoneNumber: call.DeafConnectionString,
    location: call.DeafConnectionLocation,
  };
};
