import { t } from 'i18next';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import {
  addPlusToPhoneNumber,
  parsePhoneNumber,
} from 'features/call/call-base/helpers';
import { AddHearingStatus } from 'features/call/call-details/enums';
import { setAddHearingStatus } from 'features/call/call-details/store';
import {
  pendingDisconnectById,
  setHearingLoadingStateById,
  setHearingLoadingStateByPhoneNumber,
} from 'features/call/call-hearing/store';
import { ParticipantsStatusEventBus } from 'features/call/call-status/services';
import { was911Dialed } from 'features/emergency/helpers';
import { useEmergencySpawnNewCall } from 'features/emergency/hooks';
import { isVrsModeSelector } from 'features/multi-mode/store';
import { IsN11Number } from 'features/n11/helpers';
import { handleError } from 'features/notification/store';
import {
  useVoiceMeetingSession,
  useVoiceMeetingSessionInit,
} from 'features/voice-meeting/hooks';
import {
  setVoiceSessionLoading,
  voiceSessionIdSelector,
} from 'features/voice-session/store';

export const useVoiceMeetingParticipant = () => {
  const dispatch = useAppDispatch();
  const voiceSessionId = useAppSelector(voiceSessionIdSelector);
  const { disconnectParticipant, addParticipant } = useVoiceMeetingSession();
  const { spawnNewCallEmergency } = useEmergencySpawnNewCall();
  const { createNewVoiceSession, replaceN11Number, clearCallErrorIfExists } =
    useVoiceMeetingSessionInit();
  const isVrs = useAppSelector(isVrsModeSelector);

  const connectHearingToExistingSession = async (phoneNumber: string) => {
    let hearingPhoneNumber = phoneNumber;
    try {
      if (isVrs && IsN11Number(phoneNumber)) {
        hearingPhoneNumber = await replaceN11Number(phoneNumber, true);
        if (phoneNumber === hearingPhoneNumber) {
          dispatch(setAddHearingStatus(AddHearingStatus.ENTER_PHONE_NUMBER));
          return;
        }
      }
      clearCallErrorIfExists();
      ParticipantsStatusEventBus.hearing.$connecting.next(hearingPhoneNumber);
      const hearingParticipant = await addParticipant(
        addPlusToPhoneNumber(parsePhoneNumber(hearingPhoneNumber))
      );

      if (hearingParticipant) {
        ParticipantsStatusEventBus.hearing.$connectingId.next({
          phoneNumber: hearingPhoneNumber,
          id: hearingParticipant.id,
        });
      }
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'connectHearingToExistingSession',
        })
      );
      ParticipantsStatusEventBus.hearing.$disconnected.next({
        phoneNumber: hearingPhoneNumber,
      });
    }
  };

  const connectHearing = async (phoneNumber: string) => {
    dispatch(
      setHearingLoadingStateByPhoneNumber({ isLoading: true, phoneNumber })
    );
    if (was911Dialed(phoneNumber) && isVrs) {
      await spawnNewCallEmergency();
    } else if (!voiceSessionId) {
      await createNewVoiceSession(phoneNumber);
    } else {
      await connectHearingToExistingSession(phoneNumber);
    }

    dispatch(
      setHearingLoadingStateByPhoneNumber({ isLoading: false, phoneNumber })
    );
  };

  const disconnectHearing = async (participantId: string) => {
    try {
      dispatch(pendingDisconnectById({ id: participantId }));
      dispatch(setHearingLoadingStateById({ isLoading: true, participantId }));
      dispatch(setVoiceSessionLoading(true));
      await disconnectParticipant(participantId);
    } catch (error) {
      dispatch(
        handleError({
          error,
          title: t('hearingDisconnectFailed'),
          methodName: 'disconnectHearing',
        })
      );
      throw error;
    } finally {
      dispatch(setVoiceSessionLoading(false));
      dispatch(setHearingLoadingStateById({ isLoading: false, participantId }));
    }
  };

  return {
    connectHearing,
    disconnectHearing,
  };
};
