import { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { parsePhoneNumber } from 'features/call/call-base/helpers';
import { AddHearingStatus } from 'features/call/call-details/enums';
import { addHearingStatusSelector } from 'features/call/call-details/store';
import type { HearingParticipant } from 'features/call/call-hearing/interfaces';
import { HearingParticipantType } from 'features/call/call-hearing/interfaces';
import { addHearing, hearingsSelector } from 'features/call/call-hearing/store';

export const useHearings = () => {
  const dispatch = useAppDispatch();
  const hearings = useAppSelector(hearingsSelector);
  const addHearingStatus = useAppSelector(addHearingStatusSelector);

  const createNewHearing = async (phoneNumber: string) => {
    dispatch(
      addHearing({
        id: '',
        location: '',
        name: '',
        phoneExtension: '',
        phoneNumber,
        status: 'disconnected',
        type: HearingParticipantType.SECONDARY,
        isLoading: false,
      })
    );
  };

  const addHearingIfNotExists = useCallback(
    async (hearingPhoneNumber: string) => {
      const parsedPhoneNumber = parsePhoneNumber(hearingPhoneNumber);
      const hearing = hearings.find(
        ({ phoneNumber }: HearingParticipant) =>
          phoneNumber === parsedPhoneNumber
      );

      if (!hearing) {
        await createNewHearing(parsedPhoneNumber);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hearings]
  );

  const isSecondaryConnectedHearing = (hearing: HearingParticipant) =>
    hearing.type === HearingParticipantType.SECONDARY &&
    hearing.status === 'connected';

  const atLeastOneActiveSecondaryHearing = useMemo(
    () => hearings.some(isSecondaryConnectedHearing),
    [hearings]
  );

  const activeSecondaryHearings = useMemo(
    () => hearings.filter(isSecondaryConnectedHearing),
    [hearings]
  );

  const activeHearings = useMemo(
    () => hearings.filter((hearing) => hearing.status === 'connected'),
    [hearings]
  );

  const showAlsoConnectedTo = useMemo(() => {
    return (
      atLeastOneActiveSecondaryHearing &&
      addHearingStatus === AddHearingStatus.BASE
    );
  }, [addHearingStatus, atLeastOneActiveSecondaryHearing]);

  return {
    activeHearings,
    atLeastOneActiveSecondaryHearing,
    showAlsoConnectedTo,
    activeSecondaryHearings,
    addHearingIfNotExists,
  };
};
