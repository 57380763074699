import type { ConnectingEvent } from 'jssip/lib/RTCSession';
import { Subject } from 'rxjs';

import type { DeafDisconnected } from 'features/call/call-deaf/interfaces';
import type {
  HearingPhone,
  HearingPhoneWithDisconnectReason,
  HearingPhoneWithId,
} from 'features/call/call-hearing/interfaces';
import type { AcceptedEvent } from 'features/sip/interfaces';

export class ParticipantsStatusEventBus {
  static hearing = {
    $connecting: new Subject<HearingPhone>(),
    $connectingId: new Subject<HearingPhoneWithId>(),
    $connected: new Subject<HearingPhoneWithId>(),
    $disconnected: new Subject<HearingPhoneWithDisconnectReason>(),
    $startInterpretingTime: new Subject<string>(),
  };
  static deaf = {
    $connecting: new Subject<ConnectingEvent>(),
    $connected: new Subject<AcceptedEvent>(),
    $disconnected: new Subject<DeafDisconnected>(),
    $unavailable: new Subject<void>(),
    $disconnectSignMail: new Subject<void>(),
    $connectSignMail: new Subject<void>(),
  };
  static $meetingError = new Subject<string>();
}
