import { Typography } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'features/app/hooks';
import { NewCallee } from 'features/call/call-details/components';
import { callErrorSelector } from 'features/call/call-hearing/store';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';
import { Button } from 'features/ui/elements';

export interface AddCalleeProps {
  onClick: (phoneNumber: string) => void;
  onCancel?: () => void;
  title: string;
  isLoading?: boolean;
}

export const AddCallee: FC<AddCalleeProps> = ({
  onClick,
  onCancel,
  title,
  isLoading,
}) => {
  const { t } = useTranslation();

  const error = useAppSelector(callErrorSelector);

  return (
    <Container>
      <Head>
        <Typography
          variant='textLargeMedium'
          color={theme.palette.common.white}
        >
          {title}
        </Typography>
      </Head>
      <NewCallee error={error} onClick={onClick} isLoading={isLoading} />
      {onCancel && (
        <CancelButtonStyle
          fullWidth
          size='small'
          color='secondary'
          onClick={onCancel}
        >
          {t('cancel')}
        </CancelButtonStyle>
      )}
    </Container>
  );
};

const CancelButtonStyle = styled(Button)`
  margin-top: ${theme.spacing(6)};
`;

const Container = styled('div')`
  border-radius: ${theme.spacing(4)};
  padding: ${theme.spacing(8)};
  background-color: ${theme.palette.grey[800]};
`;

const Head = styled('div')`
  color: ${theme.palette.common.white};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${theme.spacing(4)};
`;
