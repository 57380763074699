import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import type { Theme } from '@mui/material';

import type { ThemeComponents } from 'features/theme/types';

export default function Alert(theme: Theme): Partial<ThemeComponents> {
  return {
    MuiAlert: {
      defaultProps: {
        variant: 'filled',
        iconMapping: {
          info: <InfoIcon />,
          success: <CheckCircleIcon />,
          warning: <WarningIcon />,
          error: <ErrorIcon />,
        },
      },
      styleOverrides: {
        root: theme.unstable_sx({
          maxWidth: 360,
          borderRadius: 2,
          py: 7,
          px: 8,
        }),
        filledError: theme.unstable_sx({
          backgroundColor: theme.palette.error.light,
        }),
        filledWarning: theme.unstable_sx({
          color: theme.palette.common.white,
        }),
        icon: theme.unstable_sx({
          p: 0,
          '& .MuiSvgIcon-root': {
            width: 24,
            height: 24,
          },
        }),
        message: theme.unstable_sx({
          width: 390,
          p: 0,
          mt: 1,
        }),
        action: theme.unstable_sx({
          p: 0,
          mr: 0,
          ml: 6,
          '& .MuiButtonBase-root': {
            p: 0,
            '& .MuiSvgIcon-root': {
              width: 20,
              height: 20,
            },
          },
        }),
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: theme.unstable_sx({
          ...theme.typography.subtitle1,
          mt: 0,
          mb: 1,
        }),
      },
    },
  };
}
