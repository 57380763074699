export enum LogoutType {
  Unknown,
  NotSet,
  Normal,
  PushTimeout,
  SessionTimeout,
  AdminForceLogout,
  TeamingLogout,
  TeamSwitchLogout,
  AdmnCnclCallLgot,
  VPhoneUnavail,
  ConnectionLoss,
}
