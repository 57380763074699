import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { AppSupportedLanguage } from './enums';

import { envConfig } from 'features/environment/services';

export const initI18n = () => {
  // eslint-disable-next-line import/no-named-as-default-member
  return i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
      nonExplicitSupportedLngs: true,
      supportedLngs: Object.values(AppSupportedLanguage),
      fallbackLng: 'en',
      detection: {
        order: ['navigator'],
      },
      backend: {
        loadPath: '/i18n/{{lng}}/translation.json',
      },
      interpolation: {
        escapeValue: !envConfig.isStorybookPresents,
      },
    });
};
