import type { FC } from 'react';
import { useEffect } from 'react';

export const DynatraceHandler: FC = () => {
  useEffect(() => {
    if (import.meta.env.VITE_APP_DYNATRACE_RUM_ID) {
      const script = document.createElement('script');
      script.src = `https://js-cdn.dynatrace.com/jstag/${
        import.meta.env.VITE_APP_DYNATRACE_RUM_ID
      }_complete.js`;
      script.crossOrigin = 'anonymous';
      script.async = true;
      document.head.appendChild(script);
    }
  }, []);

  return <></>;
};
