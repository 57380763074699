import type { Theme } from '@mui/material';
import { alpha } from '@mui/material';

import { CheckedIcon, UncheckedIcon } from 'assets/svg';
import type { ThemeComponents } from 'features/theme/types';

export default function Checkbox(theme: Theme): Partial<ThemeComponents> {
  return {
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
        size: 'medium',
        disableRipple: true,
        icon: <UncheckedIcon />,
        checkedIcon: <CheckedIcon />,
      },
      styleOverrides: {
        root: theme.unstable_sx({
          p: 0,
          m: 4,
          borderRadius: 2,

          '&.Mui-focusVisible': {
            outline: 4,
            outlineStyle: 'solid',
            outlineColor: alpha(theme.palette.common.white, 0.3),
          },
          '&.Mui-disabled': {
            'svg rect': {
              stroke: theme.palette.grey[300],
            },
            '&.Mui-checked': {
              'svg rect': {
                stroke: 'unset',
                fill: theme.palette.grey[300],
              },
              'input:hover ~ svg rect': {
                fill: theme.palette.grey[300],
              },
              'svg path': {
                stroke: theme.palette.grey[600],
              },
            },
          },
        }),
        colorPrimary: theme.unstable_sx({
          '&.Mui-checked svg rect': {
            fill: theme.palette.primary[500],
          },
          '&.Mui-checked input:hover ~ svg rect': {
            fill: theme.palette.primary[200],
          },
        }),
        colorSecondary: theme.unstable_sx({
          '&.Mui-checked input:hover ~ svg rect': {
            fill: theme.palette.tangerine[700],
          },
        }),
      },
    },
  };
}
