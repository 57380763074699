import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  isDeafToHearingSelector,
  callIdSelector,
  isRelayCallStateInProgressSelector,
  isRelayCallStateInvalidSelector,
  terminationTypeSelector,
} from 'features/call/call-base/store/selectors';
import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { CallNotificationType } from 'features/call/call-events/enums';
import { CallTimerDaoService } from 'features/call/call-timer/services';
import { isReportNeededSelector } from 'features/call/vrs-call-comments/store';
import { terminationDeciderVrs } from 'features/call/vrs-call/helpers';
import { resetCallWasPlaced } from 'features/call/vrs-call/store';
import { wasCallPlacedSelector } from 'features/call/vrs-call/store/vrsCallSelectors';
import { handleError } from 'features/notification/store';
import { sendRnsNotification } from 'features/rns/store';
import type { SpawnCallRnsEventData } from 'features/spawn-call/interfaces';
import { spawnCall } from 'features/spawn-call/store';
import {
  forceAcceptTeaming,
  isAcceptingUserSelector,
  isTeamingStartedSelector,
  removeTeam,
  teamingAcceptingUserIdSelector,
} from 'features/teaming/teaming-base/store';
import { closeCallAnalytics } from 'features/call/call-base/helpers';
import { TerminationType } from 'features/call/call-base/enums';

export const useSpawnNewCall = () => {
  const dispatch = useAppDispatch();
  const isTeamingStarted = useAppSelector(isTeamingStartedSelector);
  const { t } = useTranslation('translation', {
    keyPrefix: 'newCall',
  });
  const callIdCurrent = useAppSelector(callIdSelector);
  const isReportNeeded = useAppSelector(isReportNeededSelector);
  const terminationType = useAppSelector(terminationTypeSelector);
  const isAcceptingUser = useAppSelector(isAcceptingUserSelector);
  const isRelayCallStateInProgress = useAppSelector(
    isRelayCallStateInProgressSelector
  );
  const isDeafToHearing = useAppSelector(isDeafToHearingSelector);
  const isCloseCallMade = useRef(false);
  const isRelayCallStateInvalid = useAppSelector(
    isRelayCallStateInvalidSelector
  );
  const wasCallPlaced = useAppSelector(wasCallPlacedSelector);

  const acceptingUserId = useAppSelector(teamingAcceptingUserIdSelector);

  const spawnCallTerminationType = useCallback(async () => {
    if (isCloseCallMade.current) {
      return;
    }

    const interpretTime =
      await CallTimerDaoService.getTotalInterpretTime(callIdCurrent);

    if (isTeamingStarted && isAcceptingUser) {
      return;
    }
    const termType = terminationDeciderVrs({
      interpretTime,
      wasCallPlaced,
      terminationType,
    });
    isCloseCallMade.current = true;
    dispatch(resetCallWasPlaced());
    return termType;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    callIdCurrent,
    isReportNeeded,
    terminationType,
    isTeamingStarted,
    isAcceptingUser,
    isRelayCallStateInProgress,
    isRelayCallStateInvalid,
    wasCallPlaced,
  ]);

  const sendSpawnedNewCallNotification = async ({
    callId,
  }: SpawnCallRnsEventData) => {
    try {
      await dispatch(
        sendRnsNotification({
          eventName: CallNotificationType.SPAWN_CALL,
          data: {
            callId,
          },
        })
      ).unwrap();
    } catch (error) {
      handleError({
        message: t('rnsErrorMessage'),
        methodName: 'sendSpawnedNewCallNotification',
        error,
      });
    }
  };

  const spawnNewCall = useCallback(async () => {
    if (isTeamingStarted) {
      await dispatch(
        removeTeam({
          suppressTeamEndNotify: true,
          userId: acceptingUserId,
        })
      ).unwrap();
    }

    const terminationReason =
      (await spawnCallTerminationType()) ?? terminationType;

    const terminationTypeString = TerminationType[terminationReason];
    dispatch(
      closeCallAnalytics(
        'closeCall',
        false,
        terminationTypeString,
        isDeafToHearing
      )
    );

    const newCall = await dispatch(spawnCall(terminationReason)).unwrap();

    if (isTeamingStarted) {
      await dispatch(forceAcceptTeaming()).unwrap();
    }

    await sendSpawnedNewCallNotification({
      callId: newCall.Id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTeamingStarted, acceptingUserId]);

  return {
    spawnNewCall,
    spawnCallTerminationType,
  };
};
