import type { FC } from 'react';

import { useAppSelector } from 'features/app/hooks';
import { CallDetailsButtonDisconnect } from 'features/call/call-details/components';
import { selectIsLoadingVco2LineHearing } from 'features/call/call-hearing/store';
import styled from 'features/theme/styled';
import { CallDetailsParticipantMetadataVco2Line } from 'features/vco-2-line/components';
import { useVoiceMeetingVco2LineHandlers } from 'features/vco-2-line/hooks';
import { isPrimaryVcoSenderSelector } from 'features/vco/store';

export const CallDetailsParticipantConnectedVco2Line: FC = () => {
  const isLoadingVco2LineHearing = useAppSelector(
    selectIsLoadingVco2LineHearing
  );
  const { handleVco2LineDisconnect } = useVoiceMeetingVco2LineHandlers();
  const isRequestingUser = useAppSelector(isPrimaryVcoSenderSelector);

  return (
    <Container>
      <CallDetailsParticipantMetadataVco2Line />
      {isRequestingUser && (
        <CallDetailsButtonDisconnect
          loading={isLoadingVco2LineHearing}
          onClick={handleVco2LineDisconnect}
        />
      )}
    </Container>
  );
};

const Container = styled('div')`
  display: flex;
  flex-direction: column;
`;
