import { type FC } from 'react';

import { useWatchRtc } from 'features/analytics/hooks';
import { DynatraceHandler } from 'features/dynatrace/components/DynatraceHandler';
import { MachinePreferencesHandler } from 'features/machine-preferences/handlers';
import { DeviceChangeHandler } from 'features/media-devices/handlers';
import { NotificationContainer } from 'features/notification/components';
import { PushNotificationHandler } from 'features/push-notifications/handlers';
import { Router } from 'features/router/components';
import { useBackButtonListener } from 'common/hooks';
import { HiddenDebugMenu } from 'features/hidden-debug-menu/components';

const App: FC = () => {
  useWatchRtc();
  useBackButtonListener();
  return (
    <>
      <HiddenDebugMenu />
      <Router />
      <NotificationContainer />
      <DeviceChangeHandler />
      <MachinePreferencesHandler />
      <PushNotificationHandler />
      <DynatraceHandler />
    </>
  );
};

export default App;
