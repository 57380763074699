import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';
import type { TerminationType } from 'features/call/call-base/enums';
import { setCallId, setRelayCallState } from 'features/call/call-base/store';
import { callIdSelector } from 'features/call/call-base/store/selectors';
import { rnsConnectionIdSelector } from 'features/session/store';
import { SpawnCallDaoService } from 'features/spawn-call/services';
import {
  isRequestingUserSelector,
  isTeamingInProgressSelector,
  isTeamingStartedSelector,
} from 'features/teaming/teaming-base/store';

export interface SpawnCallState {
  enabled: boolean;
}

export const initialSpawnCallState: SpawnCallState = {
  enabled: false,
};

export const spawnCallSlice = createSlice({
  name: 'spawnCall',
  initialState: initialSpawnCallState,
  reducers: {
    enableSpawnCall: (state) => {
      state.enabled = true;
    },
    disableSpawnCall: (state) => {
      state.enabled = false;
    },
  },
});

export const { enableSpawnCall, disableSpawnCall } = spawnCallSlice.actions;

export const spawnCallReducer = spawnCallSlice.reducer;

export const isSpawnCallEnabledSelector = ({ spawnCall }: RootState) =>
  spawnCall.enabled;

export const shouldCallControlsBeVisibleSelector = createSelector(
  isTeamingInProgressSelector,
  isTeamingStartedSelector,
  isRequestingUserSelector,
  (isTeamingInProgress, isTeamingStarted, isRequestingUser) =>
    !isTeamingInProgress || (isTeamingStarted && isRequestingUser)
);

export const spawnCall = createAsyncThunk(
  'spawnCall/spawn',
  async (
    terminationReason: TerminationType,
    { dispatch, getState, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    const callId = callIdSelector(state);
    const sessionId = rnsConnectionIdSelector(state);

    try {
      const call = await SpawnCallDaoService.spawnCall({
        callId,
        sessionId,
        terminationReason,
      });

      dispatch(setCallId(call.Id));
      dispatch(setRelayCallState(call.CallState));

      return call;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
