import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';
import type {
  MediaDevice,
  MediaDevicesState,
} from 'features/media-devices/interfaces';

export const initialMediaDevicesState: MediaDevicesState = {
  camera: null,
  microphone: null,
  speaker: null,
};

const mediaDevicesSlice = createSlice({
  name: 'media-devices',
  initialState: initialMediaDevicesState,
  reducers: {
    setCamera: (state, action: PayloadAction<MediaDevice>) => {
      state.camera = action.payload;
    },
    setMicrophone: (state, action: PayloadAction<MediaDevice>) => {
      state.microphone = action.payload;
    },
    setSpeaker: (state, action: PayloadAction<MediaDevice>) => {
      state.speaker = action.payload;
    },
  },
});

export const { setCamera, setMicrophone, setSpeaker } =
  mediaDevicesSlice.actions;

export const mediaDevicesSelector = ({ mediaDevices }: RootState) =>
  mediaDevices;

export const mediaDevicesReducer = mediaDevicesSlice.reducer;
