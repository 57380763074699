import { useCallback, useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { Hotkey } from 'config/enums';
import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { CallStatus } from 'features/call/call-base/enums';
import {
  handleCallNotReceived,
  setCallStatus,
  takeCall,
} from 'features/call/call-base/store';
import {
  isCallCanBeTransferredSelector,
  isDeafToHearingSelector,
} from 'features/call/call-base/store/selectors';
import { callAnswerTimeoutMs } from 'features/call/call-received/helpers';

export const useCallReceivedTakeCall = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const isDeafToHearing = useAppSelector(isDeafToHearingSelector);
  const isCallCanBeTransferred = useAppSelector(isCallCanBeTransferredSelector);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleTakeCall = useCallback(async () => {
    setIsLoading(true);
    await dispatch(takeCall()).unwrap();

    if (!isDeafToHearing) {
      dispatch(setCallStatus(CallStatus.CALL_IN_PROGRESS));
    }

    if (isCallCanBeTransferred) {
      timerRef.current = setTimeout(async () => {
        console.log('timer expired handleCallNotReceived called');
        await dispatch(handleCallNotReceived()).unwrap();
        setIsLoading(false);
      }, callAnswerTimeoutMs());
    }
  }, [dispatch, isDeafToHearing, isCallCanBeTransferred]);

  useHotkeys(
    Hotkey.TakeCall,
    () => {
      if (isLoading) {
        return;
      }

      handleTakeCall();
    },
    [handleTakeCall, isLoading]
  );

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return { handleTakeCall, isLoading };
};
