import { httpClient } from 'common/services';
import type {
  VoiceSessionAddParticipantRequest,
  VoiceSessionCreateRequest,
  VoiceSessionDeleteParticipantRequest,
  VoiceSessionResponse,
  VoiceSessionUpdateParticipantRequest,
  VoiceSessionDeleteRequest,
  VoiceSessionReturnToQueueRequest,
} from 'features/voice-session/interfaces';

async function getVoiceSessionById(id: string) {
  const route = `/voice-sessions/${id}`;
  const response = await httpClient.get<VoiceSessionResponse>(route);
  return response.data;
}

async function createVoiceSession(body: VoiceSessionCreateRequest) {
  const route = '/voice-sessions';
  const response = await httpClient.post<VoiceSessionResponse>(route, body);
  return response.data;
}

async function deleteVoiceSession({
  sessionId,
  keepAlive,
}: VoiceSessionDeleteRequest) {
  const route = `/voice-sessions/${sessionId}`;
  const response = await httpClient.delete<void>(route, { keepAlive });
  return response.data;
}

async function addParticipant({
  sessionId,
  participant,
  callId,
}: VoiceSessionAddParticipantRequest) {
  const route = `/voice-sessions/${sessionId}/participants`;
  const response = await httpClient.post<VoiceSessionResponse>(route, {
    participant,
    callId,
  });
  return response.data;
}

async function updateParticipant({
  sessionId,
  participantId,
  participant,
}: VoiceSessionUpdateParticipantRequest) {
  const route = `/voice-sessions/${sessionId}/participants/${participantId}`;
  const response = await httpClient.patch<VoiceSessionResponse>(route, {
    participant,
  });
  return response.data;
}

async function deleteParticipant({
  sessionId,
  participantId,
  keepAlive,
}: VoiceSessionDeleteParticipantRequest) {
  const route = `/voice-sessions/${sessionId}/participants/${participantId}`;
  const response = await httpClient.delete<void>(route, { keepAlive });
  return response.data;
}

async function returnParticipantToQueue({
  sessionId,
  hearingParticipantId,
}: VoiceSessionReturnToQueueRequest) {
  const route = `/voice-sessions/${sessionId}/participants/${hearingParticipantId}/return-to-queue`;
  const response = await httpClient.put<void>(route);
  return response.data;
}

export const VoiceSessionDaoService = {
  getVoiceSessionById,
  createVoiceSession,
  deleteVoiceSession,
  addParticipant,
  updateParticipant,
  deleteParticipant,
  returnParticipantToQueue,
};
