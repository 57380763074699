import { clone } from 'ramda';

import { envConfig } from 'features/environment/services';
import type { StaticSipSettings } from 'features/sip/interfaces';

const DEFAULT_SETTINGS: StaticSipSettings = {
  domain: envConfig.sip.domain,
  socket: {
    uri: envConfig.sip.domain,
    via_transport: 'auto',
  },
  session_timers: false,
  use_preloaded_route: false,
  pcConfig: {
    rtcpMuxPolicy: 'require',
    iceServers: [
      {
        urls: [envConfig.sip.turnServer],
        credential: envConfig.sip.iceServerCredential,
        username: envConfig.sip.iceServerUsername,
      },
      {
        urls: [envConfig.sip.stunServer, envConfig.sip.stunServerAlt],
      },
    ],
  },
};

class SipSettings {
  private settings: StaticSipSettings = clone(DEFAULT_SETTINGS);

  get() {
    return this.settings;
  }

  update(settings: Partial<StaticSipSettings>) {
    this.settings = {
      ...this.settings,
      ...settings,
    };
  }
}

export const sipSettings = new SipSettings();
