import type { FC } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'features/app/hooks';
import { getMachinePreferencesFromLocalStorage } from 'features/machine-preferences/helpers';
import { setMachinePreferences } from 'features/machine-preferences/store';
import { Mode } from 'features/multi-mode/enums';
import { modeSelector } from 'features/multi-mode/store';

export const MachinePreferencesHandler: FC = () => {
  const dispatch = useDispatch();
  const mode = useAppSelector(modeSelector);

  useEffect(() => {
    if (mode !== Mode.VRS) {
      return;
    }

    const machinePreferences = getMachinePreferencesFromLocalStorage();

    dispatch(setMachinePreferences(machinePreferences));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  return <></>;
};
