export enum TeamingReason {
  Emergency = 'Emergency',
  Asl = 'Asl',
  SpokenLanguage = 'SpokenLanguage',
  SpecializedContent = 'SpecializedContent',
  ConferenceCall = 'ConferenceCall',
  Witness = 'Witness',
  Handoff = 'Handoff',
  Other = 'Other',
}

export type TeamingReasonKeys = keyof typeof TeamingReason;
