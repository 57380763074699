export enum Hotkey {
  StartTakingCalls = 's',
  LeaveTheQueue = 'l',
  TakeCall = 'space',
  DeclineCall = 'd',
  SelfView = 'alt+s',
  DialPad = 'alt+shift+d',
  UserMicro = 'alt+m',
  VideoPrivacy = 'alt+p',
  BugReport = 'esc',
  NewCall = 'alt+n',
  ReturnToQueue = 'alt+r',
  TeamRequest = 'alt+t',
  HandoffRequest = 'alt+h',
  ShareText = 'alt+x',
  ClearText = 'alt+e',
  CallerConnectionStateChange = 'alt+1',
  CalleeConnectionStateChange = 'alt+2',
  Vco2Line = 'alt+3',
  AddCallee = 'enter',
  ClearDialPad = 'delete',
}
