import { sendAnalyticsInfo } from 'features/analytics/helpers';
import { CallBaseLogMessages } from 'features/call/call-base/enums';

export const closeCallAnalytics = (
  methodName: string,
  abandonAfterTaken: boolean,
  terminationType: string,
  isDeafToHearing: boolean
) => {
  return sendAnalyticsInfo({
    Method: methodName,
    TerminationType: terminationType,
    AbandonAfterTaken: abandonAfterTaken,
    IsDeafToHearing: isDeafToHearing,
    Message: CallBaseLogMessages.CALL_COMPLETE_SUCCESS,
  });
};

export const denyCallAnalytics = (methodName: string, logoutType: string) => {
  return sendAnalyticsInfo({
    Method: methodName,
    LogoutType: logoutType,
    Message: CallBaseLogMessages.CALL_REJECTED_VI_LEFT_QUEUE,
  });
};

export const takeCallAnalytics = (methodName: string) => {
  return sendAnalyticsInfo({
    Method: methodName,
    Message: CallBaseLogMessages.TAKE_CALL_CLICK,
  });
};
