import { Typography } from '@mui/material';
import type { FC, PropsWithChildren, ReactNode } from 'react';

import styled from 'features/theme/styled';
import theme from 'features/theme/theme';

export interface ErrorBaseProps {
  icon: ReactNode;
  title: string;
  description?: string;
  hideButton?: boolean;
}

export const ErrorBase: FC<PropsWithChildren<ErrorBaseProps>> = ({
  icon,
  title,
  description,
  children,
}) => {
  return (
    <Container>
      <ErrorContainer>
        {icon}
        <Typography variant='h4' color={theme.palette.grey[600]} mt={44}>
          {title}
        </Typography>
        {description && (
          <Typography
            variant='subtitle2'
            color={theme.palette.grey[500]}
            mt={4}
          >
            {description}
          </Typography>
        )}
        {children}
      </ErrorContainer>
    </Container>
  );
};

const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const ErrorContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 520px;
  text-align: center;
`;
