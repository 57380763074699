import { LinearProgress, Typography } from '@mui/material';
import type { FC } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { LogoImg } from 'assets/svg';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';

export const LoadingPortal: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'systemLoading' });

  return createPortal(
    <Container>
      <LogoImg width={200} height={26} />
      <Title>{t('title')}</Title>
      <StyledLinearProgress />
      <Typography
        variant='caption'
        color={theme.palette.grey[50]}
        textTransform='none'
        mt={8}
      >
        {t('hint')}
      </Typography>
    </Container>,
    document.getElementById('root') as HTMLElement
  );
};

const Container = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: ${theme.palette.grey[700]};
  z-index: 20;
`;

const Title = styled('div')`
  font: 48px/62px 'StabilGrotesk';
  text-align: center;
  color: ${theme.palette.primary[500]};
  margin-top: ${theme.spacing(20)};
`;

const StyledLinearProgress = styled(LinearProgress)`
  width: 288px;
  margin-top: ${theme.spacing(20)};
`;
