import type { FC } from 'react';

import { useAppSelector } from 'features/app/hooks';
import { CallDetailsButtonCancel } from 'features/call/call-details/components';
import {
  selectIsLoadingVco2LineHearing,
  vco2LineIdSelector,
} from 'features/call/call-hearing/store';
import styled from 'features/theme/styled';
import { CallDetailsParticipantMetadataVco2Line } from 'features/vco-2-line/components';
import { useVoiceMeetingVco2LineHandlers } from 'features/vco-2-line/hooks';
import { isPrimaryVcoSenderSelector } from 'features/vco/store';

export const CallDetailsParticipantConnectingVco2Line: FC = () => {
  const { handleVco2LineCancel } = useVoiceMeetingVco2LineHandlers();
  const isLoadingVco2LineHearing = useAppSelector(
    selectIsLoadingVco2LineHearing
  );
  const vco2LineId = useAppSelector(vco2LineIdSelector);
  const isRequestingUser = useAppSelector(isPrimaryVcoSenderSelector);

  return (
    <Container>
      <CallDetailsParticipantMetadataVco2Line />
      {isRequestingUser && (
        <CallDetailsButtonCancel
          loading={isLoadingVco2LineHearing || !vco2LineId}
          onClick={handleVco2LineCancel}
        />
      )}
    </Container>
  );
};

const Container = styled('div')`
  display: flex;
  flex-direction: column;
`;
