import type { Theme } from '@mui/material';
import { alpha } from '@mui/material';

import { RadioIcon, RadioIconChecked } from 'assets/svg';
import type { ThemeComponents } from 'features/theme/types';

export default function Radio(theme: Theme): Partial<ThemeComponents> {
  return {
    MuiRadio: {
      defaultProps: {
        color: 'primary',
        size: 'small',
        icon: <RadioIcon />,
        checkedIcon: <RadioIconChecked />,
      },
      styleOverrides: {
        root: theme.unstable_sx({
          p: 0.5,
          m: theme.spacing(4, 4, 4, 2),
          '&:hover': {
            backgroundColor: 'transparent',
          },
          'svg rect': {
            stroke: theme.palette.grey[50],
          },
          '&.Mui-checked': {
            'svg rect': {
              fill: theme.palette.primary[500],
              stroke: 'none',
            },
            'svg circle': {
              fill: theme.palette.common.white,
            },
          },
          '&.Mui-focusVisible': {
            outline: 4,
            outlineStyle: 'solid',
            outlineColor: alpha(theme.palette.common.white, 0.3),
          },
          '&.Mui-disabled': {
            'svg rect': {
              stroke: theme.palette.grey[300],
            },
            '&.Mui-checked': {
              'svg rect': {
                stroke: 'unset',
                fill: theme.palette.grey[300],
              },
              'input:hover ~ svg rect': {
                fill: theme.palette.grey[300],
              },
            },
          },
        }),
      },
    },
  };
}
