import type React from 'react';
import { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { FeatureFlagService } from 'features/feature-toggle/services';

export const InitializeFeatureFlags: React.FC = () => {
  const flags = useFlags();

  useEffect(() => {
    const featureFlagsInstance = FeatureFlagService.getInstance();
    featureFlagsInstance.setFlags(flags);
  }, [flags]);

  return null;
};
