import { httpClient } from 'common/services';
import type { Session } from 'features/session/interfaces';
import type {
  UpdateUserLanguagesRequest,
  User,
} from 'features/user/interfaces';

async function getCurrentUser() {
  const route = '/users/current';
  const response = await httpClient.get<User>(route);
  return response.data;
}

async function updateUserLanguages({
  userId,
  languageCodes,
}: UpdateUserLanguagesRequest) {
  const route = `/users/${userId}/languages`;
  const response = await httpClient.patch<void>(route, {
    languageCodes,
  });
  return response.data;
}

async function getUserSession(userId: number) {
  const route = `/users/${userId}/session`;
  const response = await httpClient.get<Session>(route);
  return response.data;
}

export const UserDaoService = {
  getCurrentUser,
  updateUserLanguages,
  getUserSession,
};
