import type { RootState } from 'features/app/store/store';

export const getMachinePreferencesSelector = ({
  machinePreferences,
}: RootState) => machinePreferences;

export const getIsMachinePreferencesProvidedSelector = ({
  machinePreferences,
}: RootState) => {
  const { machineId, stationNumber, callCenterCode } = machinePreferences;

  return Boolean(machineId && stationNumber && callCenterCode);
};

export const machineCallCenterCodeSelector = ({
  machinePreferences,
}: RootState) => machinePreferences.callCenterCode;
