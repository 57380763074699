import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';
import {
  AddHearingStatus,
  CallDetailsStatus,
} from 'features/call/call-details/enums';
import type { CallDetailsState } from 'features/call/call-details/interfaces';

export const initialCallDetailsState: CallDetailsState = {
  status: CallDetailsStatus.CALLEE_DISCONNECTED,
  addHearingStatus: AddHearingStatus.BASE,
};

const callDetailsSlice = createSlice({
  name: 'call-details',
  initialState: initialCallDetailsState,
  reducers: {
    setCallDetailsStatus: (state, action: PayloadAction<CallDetailsStatus>) => {
      state.status = action.payload;
    },
    setAddHearingStatus: (state, action: PayloadAction<AddHearingStatus>) => {
      state.addHearingStatus = action.payload;
    },
    resetCallDetails: () => initialCallDetailsState,
  },
});

export const { setCallDetailsStatus, resetCallDetails, setAddHearingStatus } =
  callDetailsSlice.actions;

export const callDetailsStatusSelector = ({ callDetails }: RootState) =>
  callDetails.status;

export const addHearingStatusSelector = ({ callDetails }: RootState) =>
  callDetails.addHearingStatus;

export const isCalleeReconnectSelector = ({ callDetails }: RootState) =>
  callDetails.status === CallDetailsStatus.CALLEE_RECONNECT;

export const callDetailsReducer = callDetailsSlice.reducer;
