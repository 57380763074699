import { Typography } from '@mui/material';
import type { FC } from 'react';

import theme from 'features/theme/theme';

interface ExtensionNumberProps {
  phoneExtension: string;
}

export const ExtensionNumber: FC<ExtensionNumberProps> = ({
  phoneExtension,
}) => {
  return (
    <Typography
      variant='body1'
      data-testid='extension-number'
      color={theme.palette.grey[50]}
    >
      {`ext ${phoneExtension}`}
    </Typography>
  );
};
