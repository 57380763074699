import type { ThunkAction } from '@reduxjs/toolkit';
import type { RTCSession } from 'jssip/lib/RTCSession';

import { newHearingConnection } from './callerSessionSlice';

import { CallerSessionStatus } from 'features/caller-session/enums/';
import {
  isUnregisteredCaller,
  callerSessionAnalyticsInfo,
  getDefaultAnalyticsInfo,
} from 'features/caller-session/helpers';
import type { RootState } from 'features/app/store/store';
import { parsePhoneNumberWithRemovePlus } from 'features/call/call-base/helpers';
import { isConferenceCall } from 'features/call/call-base/store/selectors';
import { isEmergencyCallTypeSelector } from 'features/emergency/store';
import { isVriModeSelector } from 'features/multi-mode/store';
import { isWavelloSession } from 'features/wavello/helpers';

/** Determines whether new RTCSessions should be allowed. */
export const checkShouldAllowNewRtcSession =
  (
    session: RTCSession,
    rtcCallInfo: {
      callInfo: string | undefined;
      endpointMacAddress: string | undefined;
    }
  ): ThunkAction<
    {
      shouldAllow: boolean;
      reasonMessage: string;
      analyticsInfo: Record<string, any>;
    },
    RootState,
    unknown,
    any
  > =>
  (dispatch, getState) => {
    const state = getState();
    const status = state.callerSession.status;
    const caller = state.callerSession.caller;
    const macAddressFromRelay = state.callerSession.endpointMacAddress;
    const webRtcRemoteIdentities = state.callerSession.webRtcRemoteIdentities;
    const callDeafPhoneNumber = state.callDeaf.phoneNumber;
    const teamingRoomUri = state.teaming.roomUri;
    const isWavelloPossible = state.callHearing.hearings[0].isWavelloRegistered;
    const isEmergencyCall = isEmergencyCallTypeSelector(state);
    const isConferenceCallType = isConferenceCall(state);
    const isVriMode = isVriModeSelector(state);
    const endpointMacAddressFromSinfo = rtcCallInfo.endpointMacAddress;
    const isActiveRelayCall =
      state.callerSession.relayCallId !== '0' &&
      state.callerSession.relayCallId !== null;

    const defaultAnalytics = getDefaultAnalyticsInfo(
      session,
      rtcCallInfo,
      state
    );

    const defaultResult = {
      shouldAllow: false,
      reasonMessage: 'New RTCSessions are not allowed by default.',
      analyticsInfo: defaultAnalytics,
    };

    // explicit denials early returns for certain scenarios

    if (status !== CallerSessionStatus.InCallerSession) {
      return {
        ...defaultResult,
        reasonMessage: `Absolutely no calls are allowed outside of caller sessions. Status: ${status}`,
      };
    }

    if (!isActiveRelayCall) {
      return {
        ...defaultResult,
        reasonMessage: `Calls are not allowed without an active relay call in progress.`,
      };
    }

    if (isConferenceCallType) {
      return {
        ...defaultResult,
        reasonMessage: `Conference calls are not allowed to have WebRTC connections.`,
      };
    }

    const remoteIdentifier = session.remote_identity.uri.toString();
    const isIncoming = session.direction === 'incoming';
    const isAnyWebRtcConnected = webRtcRemoteIdentities.length > 0;
    const isAlreadyConnected =
      webRtcRemoteIdentities.includes(remoteIdentifier);
    const isReadyForTeaming = remoteIdentifier.includes(teamingRoomUri);
    const validUnregisteredCaller = isUnregisteredCaller(remoteIdentifier);
    const freeSwitchSignature = 'SVRS-RT';
    const isVrsMode = !isVriMode;
    const doesCallInfoIncludeCaller = rtcCallInfo.callInfo?.includes(caller);
    const doesRemoteIdentifierIncludeCaller = remoteIdentifier.includes(caller);
    const isWavelloCall = isWavelloSession(session);

    const vrsScenarios = [
      {
        condition:
          isIncoming &&
          doesRemoteIdentifierIncludeCaller &&
          !isAnyWebRtcConnected,
        message: 'Accepting call from the already-known caller',
      },
      {
        condition:
          isReadyForTeaming && remoteIdentifier.includes(freeSwitchSignature),
        message: 'Accepting connection for teaming',
      },
      {
        condition:
          !isIncoming &&
          remoteIdentifier.includes(callDeafPhoneNumber) &&
          !isAlreadyConnected,
        message: 'Allowing call to the already-known deaf person',
      },
      {
        condition: !isIncoming && isWavelloCall && isWavelloPossible,
        message: 'Allowing connection to Wavello',
      },
      {
        condition:
          isEmergencyCall &&
          !isAnyWebRtcConnected &&
          isIncoming &&
          validUnregisteredCaller,
        message:
          'Allowing incoming connection to emergency call to unregistered endpoint',
      },
    ];

    const vriScenarios = [
      {
        condition:
          isIncoming &&
          validUnregisteredCaller &&
          !isAnyWebRtcConnected &&
          doesCallInfoIncludeCaller,

        message:
          'Allowing incoming VRI connection to unregistered endpoint with expected caller',
      },
      {
        condition:
          isIncoming &&
          endpointMacAddressFromSinfo?.includes(macAddressFromRelay),
        message:
          'Allowing incoming VRI connection to unregistered endpoint with matching MAC address',
      },
    ];

    const scenarios = isVrsMode ? vrsScenarios : vriScenarios;
    const matchedScenario = scenarios.find((scenario) => scenario.condition);

    const result = matchedScenario
      ? { shouldAllow: true, reasonMessage: matchedScenario.message }
      : defaultResult;

    console.log('checkShouldAllowNewRtcSession', {
      result,
      session,
      status,
      caller,
      matchedScenario,
      remoteIdentifier,
      callDeafPhoneNumber,
      doesCallInfoIncludeCaller,
    });

    return {
      ...result,
      analyticsInfo: {
        ...defaultAnalytics,
        isVriMode: !isVrsMode,
        isEmergencyCall,
        doesCallInfoIncludeCaller,
      },
    };
  };

interface ShouldAllowResult {
  shouldAllow: boolean;
  reasonMessage: string;
}

/**
 * Synchronous thunk to check if a new hearing connection should be allowed.
 * If it should be allowed, this updates the state, and it won't be allowed again until it's marked as disconnected.
 * @param phoneNumber - The phone number to check.
 * @returns A thunk action that returns the result.
 */
export const checkShouldAllowNewHearingConnection =
  (
    phoneNumber: string
  ): ThunkAction<ShouldAllowResult, RootState, unknown, any> =>
  (dispatch, getState) => {
    dispatch({ type: 'callerSession/checkShouldAllowNewHearingConnection' });
    const state = getState();
    const normalizedNumber = parsePhoneNumberWithRemovePlus(phoneNumber);
    const status = state.callerSession.status;
    const switchboardConnections = state.callerSession.switchboardConnections;

    const defaultResult: ShouldAllowResult = {
      shouldAllow: true,
      reasonMessage: 'New hearing connections are allowed by default.',
    };

    if (status !== CallerSessionStatus.InCallerSession) {
      return {
        shouldAllow: false,
        reasonMessage: `Absolutely no calls are allowed outside of caller sessions. Status: ${status}`,
      };
    }

    const isNumberAlreadyInProgress =
      switchboardConnections.includes(normalizedNumber);

    dispatch(newHearingConnection(normalizedNumber));
    dispatch(
      callerSessionAnalyticsInfo(
        `New hearing connection being considered for ${normalizedNumber}`
      )
    );

    const scenarios = [
      {
        condition: isNumberAlreadyInProgress,
        message: 'Placing call to already in progress hearing is disallowed',
      },
    ];

    const matchedScenario = scenarios.find((scenario) => scenario.condition);
    const result: ShouldAllowResult = matchedScenario
      ? { shouldAllow: false, reasonMessage: matchedScenario.message }
      : defaultResult;

    return result;
  };
