import { httpClient } from 'common/services';
import type { Call } from 'features/call/call-base/interfaces';
import type { SpawnCallRequest } from 'features/spawn-call/interfaces';

async function spawnCall({
  sessionId,
  callId,
  terminationReason,
}: SpawnCallRequest) {
  const route = `/call/${callId}/spawn-call`;
  const response = await httpClient.post<Call>(route, {
    sessionId,
    terminationType: terminationReason,
  });
  return response.data;
}

export const SpawnCallDaoService = {
  spawnCall,
};
