import { createSlice } from '@reduxjs/toolkit';

import type { DeafPhoneNumberHiddenState } from 'features/deaf-phone-number-hidden/interfaces';

export const initialDeafPhoneNumberHidden: DeafPhoneNumberHiddenState = {
  isHidden: false,
};

const deafPhoneNumberHiddenSlice = createSlice({
  name: 'deaf-phone-number-hidden',
  initialState: initialDeafPhoneNumberHidden,
  reducers: {
    toggleDeafPhoneNumberIsHiddenState: (state) => {
      state.isHidden = !state.isHidden;
    },
    resetDeafPhoneNumberHiddenStatus: () => initialDeafPhoneNumberHidden,
  },
});

export const {
  toggleDeafPhoneNumberIsHiddenState,
  resetDeafPhoneNumberHiddenStatus,
} = deafPhoneNumberHiddenSlice.actions;

export const deafPhoneNumberHiddenReducer = deafPhoneNumberHiddenSlice.reducer;
