import type { ThemeComponents } from 'features/theme/types';

export default function Paper(): Partial<ThemeComponents> {
  return {
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: 'transparent',
        },
      },
    },
  };
}
