import {
  init,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
} from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import packageJson from '../../../package.json';

import {
  formatJsSipBreadcrumb,
  isChimeBreadcrumb,
  isJsSipBreadcrumb,
} from './utils';

import { envConfig } from 'features/environment/services';
import { isProductionEnvironment } from 'common/utils';

export function initSentry() {
  init({
    release: `${packageJson.name}@${packageJson.version}`,
    dsn: 'https://7d171016a97c4dd4829bedeeb7edd9c6@o1294089.ingest.sentry.io/6517721',
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),

      replayIntegration({
        blockAllMedia: true,
        maskAllText: true,
        maskAllInputs: true,
      }),
    ],
    normalizeDepth: 5,
    environment: envConfig.environment,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: isProductionEnvironment() ? 0.4 : 0,
    replaysOnErrorSampleRate: isProductionEnvironment() ? 1.0 : 0,
    maxBreadcrumbs: 300,
    beforeBreadcrumb: (breadcrumb) => {
      if (isChimeBreadcrumb(breadcrumb)) {
        return null;
      }

      if (isJsSipBreadcrumb(breadcrumb)) {
        return formatJsSipBreadcrumb(breadcrumb);
      }

      return breadcrumb;
    },
  });
}
