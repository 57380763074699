import { httpClient } from 'common/services';
import type { SendAnalyticsRequest } from 'features/analytics/interfaces';
import { env } from 'features/environment/constants';

async function sendAnalytics(payload: SendAnalyticsRequest) {
  if (!env.isProductionBuild) {
    return;
  }

  const route = '/analytics';
  return await httpClient.post(route, { ...payload, keepAlive: true });
}

export const AnalyticsDaoService = {
  sendAnalytics,
};
